import styled from 'styled-components'

type TWrapperProps = {
  marginTop?: number
}
export const StyledFacts = {
  Wrapper: styled.div<TWrapperProps>`
    width: 100%;
    margin-top: ${({ marginTop }) => `${marginTop || 0}px`};
    margin-bottom: 48px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    box-shadow: 0 8px 12px rgba(152, 159, 187, 0.1);
    border-radius: 16px;
  `,
  Title: styled.h4`
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    text-align: center;
  `,
  List: styled.ul`
    li {
      position: relative;
      padding-left: 24px;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;

      :not(:last-child) {
        margin-bottom: 12px;
      }

      ::before {
        content: '•';
        position: absolute;
        left: 12px;
        top: 0;
      }
    }
  `,
}
