import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import pulseMeasurement from 'assets/images/pulse-measurement.jpg'

import { StyledSocialProofRestingHRVariant2 as S } from './SocialProofRestingHRVariant2.styles'

const PAGE_NAME = 'Social Proof Resting Heart Rate'

export const SocialProofRestingHRVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <div>
          <S.Text>{t`onboarding.socialProofRestingHr.description2`}</S.Text>
          <S.ImageContainer>
            <img src={pulseMeasurement} alt="pulse measurement" />
          </S.ImageContainer>
        </div>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
