import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import {
  VAT_INCLUDED_COHORTS,
  VAT_INCLUDED_COUNTRIES,
} from 'root-constants/common'

export const useVatInfo = (): boolean => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const countryCode = useSelector(selectUserCountryCode)
  const hasAlternativeVariant = useFeatureIsOn('web_432_vat_test')

  return useMemo(() => {
    return (
      (VAT_INCLUDED_COHORTS.includes(cohortToUse) &&
        VAT_INCLUDED_COUNTRIES.includes(countryCode.toLowerCase()) &&
        hasAlternativeVariant) ||
      (VAT_INCLUDED_COHORTS.includes(cohortToUse) &&
        countryCode.toLowerCase() === 'us')
    )
  }, [cohortToUse, countryCode, hasAlternativeVariant])
}
