import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import { StyledPersonalSummary as S } from './PersonalSummary.styles'

export const PersonalSummary: React.FC = () => {
  const { t } = useTranslation()
  const { age, weight, weightUnit, height } = useUserData()

  return (
    <S.Wrapper>
      <S.Item>
        <S.Text>{age}</S.Text>
        <S.Unit>{t`summary.personalSummary.yearsOld`}</S.Unit>
      </S.Item>
      <S.Divider />
      <S.Item>
        {Array.isArray(height) ? (
          <>
            <S.Text>{height[0]}</S.Text>
            <S.Unit>{t`commonComponents.ft`}</S.Unit>
            <S.Text>{height[1]}</S.Text>
            <S.Unit>{t`commonComponents.in`}</S.Unit>
          </>
        ) : (
          <>
            <S.Text>{height}</S.Text>
            <S.Unit>{t`commonComponents.cm`}</S.Unit>
          </>
        )}
      </S.Item>
      {weight && (
        <>
          <S.Divider />
          <S.Item>
            <S.Text>{weight}</S.Text>
            <S.Unit>{t(`commonComponents.${weightUnit}`)}</S.Unit>
          </S.Item>
        </>
      )}
    </S.Wrapper>
  )
}
