import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useInputValidation } from 'hooks/useInputValidation'
import { useUserData } from 'hooks/useUserData'

import { getBmiCategory } from 'helpers/getBmiCategory'
import { getCmFromFeetAndInch } from 'helpers/getCmFromFeetAndInch'
import { getKgFromLb } from 'helpers/getKgFromLb'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Popover } from 'components/Popover'

import { goTo } from 'browser-history'
import { BMICategory, Language } from 'root-constants/common'

import { StyledWeight as S } from './Weight.styles'
import {
  PAGE_NAME,
  WEIGHT_KG_MAX,
  WEIGHT_KG_MIN,
  WEIGHT_LB_MAX,
  WEIGHT_LB_MIN,
} from './constants'

export const WeightVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [weight, setWeight] = useState(0)

  const { height } = useUserData()

  const {
    value: kg,
    isValid: isKgValid,
    handleInputChange: handleKgChange,
  } = useInputValidation()

  const {
    value: lb,
    isValid: isLbValid,
    handleInputChange: handleLbChange,
  } = useInputValidation()

  const isImperialUsed = useMemo(() => Array.isArray(height), [height])

  const isWeightValueValid = useMemo(
    () => !(isImperialUsed ? isLbValid && lb : isKgValid && kg),
    [isImperialUsed, isKgValid, isLbValid, kg, lb],
  )

  const bmi = useMemo(() => {
    const weightInKg = !isImperialUsed ? weight : getKgFromLb(weight)

    const heightInCm = isImperialUsed
      ? getCmFromFeetAndInch(+height[0], +height[1])
      : +height

    return +(weightInKg / (heightInCm / 100) ** 2).toFixed(1)
  }, [height, isImperialUsed, weight])

  const bmiCategory = useMemo(() => getBmiCategory(bmi), [bmi])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(
        setMultipleAnswerAction({
          answers: {
            [pageId]: isImperialUsed ? +lb : +kg,
            weightUnit: isImperialUsed ? 'lb' : 'kg',
          },
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.weight.title', { lng: Language.EN }),
        pageName: PAGE_NAME,
        answers: isImperialUsed ? `${lb} lb` : `${kg} kg`,
      })
      goTo(nextPagePath)
    },
    [dispatch, isImperialUsed, kg, lb, nextPagePath, pageId, t],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.weight.title`}</S.Title>
        <S.ButtonsWrapper>
          <S.SystemButton>
            {isImperialUsed
              ? t`commonComponents.imperial`
              : t`commonComponents.metric`}
          </S.SystemButton>
        </S.ButtonsWrapper>

        <form onSubmit={handleSubmit}>
          {isImperialUsed && (
            <S.SystemWrapper>
              <Popover
                isShown={!isLbValid}
                position="center"
                text={t('commonComponents.inputValidationText', {
                  minValue: WEIGHT_LB_MIN,
                  maxValue: WEIGHT_LB_MAX,
                })}
              >
                <Input
                  type="number"
                  placeholder={t`commonComponents.pounds`}
                  min={WEIGHT_LB_MIN}
                  max={WEIGHT_LB_MAX}
                  onChange={(e) => {
                    handleLbChange(e.target.value, e.target.validity.valid)
                    setWeight(+e.target.value)
                  }}
                  isValid={isLbValid}
                  inputMode="numeric"
                />
              </Popover>
            </S.SystemWrapper>
          )}

          {!isImperialUsed && (
            <S.SystemWrapper>
              <Popover
                isShown={!isKgValid}
                position="center"
                text={t('commonComponents.inputValidationText', {
                  minValue: WEIGHT_KG_MIN,
                  maxValue: WEIGHT_KG_MAX,
                })}
              >
                <Input
                  type="number"
                  placeholder={t`commonComponents.kilograms`}
                  min={WEIGHT_KG_MIN}
                  max={WEIGHT_KG_MAX}
                  onChange={(e) => {
                    handleKgChange(e.target.value, e.target.validity.valid)
                    setWeight(+e.target.value)
                  }}
                  isValid={isKgValid}
                  inputMode="numeric"
                />
              </Popover>
            </S.SystemWrapper>
          )}

          {!isWeightValueValid && (
            <S.BmiWrapper>
              <S.BmiTitle>
                {t`onboarding.weight.yourBmi`}
                <S.BmiValue isNormalWeight={bmiCategory === BMICategory.NORMAL}>
                  {bmi}
                </S.BmiValue>
              </S.BmiTitle>
              <S.BmiLabel isNormalWeight={bmiCategory === BMICategory.NORMAL}>
                {t(`onboarding.weight.bmi.${bmiCategory}.label`)}
              </S.BmiLabel>
              <S.BmiDescription>
                {t(`onboarding.weight.bmi.${bmiCategory}.text`)}
              </S.BmiDescription>
            </S.BmiWrapper>
          )}
          <Button type="submit" disabled={isWeightValueValid}>
            {t`actions.continue`}
          </Button>
        </form>
      </S.Column>
    </S.Wrapper>
  )
}
