import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserAdditionalGoals } from 'hooks/useUserAdditionalGoals'
import { useUserSymptoms } from 'hooks/useUserSymptoms'

import { TPageProps } from 'models/common.model'

import {
  MAX_ADDITIONAL_GOALS_SIZE,
  MAX_SYMPTOMS_SIZE,
} from 'pages/better-heart-life/constants'

import { Button } from 'components/Button'

import numberOne from 'assets/images/number-one.png'

import { Language } from 'root-constants/common'

import { StyledBetterHeartLife as S } from './BetterHeartLife.styles'

export const BetterHeartLifeVariant1: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()

  const userSymptoms = useUserSymptoms(MAX_SYMPTOMS_SIZE)
  const userAdditionalGoals = useUserAdditionalGoals(MAX_ADDITIONAL_GOALS_SIZE)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.betterHeartLife.title', { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.betterHeartLife.title`}</S.Title>
        <S.BenefitsWrapper>
          <S.BenefitsList>
            <li>{t`onboarding.betterHeartLife.advancedTracking`}</li>
            <li>{userAdditionalGoals.join(', ')}</li>
            {!!userSymptoms.length && (
              <li>
                {t('onboarding.betterHeartLife.minimize', {
                  symptoms: userSymptoms,
                  style: 'long',
                  type: 'conjunction',
                })}
              </li>
            )}
          </S.BenefitsList>
        </S.BenefitsWrapper>
        <S.Image src={numberOne} alt="number_one_app" />
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
