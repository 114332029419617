import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { PRICE_BACKGROUND } from 'modules/subscriptions/components/trial/PaidTrialItemVariant1/constants'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import priceBgImg from 'assets/images/price-bg-blue.svg'

import {
  CURRENCY_SYMBOLS,
  ProductKey,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledPlanItem as S } from './PaidTrialItemVariant1.styles'

export const PaidTrialItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    product,
    mainPrices: { periodName, periodQuantity, fullPrice },
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  return (
    <S.PlanItem
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.mostPopular`}
    >
      <S.Container>
        <S.PlanTitle>
          {t('subscriptions.trialPlanPeriod', {
            periodName,
            periodQuantity,
            trialDays: durationDays,
            context: TRIAL_DESCRIPTION_CONTEXT[durationDays],
          })}
        </S.PlanTitle>
        <S.PricesWrapper>
          <S.OldPrice>
            {CURRENCY_SYMBOLS[currency]}
            {(product === ProductKey.ONE_WEEK
              ? oldPrices.beforeCustomDiscount.fullPrice
              : fullPrice
            ).toFixed(2)}
          </S.OldPrice>
          <S.NewPrice>
            {CURRENCY_SYMBOLS[currency]}
            {trialPrice.toFixed(2)}
          </S.NewPrice>
        </S.PricesWrapper>
      </S.Container>
      {!!daily && (
        <S.Container>
          <S.CustomPrice
            data-is-selected={isSelected}
            data-currency-symbol-length={CURRENCY_SYMBOLS[currency]?.length}
            priceBackground={PRICE_BACKGROUND[cohortToUse] || priceBgImg}
          >
            <S.Price data-is-selected={isSelected}>
              {CURRENCY_SYMBOLS[currency]}
              {daily.toFixed(2)}
            </S.Price>
            <S.Period data-is-selected={isSelected}>
              {t`subscriptions.perDay`}
            </S.Period>
          </S.CustomPrice>
        </S.Container>
      )}
    </S.PlanItem>
  )
}
