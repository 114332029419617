import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import doctorImage from 'assets/images/plan-processing-doctor-2.png'

import { StyledPlanProcessingVariant7 as S } from './PlanProcessingVariant7.styles'
import { PAGE_NAME, PROCESSING_TEXT_FOUR_POINTS } from './constants'

export const PlanProcessingVariant7: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { counter, isFinished } = useCounter(true, {
    duration: 6000,
  })

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.processing.creatingProfile`}</S.Title>
        <S.ProgressBar max={100} value={counter} />
        <S.Block>
          {PROCESSING_TEXT_FOUR_POINTS.map((text, index) => (
            <S.Item key={text} data-item={index}>
              {t(text)}
            </S.Item>
          ))}
        </S.Block>
        <S.ImageWrapper>
          <img src={doctorImage} alt="doctor" />
          <div>
            <S.ImageWrapperTitle>{t`onboarding.processing.thanksForTrust`}</S.ImageWrapperTitle>
            <S.Text>{t`onboarding.processing.wellBeing`}</S.Text>
          </div>
        </S.ImageWrapper>
        <S.Disclaimer>{t`onboarding.processing.trustedByMillion`}</S.Disclaimer>

        <S.StickyButtonContainer>
          <Button
            disabled={!isFinished}
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
