import styled from 'styled-components'

import backIcon from 'assets/images/back.svg'
import warningIcon from 'assets/images/warning-gray.svg'

import { Color } from 'root-constants/common'

type TFormProps = {
  marginBottom?: number
}

export const StyledPaymentFlows = {
  BackButton: styled.button`
    box-sizing: content-box;
    height: 22px;
    display: block;
    padding: 0 0 0 18px;
    margin-bottom: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #5f96f7;
    text-decoration: underline;
    border: none;
    outline: none;
    background-color: transparent;
    background-image: url(${backIcon});
    background-size: 8px 11px;
    background-position: 2px 6px;
    background-repeat: no-repeat;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  Title: styled.h1`
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.secondaryText};
    margin: 32px 0 8px;
  `,
  PaymentServiceImage: styled.img`
    margin-bottom: 16px;
  `,
  Guarantee: styled.span`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${Color.DARK_TEXT};
    margin-bottom: 32px;
  `,
  DescriptionItem: styled.li`
    padding: 0 0 0 24px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: ${Color.DARK_TEXT};
    background-image: url(${warningIcon});
    background-size: 14px 14px;
    background-position: top left;
    background-repeat: no-repeat;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  `,
  PaymentCard: styled.div`
    margin-bottom: 12px;
    width: 100%;
    height: 78px;
    border: 1px solid #b2b8cd;
    border-radius: 16px;
    overflow: hidden;
    background-color: white;
    transition: all 0.2s ease-out;

    input:checked + & {
      border-color: #5f96f7;

      &[data-payment-type='paypal'] {
        height: 354px;
      }

      &[data-payment-type='apple-pay'] {
        height: 354px;
      }

      &[data-payment-type='card'] {
        height: 626px;
      }
    }
  `,
  PaymentCardBody: styled.div`
    input:not(:checked) + * & {
      display: none;
    }
  `,
  PaymentHeader: styled.label`
    height: 78px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  PaymentHeaderLeftSide: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  PaymentHeaderTitle: styled.h3`
    font-size: 14px;
    font-weight: 900;
  `,
  PaymentHeaderBox: styled.div`
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #5f96f7;

    ::before {
      content: '';
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      position: absolute;
      display: block;
      border-radius: 50%;
      background-color: white;
      transition: background-color 0.2s ease-out;
    }

    input:checked + * &::before {
      background-color: #5f96f7;
    }
  `,
  Form: styled.div<TFormProps>`
    width: 100%;
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 0}px`};
  `,
  Divider: styled.div`
    margin: 16px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  `,
  DividerText: styled.div`
    position: relative;
    padding: 0 4px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #153e83;

    ::before,
    ::after {
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      width: 500px;
      top: 10px;
      background-color: #b2b8cd;
    }

    ::before {
      right: 100%;
    }

    ::after {
      left: 100%;
    }
  `,
}
