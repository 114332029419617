import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'
import { useUserSymptoms } from 'hooks/useUserSymptoms'

import { TPageProps } from 'models/common.model'

import { MAX_SYMPTOMS_SIZE } from 'pages/blood-pressure-graph/constants'

import graph from 'assets/images/blood-pressure-graph.png'

import { Gender } from 'root-constants/common'

import { StyledBloodPressureGraphVariant5 as S } from './BloodPressureGraphVariant5.styles'

export const BloodPressureGraphVariant5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { age, userGender } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const userSymptoms = useUserSymptoms(MAX_SYMPTOMS_SIZE)

  return (
    <S.Wrapper>
      <S.Column>
        <S.Content>
          <S.Title>
            {t('onboarding.bloodPressureGraph.title2', {
              context: userGender || Gender.FEMALE,
              age,
            })}
          </S.Title>
          <S.GraphWrapper>
            <S.BenefitsList>
              <li>
                <Trans i18nKey="onboarding.bloodPressureGraph.trackingSystem" />
              </li>
              <S.ImageContainer>
                <img src={graph} alt="graph" />
              </S.ImageContainer>
              <li>
                <Trans i18nKey="onboarding.bloodPressureGraph.healthyPressure" />
              </li>
              <li>
                <Trans
                  i18nKey="onboarding.bloodPressureGraph.minimize"
                  values={{
                    userSymptoms: userSymptoms.length
                      ? userSymptoms
                      : t('onboarding.bloodPressureGraph.irregularities'),
                  }}
                />
              </li>
            </S.BenefitsList>
          </S.GraphWrapper>
        </S.Content>

        <S.StickyButtonContainer>
          <Button
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
