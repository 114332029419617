import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import 'root-redux/actions/user'
import {
  selectCookieConsentCountries,
  selectGoogleAnalyticsId,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { GAConsent } from 'root-constants/common'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useGoogleAnalytics = (): void => {
  const googleAnalyticsId = useSelector(selectGoogleAnalyticsId)
  const userCountryCode = useSelector(selectUserCountryCode)
  const cookieConsentCountries = useSelector(selectCookieConsentCountries)

  const {
    isEUUser,
    cookieConsent,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
  } = useCookieConsentAnswer()

  const shouldGABePaused = useMemo(
    () => isEUUser && !hasAnalyticsAndPerformanceCookie,
    [hasAnalyticsAndPerformanceCookie, isEUUser],
  )

  useEffect(() => {
    if (!googleAnalyticsId || !userCountryCode || shouldGABePaused) return

    const initGtag = () => {
      const script = document.createElement('script')
      script.text = `window.dataLayer = window.dataLayer || [];
        gtag = function () { dataLayer.push(arguments); }
        gtag('js', new Date());`
      script.async = true
      document.head.appendChild(script)
    }

    const setUpGTM = () => {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
      script.async = true
      document.head.appendChild(script)
    }

    initGtag()
    setUpGTM()

    !!window.gtag &&
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'granted',
        region: cookieConsentCountries,
      })

    if (cookieConsent?.length) {
      !!window.gtag &&
        window.gtag('consent', 'update', {
          ad_user_data: hasAdvertisingAndTargetingCookie
            ? GAConsent.GRANTED
            : GAConsent.DENIED,
          ad_personalization:
            hasAdvertisingAndTargetingCookie && hasFunctionalCookie
              ? GAConsent.GRANTED
              : GAConsent.DENIED,
          ad_storage:
            hasAdvertisingAndTargetingCookie && hasFunctionalCookie
              ? GAConsent.GRANTED
              : GAConsent.DENIED,
          region: cookieConsentCountries,
        })
    }

    !!window.gtag &&
      window.gtag('config', googleAnalyticsId, {
        send_page_view: false,
      })
  }, [
    googleAnalyticsId,
    userCountryCode,
    shouldGABePaused,
    cookieConsentCountries,
    cookieConsent?.length,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
  ])
}
