import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { NO_TRANSITION_PAGES } from 'root-constants/common'

type TProps = {
  children?: React.ReactNode
}

export const AnimationProvider: React.FC<TProps> = ({ children }) => {
  const { pathname } = useLocation()
  const { currentPageId } = useGetPageInfo()

  const hasTransition = useMemo(
    () => !NO_TRANSITION_PAGES.includes(currentPageId),
    [currentPageId],
  )

  return !hasTransition ? (
    <div>{children}</div>
  ) : (
    <TransitionGroup>
      <CSSTransition key={pathname} classNames="fade" timeout={350}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}
