import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { StyledHeartRate as S } from './HeartRate.styles'

const PAGE_NAME = 'Heart rate question'
const QUESTION = `What's your resting heart rate (bpm)?`
const OPTION_VALUES = {
  LESS_50: '<50',
  FROM_50_TO_70: '51 - 70',
  FROM_70_TO_90: '71 - 90',
  FROM_90_TO_110: '91 - 110',
  MORE_110: '>111',
}

export const HeartRateVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.heartRate.title2" />
        </S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.LESS_50}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.LESS_50}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.FROM_50_TO_70}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.FROM_50_TO_70}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.FROM_70_TO_90}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.FROM_70_TO_90}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.FROM_90_TO_110}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.FROM_90_TO_110}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.MORE_110}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.MORE_110}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
