import styled, { css, keyframes } from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-circle-light-green.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

const progressValueStyles = css`
  border-radius: 10px;
  background-color: #35b3e5;
  transition: all 0.2s ease-out;
`
const itemVisibility = keyframes`
  0% {
   opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const animation = css`
  animation-name: ${itemVisibility};
  animation-duration: 6000ms;
  animation-fill-mode: backwards;

  [data-item='1']& {
    animation-delay: 1000ms;
  }

  [data-item='2']& {
    animation-delay: 2000ms;
  }

  [data-item='3']& {
    animation-delay: 3000ms;
  }

  [data-item='4']& {
    animation-delay: 4000ms;
  }
`

export const StyledPlanProcessingVariant7 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    background-color: #f2f3f5;
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;
    padding-top: 24px;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    color: ${Color.GRAY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  ProgressBar: styled.progress`
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background-color: #e9e9e9;
    margin-bottom: 20px;

    &::-webkit-progress-bar {
      background-color: #e9e9e9;
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
    }
    &::-ms-fill {
      ${progressValueStyles};
    }
  `,
  Block: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  Item: styled.div`
    ${animation};
    position: relative;
    padding-left: 32px;
    color: ${Color.GRAY_80};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  `,
  ImageWrapper: styled.div`
    display: flex;
    padding-right: 32px;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
    max-height: 140px;
    margin: 20px 0;

    img {
      max-height: 140px;
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;
    }
  `,
  ImageWrapperTitle: styled.p`
    margin-bottom: 8px;
    color: ${Color.GRAY_100};
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  Text: styled.p`
    color: ${Color.GRAY_80};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  Disclaimer: styled.p`
    flex-grow: 1;
    margin: 0 auto 24px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.GRAY_80};
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    background-image: none;
  `,
}
