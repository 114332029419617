import { IAppState } from 'models/store.model'
import { IUserStatus } from 'models/user.model'

import { PaymentMethod, PaymentSystem } from 'root-constants/common'

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectOneLink = (state: IAppState): string => state.user.oneLink
export const selectUserStatus = (state: IAppState): IUserStatus | null =>
  state.user.status
export const selectEmail = (state: IAppState): string =>
  state.user.status?.email?.onboardingEmail || ''
export const selectUserCountryCode = (state: IAppState): string =>
  state.user.status?.countryCode || ''
export const selectUtmTags = (state: IAppState): Record<string, string> =>
  state.user.utmTags || state.user.status?.config?.utmTags || {}
export const selectUserPaymentMethod = (state: IAppState): PaymentMethod =>
  state.user.status?.config.paymentMethod || PaymentMethod.CREDIT_CARD
export const selectGeneralSubscriptionTrialPeriod = (
  state: IAppState,
): number => state.user.status?.config.trialPeriod || 0
export const selectCookieConsent = (state: IAppState): string[] | null =>
  state.user.status?.config.cookieConsent || null
export const selectProductName = (state: IAppState): string =>
  state.user.productName || state.user.status?.config?.productName || ''
export const selectUserPaymentId = (state: IAppState): string =>
  state.user.status?.config?.paymentId || ''
export const selectUserPaymentSystem = (state: IAppState): PaymentSystem =>
  state.user.status?.config?.paymentSystem || PaymentSystem.STRIPE
