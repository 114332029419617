import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledEthnicity = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 46px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 24px;
  `,
  Button: styled.span`
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    color: #b2b8cd;
    margin-top: 12px;
  `,
}
