import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import clockImage from 'assets/images/sprite/clock.svg'

import { useTimerForTarget } from '../../../hooks/useTimerForTarget'
import {
  GlobalStyleForSubheaderWithTimer,
  StyledSubheaderWithTimer as S,
} from './SubheaderWithTimer.styles'

interface ITimerProps {
  discount: number
  timeAmount?: number
  clockImg?: string
  clockheight?: number
}

const FIFTEEN_MINUTES_IN_SECONDS = 900

export const SubheaderWithTimer: React.FC<ITimerProps> = ({
  discount,
  timeAmount = FIFTEEN_MINUTES_IN_SECONDS,
  clockImg = clockImage,
  clockheight = 16,
  ...props
}) => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLDivElement>(null)

  const { minutes, seconds } = useTimerForTarget(timerElementRef, timeAmount)

  return (
    <>
      <GlobalStyleForSubheaderWithTimer />
      <S.TimerContainer {...props}>
        <S.TimerContent ref={timerElementRef}>
          <S.Discount>
            <S.ClockImg svg={clockImg} clockheight={clockheight} />
            {discount}%
          </S.Discount>
          {t`subscriptions.discountText`}{' '}
          {minutes && seconds && <strong>{`00:${minutes}:${seconds}`}</strong>}
        </S.TimerContent>
      </S.TimerContainer>
    </>
  )
}
