import styled from 'styled-components'

import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { commonTitleStyles } from 'modules/subscriptions/styles'

import { Button } from 'components/Button'

import checkIcon from 'assets/images/check-icon-wavy-borders-green.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledSubscriptionsVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    align-items: center;
    padding: 80px 0 100px;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 12px;

    strong {
      color: ${({ theme }) => theme.colors.highlightedText};
      font-weight: inherit;
    }
  `,
  Subtitle: styled.h3`
    margin-bottom: 24px;
    color: ${Color.LIGHT_TEXT};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  `,
  List: styled.ul`
    margin-bottom: 45px;
  `,
  ListItem: styled.li`
    margin-bottom: 16px;
    padding-left: 32px;
    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: 0 2px;
    color: ${Color.LIGHT_TEXT};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 12px;
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
  SubscriptionDescription: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 48px;
    color: ${Color.LIGHT_TEXT};

    & a {
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
  ReviewsTitle: styled.h3`
    ${commonTitleStyles};
    align-self: flex-start;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.secondaryText};
  `,
  MoneyBackGuarantee: styled(MoneyBackGuarantee)`
    p {
      text-align: left;
    }
  `,
}
