import { PageId } from 'root-constants/pages'

export const ONBOARDING_START = 0
export const ONBOARDING_END = 3
export const PROFILE_BREAKPOINTS = [
  PageId.KIT_GENDER_1,
  PageId.KIT_PHYSICAL_ACTIVITY_1,
  PageId.KIT_SYMPTOMS_1,
  PageId.KIT_HEART_COMPANION_1,
]

export const PROFILE_TEXTS = [
  'commonComponents.onboardingProgressbarV3.healthProfile',
  'commonComponents.onboardingProgressbarV3.habitsAndRoutine',
  'commonComponents.onboardingProgressbarV3.personalDetails',
]
export const FEMALE_OPTIONAL_PAGES = [
  PageId.KIT_HORMONAL_MEDS_1,
  PageId.KIT_MENOPAUSE_1,
]

export const MALE_OPTIONAL_PAGES = [
  PageId.KIT_SYMPTOMS_2,
  PageId.KIT_TESTOSTERONE_1,
]
