import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import pulseImg from 'assets/images/social-proof-heart-health-1.webp'
import pressureImg from 'assets/images/social-proof-heart-health-2.webp'

import { StyledSocialProofHeartHealthVariant1 as S } from './SocialProofHeartHealthVariant1.styles'

export const SocialProofHeartHealthVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { hasUserConcerns } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: hasUserConcerns
      ? 'Social Proof Pulse 2'
      : 'Social Proof Healthy People Die 2',
    question: '',
    nextPagePath,
  })
  return (
    <S.Wrapper>
      <S.Column>
        {hasUserConcerns ? (
          <>
            <S.Title>{t`onboarding.socialProofHeartHealth.title`}</S.Title>
            <S.Text>{t`onboarding.socialProofHeartHealth.description`}</S.Text>
            <S.Img src={pulseImg} alt="woman" />
          </>
        ) : (
          <>
            <S.Title>{t`onboarding.socialProofHeartHealth.title2`}</S.Title>
            <S.Text>
              <Trans i18nKey="onboarding.socialProofHeartHealth.description2" />
            </S.Text>
            <S.Img src={pressureImg} alt="doctor" />
          </>
        )}
        <S.StickyButton onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </S.StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
