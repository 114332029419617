import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledTestosteroneVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${({ theme }) => theme.common.wrapperPaddingTop};
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: ${({ theme }) => theme.common.columnPaddingTop};
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
  `,
  Subtitle: styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 24px;
  `,
}
