import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'

import crown from 'assets/images/crown.png'
import checkIcon from 'assets/images/sprite/check-icon-green-circle.svg'
import crossIcon from 'assets/images/sprite/cross.svg'

import { goTo } from 'browser-history'

import { StyledSummaryAppBenefits as S } from './SummaryAppBenefits.styles'

const PAGE_NAME = 'Premium plan benefits'

export const SummaryAppBenefits: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: PAGE_NAME,
    })
  }, [])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({
      pageName: PAGE_NAME,
    })
    goTo(nextPagePath)
  }, [nextPagePath])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`summary.appBenefits.title`}</S.Title>

        <S.Table>
          <S.TableHead>
            <tr>
              <th>{t`summary.appBenefits.plan`}</th>
              <th>{t`summary.appBenefits.limited`}</th>
              <th>
                <Trans i18nKey="summary.appBenefits.premium" />
              </th>
            </tr>
          </S.TableHead>

          <S.TableBody>
            <tr>
              <th>{t`summary.appBenefits.pulseMeasurement`}</th>
              <th>
                <S.CheckIcon svg={checkIcon} />
              </th>
              <th>
                <S.CheckIcon svg={checkIcon} />
              </th>
            </tr>
            <tr>
              <th>{t`summary.appBenefits.stressMeasurement`}</th>
              <th>
                <S.CrossIcon svg={crossIcon} />
              </th>
              <th>
                <S.CheckIcon svg={checkIcon} />
              </th>
            </tr>
            <tr>
              <th>{t`summary.appBenefits.heartAgeTracking`}</th>
              <th>
                <S.CrossIcon svg={crossIcon} />
              </th>
              <th>
                <S.CheckIcon svg={checkIcon} />
              </th>
            </tr>
            <tr>
              <th>{t`summary.appBenefits.meditation`}</th>
              <th>
                <S.CrossIcon svg={crossIcon} />
              </th>
              <th>
                <S.CheckIcon svg={checkIcon} />
              </th>
            </tr>
          </S.TableBody>
        </S.Table>

        <S.DisclaimerContainer>
          <S.Disclaimer>
            <S.DisclaimerImage src={crown} alt="crown" />
            <S.DisclaimerText>
              <Trans i18nKey="summary.appBenefits.disclaimer" />
            </S.DisclaimerText>
          </S.Disclaimer>
        </S.DisclaimerContainer>

        <Button type="button" onClick={handleContinue}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
