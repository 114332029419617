import styled from 'styled-components'

import {
  commonListStyles,
  commonTitleStyles,
} from 'modules/subscriptions/styles'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'

export const StyledPaidTrialSubscriptions = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 80px 0 100px;
  `,
  Title: styled.h3`
    ${commonTitleStyles};
    margin-bottom: 16px;
  `,
  Subtitle: styled.h3`
    margin-bottom: 48px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  `,
  Congratulations: styled.h3`
    margin-bottom: 16px;
    font-weight: 900;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  `,
  List: styled.div`
    ${commonListStyles};
  `,
  SubscriptionBlockTitle: styled.h3`
    ${commonTitleStyles};
    margin-bottom: 24px;
  `,
  Disclaimer: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 48px;
  `,
  SubscriptionDescription: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 48px;
    text-align: center;
    color: #565976;

    & a {
      color: #565976;
      text-decoration: underline;
    }
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
}
