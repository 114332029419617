import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check.svg'

import { Color } from 'root-constants/common'

const checkboxStyles = css`
  text-align: left;

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 21px;
    right: 19px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid #d0dde4;
  }
`

export const StyledQuestionButton = {
  Wrapper: styled.div`
    position: relative;
    padding: ${({ theme }) => theme.common.optionPadding};
    border: ${({ theme }) => `1px solid ${theme.colors.optionBorder}`};
    border-radius: 16px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.optionBackground};
    box-shadow: ${({ theme }) => theme.shadows.optionShadow};
    font-size: ${({ theme }) => theme.common.optionFontSize};
    font-weight: 700;
    line-height: ${({ theme }) => theme.common.optionLineHeight};
    text-align: ${({ theme }) => theme.common.textAlign};
    transition: border-color 0.2s ease-out;

    &[data-has-check-icon='true'] {
      ${checkboxStyles};
    }

    &[data-has-none-button-check-icon='true'] {
      ${checkboxStyles};

      ::after {
        content: '-';
        text-align: center;
        color: #d0dde4;
        font-size: 22px;
        line-height: 11px;
      }
    }

    input:checked + & {
      border-color: ${({ theme }) => theme.colors.checkedOptionBorder};
      background-color: ${({ theme }) => theme.colors.checkedOptionBackground};
      color: ${({ theme }) => theme.colors.checkedOptionText};

      &[data-has-check-icon='true'],
      &[data-has-none-button-check-icon='true'] {
        border-color: ${({ theme }) =>
          theme.colors.checkedOptionWithCheckboxBorder};
        background-color: ${Color.WHITE};
        color: ${Color.DEFAULT_TEXT};
      }

      &[data-has-check-icon='true']::after {
        background-color: ${({ theme }) => theme.colors.checkIcon};
        background-image: url(${checkIcon});
        background-repeat: no-repeat;
        background-position: center;
        border-color: ${({ theme }) => theme.colors.checkIcon};
      }

      &[data-has-none-button-check-icon='true']::after {
        border-color: ${({ theme }) => theme.colors.checkIcon};
        color: ${({ theme }) => theme.colors.checkIcon};
      }
    }
  `,
}
