import { Goal } from 'root-constants/common'

export const MAX_SYMPTOMS_SIZE = 2

export const GOALS = {
  [Goal.HEART_ATTACK_PREVENTION]:
    'subscriptions.subscription3.goals.preventHeartAttack',
  [Goal.BLOOD_PRESSURE]: 'subscriptions.subscription3.goals.bloodPressure',
  [Goal.HEART_RATE]: 'subscriptions.subscription3.goals.heartRate',
  [Goal.LOWER_CHOLESTEROL]: 'subscriptions.subscription3.goals.cholesterol',
}
