import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledUpsellBenefitsDescription = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  Subtitle: styled.h3`
    margin-bottom: 16px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  Card: styled.div`
    display: grid;
    grid-template-columns: 112px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'image title'
      'image description';
    grid-gap: 4px 12px;
    margin-bottom: 12px;
    padding: 14px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #dfe3f3;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  Image: styled.img`
    grid-area: image;
  `,
  CardTitle: styled.h3`
    grid-area: title;
    align-self: flex-end;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  `,
  Description: styled.p`
    grid-area: description;
    align-self: flex-start;
    color: ${Color.LIGHT_TEXT};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
}
