import { Goal } from 'root-constants/common'

export const GOAL_TO_MARKUP = {
  [Goal.HEART_ATTACK_PREVENTION]:
    'onboarding.socialProofHealthRisks.monitorHeartHealth',
  [Goal.BLOOD_PRESSURE]: 'onboarding.socialProofHealthRisks.bloodPressure',
  [Goal.HEART_RATE]: 'onboarding.socialProofHealthRisks.monitorHeartHealth',
  [Goal.LOWER_CHOLESTEROL]:
    'onboarding.socialProofHealthRisks.lowerCholesterol',
}
