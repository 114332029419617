/* eslint-disable max-lines */
import {
  OnResumeSuccessHandler,
  OnTokenizeSuccessHandler,
} from '@primer-io/checkout-web'
import { paymentApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  savePlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectScreenName,
} from 'root-redux/selects/common'
import { selectUUID, selectUserPaymentId } from 'root-redux/selects/user'

import { TAnswer } from 'models/common.model'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  PRIMER_PAYMENT_ERRORS,
  PRIMER_SOFT_DECLINE,
} from 'modules/payment/constants'

import { PaymentMethod, PaymentSystem } from 'root-constants/common'

import { getPurchaseFailedEventParams } from '../../helpers/getPurchaseFailedEventParams'
import { getPurchaseSuccessEventParams } from '../../helpers/getPurchaseSuccessEventParams'
import { logSuccessfulPayment } from '../../helpers/logSuccessfulPayment'
import {
  selectCurrency,
  selectIsFirstPaymentRetryPassed,
  selectPaymentMethod,
  selectPlanId,
  selectPrimerClientSessionToken,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from '../selects'

const MODULE_NAME = 'PAYMENT_PRIMER'

export const PURCHASE_PRIMER = `${MODULE_NAME}/PURCHASE_PRIMER`
export const SET_PRIMER_CLIENT_SESSION_TOKEN = `${MODULE_NAME}/SET_PRIMER_CLIENT_SESSION_TOKEN`
export const GET_PRIMER_CLIENT_SESSION_TOKEN = `${MODULE_NAME}/GET_PRIMER_CLIENT_SESSION_TOKEN`
export const UPDATE_PRIMER_CLIENT_SESSION = `${MODULE_NAME}/UPDATE_PRIMER_CLIENT_SESSION`
export const SET_IS_PRIMER_RETRY_PROCESSING = `${MODULE_NAME}/SET_IS_PRIMER_RETRY_PROCESSING`
export const SET_IS_FIRST_PAYMENT_RETRY_PASSED = `${MODULE_NAME}/SET_IS_FIRST_PAYMENT_RETRY_PASSED`
export const SET_PRIMER_PAYMENT_FORM_IS_LOADING = `${MODULE_NAME}/SET_PRIMER_PAYMENT_FORM_IS_LOADING`

export const setPrimerClientSessionTokenAction = (
  payload: string,
): IAction<string> => ({
  type: SET_PRIMER_CLIENT_SESSION_TOKEN,
  payload,
})

export function setIsPrimerRetryProcessing(
  isPrimerRetryProcessing: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PRIMER_RETRY_PROCESSING,
    payload: isPrimerRetryProcessing,
  }
}

export const setIsFirstPaymentRetryPassedAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_FIRST_PAYMENT_RETRY_PASSED,
  payload,
})

export function getPrimerClientSessionTokenAction(): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const planId = selectPlanId(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const cohort = selectCurrentVariantCohort(state)

    dispatch(startFetching(GET_PRIMER_CLIENT_SESSION_TOKEN))

    const response = await paymentApi.getPrimerClientSessionToken({
      uuid,
      cohort,
      planId,
      trialPeriodDays,
    })

    if (response.success && response.data) {
      dispatch(setPrimerClientSessionTokenAction(response.data.client_token))
    }

    dispatch(stopFetching(GET_PRIMER_CLIENT_SESSION_TOKEN))
  }
}

export function updatePrimerClientSessionAction(
  retryCallback: () => void,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const clientToken = selectPrimerClientSessionToken(state)
    const paymentId = selectUserPaymentId(state)

    dispatch(startFetching(UPDATE_PRIMER_CLIENT_SESSION))

    try {
      const response = await paymentApi.updatePrimerClientSession({
        uuid,
        paymentId,
        clientToken,
      })

      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))

      if (response.success && response.data && response.data.should_retry) {
        dispatch(stopFetching(PURCHASE_PRIMER))
        dispatch(setPrimerClientSessionTokenAction(response.data.client_token))
        dispatch(setIsPrimerRetryProcessing(true))

        retryCallback()
        return
      }

      const error = i18n.t(
        PRIMER_PAYMENT_ERRORS[response.data.payment_error] ||
          PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
      )

      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(setErrorAction(error))
      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))
      dispatch(stopFetching(PURCHASE_PRIMER))
    } catch (error: any) {
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE_PRIMER))
      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))
    }
  }
}

export function primerConfirmPaymentAction({
  paymentId,
  goal,
  handler,
}: {
  paymentId: string
  goal: TAnswer
  handler: OnTokenizeSuccessHandler
}): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const paymentMethod = selectPaymentMethod(state)

    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    try {
      const response = await paymentApi.confirmPrimerSubscription({
        uuid,
        paymentId,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE_PRIMER))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.success && response?.data?.error) {
        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error: response?.data?.error,
          paymentSystem: PaymentSystem.PRIMER,
          goal,
        })
        dispatch(setErrorAction(response?.data?.error))
        dispatch(stopFetching(PURCHASE_PRIMER))
        dispatch(setIsPrimerRetryProcessing(false))

        handler.handleFailure(response?.data?.error)
        return
      }

      dispatch(savePlanAdditionsAction())
      dispatch(stopFetching(PURCHASE_PRIMER))
      dispatch(setIsPrimerRetryProcessing(false))
      handler.handleSuccess()
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE_PRIMER))
      handler.handleFailure(error.toString())
    }
  }
}

export function primerPurchaseAction({
  goal,
  token,
  handler,
  retryPaymentCallback,
}: {
  goal: TAnswer
  token: string
  handler: OnTokenizeSuccessHandler
  retryPaymentCallback: () => void
}): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const paymentMethod = selectPaymentMethod(state)
    const priceId = selectSubscriptionLookupKey(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const discountTried = selectIsFirstPaymentRetryPassed(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)
    const screenName = selectScreenName(state)

    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)

    dispatch(startFetching(PURCHASE_PRIMER))
    dispatch(
      sendUserConfigAction({
        trial_period: trialPeriodDays,
        is_cancel_offer_tried: screenName === ScreenName.CANCEL_OFFER,
      }),
    )

    try {
      const response = await paymentApi.createPrimerSubscription({
        uuid,
        token,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE_PRIMER))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.data.payment.is_success) {
        const error = {
          type: response.data.payment.status_reason.type,
          description: response.data.payment.status_reason.message,
          code: response.data.payment.status_reason.code,
        }

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error,
          paymentSystem: PaymentSystem.PRIMER,
          goal,
        })

        handler.handleFailure(error.description)

        dispatch(
          setErrorAction(
            i18n.t(
              PRIMER_PAYMENT_ERRORS[error.code] ||
                PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
            ),
          ),
        )
        dispatch(stopFetching(PURCHASE_PRIMER))
        dispatch(setIsPrimerRetryProcessing(false))

        if (
          response?.data.payment.status_reason.declineType ===
            PRIMER_SOFT_DECLINE &&
          paymentMethod === PaymentMethod.CREDIT_CARD &&
          !discountTried
        ) {
          dispatch(updatePrimerClientSessionAction(retryPaymentCallback))
        }

        return
      }

      dispatch(
        setPrimerClientSessionTokenAction(response.data.payment.client_token),
      )

      if (response.data.payment.client_token) {
        dispatch(
          sendUserConfigAction({
            payment_system: PaymentSystem.PRIMER,
            payment_currency: currency,
            payment_method: paymentMethod,
            subscription_price: currentPrice,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: priceId,
            trial_price: trialPrice,
            trial_period: trialPeriodDays,
            is_cancel_offer_purchased: screenName === ScreenName.CANCEL_OFFER,
            payment_id: response.data.payment.payment_id,
          }),
        )

        handler.continueWithNewClientToken(response.data.payment.client_token)
        return
      }

      dispatch(
        primerConfirmPaymentAction({
          paymentId: response.data.payment.payment_id,
          handler,
          goal,
        }),
      )

      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        productId: response.data.purchase.product_id,
        price: currentPrice,
        trialPrice,
        trialPeriodDays,
        discountApplied: response.data.purchase.discount_applied,
        subscriptionId: response.data.purchase.subscription_id,
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        goal,
      })

      dispatch(
        sendUserConfigAction({
          payment_system: PaymentSystem.PRIMER,
          payment_currency: currency,
          payment_method: paymentMethod,
          subscription_price: currentPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: trialPrice,
          trial_period: trialPeriodDays,
          is_cancel_offer_purchased: screenName === ScreenName.CANCEL_OFFER,
        }),
      )
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE_PRIMER))
      handler.handleFailure(error.toString())
    }
  }
}

export function primerResumePurchaseAction({
  goal,
  currentPaymentId,
  resumeToken,
  handler,
  retryPaymentCallback,
}: {
  goal: TAnswer
  currentPaymentId: string | undefined
  resumeToken: string
  handler: OnResumeSuccessHandler
  retryPaymentCallback: () => void
}): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const paymentMethod = selectPaymentMethod(state)
    const priceId = selectSubscriptionLookupKey(state)
    const paymentId = selectUserPaymentId(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const discountTried = selectIsFirstPaymentRetryPassed(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)
    const screenName = selectScreenName(state)

    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    dispatch(startFetching(PURCHASE_PRIMER))

    try {
      const response = await paymentApi.resumePrimerSubscription({
        uuid,
        paymentId: currentPaymentId || paymentId,
        resumeToken,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE_PRIMER))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.data.payment.is_success) {
        const error = {
          type: response.data.payment.status_reason.type,
          description: response.data.payment.status_reason.message,
          code: response.data.payment.status_reason.code,
        }

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error,
          paymentSystem: PaymentSystem.PRIMER,
          goal,
        })

        handler.handleFailure(error.description)

        dispatch(
          setErrorAction(
            i18n.t(
              PRIMER_PAYMENT_ERRORS[error.code] ||
                PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
            ),
          ),
        )
        dispatch(stopFetching(PURCHASE_PRIMER))
        dispatch(setIsPrimerRetryProcessing(false))

        if (
          response?.data.payment.status_reason.declineType ===
            PRIMER_SOFT_DECLINE &&
          paymentMethod === PaymentMethod.CREDIT_CARD &&
          !discountTried
        ) {
          dispatch(updatePrimerClientSessionAction(retryPaymentCallback))
        }

        return
      }

      if (response.data.payment.client_token) {
        handler.continueWithNewClientToken(response.data.payment.client_token)
        return
      }

      dispatch(
        primerConfirmPaymentAction({
          paymentId: response.data.payment.payment_id,
          handler,
          goal,
        }),
      )
      dispatch(
        setPrimerClientSessionTokenAction(response.data.payment.client_token),
      )

      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        productId: response.data.purchase.product_id,
        price: currentPrice,
        trialPrice,
        trialPeriodDays,
        discountApplied: response.data.purchase.discount_applied,
        subscriptionId: response.data.purchase.subscription_id,
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        goal,
      })

      dispatch(
        sendUserConfigAction({
          payment_system: PaymentSystem.PRIMER,
          payment_currency: currency,
          payment_method: paymentMethod,
          subscription_price: currentPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: trialPrice,
          trial_period: trialPeriodDays,
          is_cancel_offer_purchased: screenName === ScreenName.CANCEL_OFFER,
        }),
      )
      handler.handleSuccess()
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE_PRIMER))
      handler.handleFailure(error.toString())
    }
  }
}
