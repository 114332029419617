import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'

import heartHealthImage from 'assets/images/heart-health-goal.png'
import reduceStressImage from 'assets/images/reduce-stress-goal.png'

import { goTo } from 'browser-history'
import { Language, MAIN_GOAL_OPTION_VALUES } from 'root-constants/common'

import { StyledSummaryHealthPlan as S } from './SummaryHealthPlan.styles'
import {
  BENEFITS_DATA_HEART_HEALTH,
  BENEFITS_DATA_REDUCE_STRESS,
  PERSONAL_PLAN_SUBTITLE_MAP,
} from './constants'

export const SummaryHealthPlan: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { prioritizedUserGoal } = useUserData()

  const isAlternativeGoal = useMemo(
    () =>
      prioritizedUserGoal === MAIN_GOAL_OPTION_VALUES.REDUCE_STRESS ||
      prioritizedUserGoal === MAIN_GOAL_OPTION_VALUES.SLEEP_BETTER,
    [prioritizedUserGoal],
  )

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: t(PERSONAL_PLAN_SUBTITLE_MAP[prioritizedUserGoal], {
        lng: Language.EN,
      }),
    })
  }, [prioritizedUserGoal, t])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({
      pageName: t(PERSONAL_PLAN_SUBTITLE_MAP[prioritizedUserGoal], {
        lng: Language.EN,
      }),
    })
    goTo(nextPagePath)
  }, [nextPagePath, prioritizedUserGoal, t])

  return (
    <S.Wrapper>
      <S.Content>
        <S.PersonalSummaryTitle>
          {t`summary.heartHealth.title2`}
        </S.PersonalSummaryTitle>
        <S.Subtitle>
          {t`summary.heartHealth.subtitle`}{' '}
          <strong>{t(PERSONAL_PLAN_SUBTITLE_MAP[prioritizedUserGoal])}</strong>
        </S.Subtitle>
        <S.ImageContainer>
          {isAlternativeGoal ? (
            <img src={reduceStressImage} alt="reduce_stress" />
          ) : (
            <img src={heartHealthImage} alt="heart_health" />
          )}
        </S.ImageContainer>
        <S.PlanTitle>{t`summary.heartHealth.planTitle`}</S.PlanTitle>
        <S.BenefitsList>
          {(isAlternativeGoal
            ? BENEFITS_DATA_REDUCE_STRESS
            : BENEFITS_DATA_HEART_HEALTH
          ).map(({ text }) => (
            <li key={text}>
              <Trans i18nKey={text} />
            </li>
          ))}
        </S.BenefitsList>
        <Button type="button" onClick={handleContinue}>
          {t`actions.continue`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
