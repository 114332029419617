import { Goal, MAIN_GOAL_OPTION_VALUES } from 'root-constants/common'

export const PAGE_NAME = 'Main goal question'

export const OPTION_VALUES = {
  CONTROL_HEART_HEALTH: 'heart_rate',
  CONTROL_BLOOD_PRESSURE: 'blood_pressure',
  LOWER_CHOLESTEROL: 'lower_cholesterol',
  HEART_ATTACK: 'heart_attack_prevention',
}

export const OPTION_VALUE_TO_GOAL_MAP = {
  [MAIN_GOAL_OPTION_VALUES.CONTROL_BLOOD_PRESSURE]: Goal.HEART_HEALTH,
  [MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH]: Goal.HEART_HEALTH,
  [MAIN_GOAL_OPTION_VALUES.REDUCE_STRESS]: Goal.REDUCE_STRESS,
  [MAIN_GOAL_OPTION_VALUES.SLEEP_BETTER]: Goal.REDUCE_STRESS,
  [MAIN_GOAL_OPTION_VALUES.REDUCE_MEDICATION_INTAKE]: Goal.HEART_HEALTH,
  [MAIN_GOAL_OPTION_VALUES.OTHER]: Goal.HEART_HEALTH,
}
export const OPTION_VALUES_MAIN_GOAL_6 = [
  {
    value: OPTION_VALUES.HEART_ATTACK,
    text: 'onboarding.mainGoal.heartAttackPrevention',
  },
  {
    value: OPTION_VALUES.CONTROL_BLOOD_PRESSURE,
    text: 'onboarding.mainGoal.controlBloodPressure',
  },
  {
    value: OPTION_VALUES.CONTROL_HEART_HEALTH,
    text: 'onboarding.mainGoal.controlHeartRate',
  },
  {
    value: OPTION_VALUES.LOWER_CHOLESTEROL,
    text: 'onboarding.mainGoal.lowerCholesterol',
  },
]
