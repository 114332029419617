import styled from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import {
  Color,
  MediaBreakpoint,
  SENIOR_FLOW_HEADER_HEIGHT,
} from 'root-constants/common'

export const StyledSocialProofVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    min-height: 100dvh;
    background-color: #f2f3f5;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 24px;
    flex-grow: 1;
  `,
  Image: styled.img`
    aspect-ratio: 343/303;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h1`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.GRAY_100};
    margin-bottom: 8px;
  `,
  Text: styled.p`
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY_80};
    margin-bottom: 24px;
    flex-grow: 1;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #f0f2fa 24%
    );
  `,
}
