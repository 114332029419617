import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserHeartAge } from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'

import heartScoreImage from 'assets/images/heart-score-image.png'

import { goTo } from 'browser-history'

import { StyledSummaryHeartScore as S } from './SummaryHeartScore.styles'

const PAGE_NAME = 'Heart score analysis'

export const SummaryHeartScore: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const userHeartAge = useSelector(selectUserHeartAge)

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: PAGE_NAME,
    })
  }, [])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({
      pageName: PAGE_NAME,
    })
    goTo(nextPagePath)
  }, [nextPagePath])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`summary.summaryHeartScore.title`}</S.Title>
        <S.HeartScoreContentContainer>
          <S.HeartDataWrapper>
            <S.WrapperTitle>{t`summary.summaryHeartScore.heartScore`}</S.WrapperTitle>
            <S.HighlightedText>49/100</S.HighlightedText>
          </S.HeartDataWrapper>
          <S.Text>{t`summary.summaryHeartScore.heartScoreImpacts`}</S.Text>
        </S.HeartScoreContentContainer>
        <S.HeartScoreContentContainer>
          <S.HeartDataWrapper>
            <S.WrapperTitle>{t`summary.summaryHeartScore.heartAge`}</S.WrapperTitle>
            <S.HighlightedText>{userHeartAge}</S.HighlightedText>
          </S.HeartDataWrapper>
          <S.Text>{t`summary.summaryHeartScore.heartAgeOlder`}</S.Text>
        </S.HeartScoreContentContainer>
        <S.ImageContainer>
          <img src={heartScoreImage} alt="heart_score" />
        </S.ImageContainer>
        <S.HighlightedText>{t`summary.summaryHeartScore.noWorries`}</S.HighlightedText>
        <S.BenefitsText>{t`summary.summaryHeartScore.increaseHeartScore`}</S.BenefitsText>
        <Button type="button" onClick={handleContinue}>
          {t`actions.continue`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
