import React from 'react'

import RateImage from 'assets/images/low-risk.jpg'

import { StyledHealthyLevelGraph as S } from './HealthyLevelGraph.styles'

type TProps = React.HTMLAttributes<HTMLDivElement> & {
  marginBottom: number
}

export const HealthyLevelGraph: React.FC<TProps> = ({ children, ...props }) => (
  <S.Wrapper {...props}>
    <S.Title>{children}</S.Title>
    <img src={RateImage} height={147} alt="healthy rate" />
  </S.Wrapper>
)
