import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import sleepDescriptionFemale from 'assets/images/sleep-description-female.webp'
import sleepDescriptionMale from 'assets/images/sleep-description-male.webp'

import { StyledSleep as S } from './Sleep.styles'

const PAGE_NAME_MALE = 'Social Proof Sleep Man'
const PAGE_NAME_FEMALE = 'Social Proof Sleep Woman'

export const SleepDescriptionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isMale } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: isMale ? PAGE_NAME_MALE : PAGE_NAME_FEMALE,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.TitleSleepDescription>
          {t`onboarding.sleep.changesInSleep`}
        </S.TitleSleepDescription>
        <S.Description>
          <Trans i18nKey="onboarding.sleep.changesInSleepDescription" />
        </S.Description>
        <S.Image
          src={isMale ? sleepDescriptionMale : sleepDescriptionFemale}
          alt="gender"
          loading="lazy"
        />
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
