import styled from 'styled-components'

import { ModalContainerBase, ModalWrapperBase } from 'common-styles'

import { Button } from '../Button'

export const StyledModal = {
  Wrapper: styled.div`
    ${ModalWrapperBase};
  `,
  Container: styled.div`
    ${ModalContainerBase};
  `,
  TextWrapper: styled.div`
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  `,
  Button: styled(Button)`
    width: 100%;
    border-radius: 12px;
  `,
}
