import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import alcoholDescription from 'assets/images/alcohol-description.webp'

import { StyledAlcoholDescriptionVariant1 as S } from './AlcoholDescriptionVariant1.styles'

const PAGE_NAME = 'Social Proof Alcohol'

export const AlcoholDescriptionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.alcohol.alcoholConsumption`}</S.Title>
        <S.Description>
          <Trans i18nKey="onboarding.alcohol.alcoholDescription" />
        </S.Description>
        <S.Image
          src={alcoholDescription}
          alt="alcohol-consequences"
          loading="lazy"
        />
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
