import { AnyAction } from 'redux'

import { RESET_EMAIL_ERROR_MESSAGE, SET_EMAIL_ERROR_MESSAGE } from './actions'

export interface IAccountState {
  emailErrorMessage: string
}

const initialState: IAccountState = {
  emailErrorMessage: '',
}

export function accountReducer(
  state = initialState,
  { type, payload }: AnyAction,
): IAccountState {
  switch (type) {
    case SET_EMAIL_ERROR_MESSAGE: {
      return { ...state, emailErrorMessage: payload }
    }
    case RESET_EMAIL_ERROR_MESSAGE: {
      return { ...state, emailErrorMessage: '' }
    }
    default:
      return state
  }
}
