import appStoreIcon from 'assets/images/app-store-icon.png'
import heartWithPulse from 'assets/images/heart-with-pulse.png'
import people from 'assets/images/people.png'
import michael from 'assets/images/review-avatar-9.jpg'
import rose from 'assets/images/review-avatar-10.jpg'

import { Cohort, Color, Gender } from 'root-constants/common'

export const PAGE_NAME = 'Preparing Personal Flow'

export const PROCESSING_TEXTS = [
  'onboarding.processing.reviewingData',
  'onboarding.processing.heartHealth',
  'onboarding.processing.healthRisks',
  'onboarding.processing.training',
  'onboarding.processing.fineTuning',
  'onboarding.processing.healthReport',
]

export const PROCESSING_TEXT_FOUR_POINTS = [
  'onboarding.processing.reviewingData',
  'onboarding.processing.heartHealth',
  'onboarding.processing.healthRisks',
  'onboarding.processing.training',
]

export const PROCESSING_DATA = [
  {
    text: 'onboarding.processing.reviewingData',
    threshold: 0,
  },
  {
    text: 'onboarding.processing.heartHealth',
    threshold: 20,
  },
  {
    text: 'onboarding.processing.healthRisks',
    threshold: 40,
  },
  {
    text: 'onboarding.processing.training',
    threshold: 60,
  },
  {
    text: 'onboarding.processing.healthReport',
    threshold: 80,
  },
]

export const PROCESSING_DATA_2 = [
  {
    text: 'onboarding.processing.reviewingData',
    threshold: 0,
  },
  {
    text: 'onboarding.processing.training',
    threshold: 33,
  },
  {
    text: 'onboarding.processing.healthReport',
    threshold: 66,
  },
]

export const PROCESSING_DATA_3 = [
  {
    text: 'onboarding.processing.personalProfile',
    threshold: 0,
  },
  {
    text: 'onboarding.processing.addingData',
    threshold: 33,
  },
  {
    text: 'onboarding.processing.analysingAnswers',
    threshold: 66,
  },
]

export const PROCESSING_DATA_4 = [
  {
    text: 'onboarding.processing.reviewingData',
    threshold: 0,
  },
  {
    text: 'onboarding.processing.heartHealth',
    threshold: 33,
  },
  {
    text: 'onboarding.processing.healthRisks',
    threshold: 66,
  },
]

export const APP_HIGHLIGHTS_DATA = [
  {
    imageSrc: appStoreIcon,
    title: 'onboarding.processing.appHighlights.downloads',
    hasRating: true,
    text: 'onboarding.processing.appHighlights.rating',
  },
  {
    imageSrc: heartWithPulse,
    title: 'onboarding.processing.appHighlights.pulseMeasurementsNumber',
    hasRating: false,
    text: 'onboarding.processing.appHighlights.pulseMeasurements',
  },
  {
    imageSrc: people,
    title: 'onboarding.processing.appHighlights.usersNumber',
    hasRating: false,
    text: 'onboarding.processing.appHighlights.healthyUsers',
  },
]

export const PROGRESS_COLORS = {
  [Cohort.CARDIMATE_2]: {
    trailColor: Color.WHITE,
  },
  [Cohort.CARDIMATE_10]: {
    pathColor: '#01cda1',
    trailColor: '#f0f2fa',
  },
  [Cohort.CARDIMATE_12]: {
    pathColor: '#01cda1',
    trailColor: '#f0f2fa',
  },
}

export const PROCESSING_4_REVIEW_DATA = {
  [Gender.MALE]: {
    avatar: michael,
    name: 'commonComponents.reviewsSlider.ninthUser.name',
  },
  [Gender.FEMALE]: {
    avatar: rose,
    name: 'commonComponents.reviewsSlider.tenthUser.name',
  },
  [Gender.OTHER]: {
    avatar: michael,
    name: 'commonComponents.reviewsSlider.ninthUser.name',
  },
}
