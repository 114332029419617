import React from 'react'

import { TPageProps } from 'models/common.model'

import { KitRadioOptionPage } from 'pages/kit-options-page'

import { OPTION_VALUES_BLOOD_PRESSURE_4, TITLE_PATH } from './constants'

export const MonitorBloodPressureVariant4: React.FC<TPageProps> = (props) => (
  <KitRadioOptionPage
    {...props}
    options={OPTION_VALUES_BLOOD_PRESSURE_4}
    titlePath={TITLE_PATH}
  />
)
