import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'

type TProps = {
  isSeniorCohort: boolean
}
export const StyledMedicine = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${({ theme }) => theme.common.wrapperPaddingTop};
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: ${({ theme }) => theme.common.columnPaddingTop};
    padding-bottom: 46px;
  `,
  Title: styled.h1<TProps>`
    ${baseTitleStyles};
    margin-bottom: ${({ isSeniorCohort }) => (isSeniorCohort ? 24 : 40)}px;
  `,
}
