import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CUSTOMER_REVIEWS } from 'components/ReviewSlider/constants'

import ratingStars from 'assets/images/rating-stars.svg'

import { StyledReviewsSlider as S } from './ReviewSlider.styles'

type TProps = {
  titlePath?: string
  titleBottomMargin?: number
  marginBottom?: number
  textAlign?: string
} & React.HTMLAttributes<HTMLDivElement>

export const ReviewsSlider: React.FC<TProps> = ({
  titlePath,
  titleBottomMargin,
  marginBottom,
  textAlign,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper marginBottom={marginBottom} {...props}>
      {!!titlePath && (
        <S.Title titleBottomMargin={titleBottomMargin} textAlign={textAlign}>
          <Trans i18nKey={titlePath} />
        </S.Title>
      )}
      <Swiper
        slidesPerView="auto"
        spaceBetween={24}
        centeredSlides
        modules={[Pagination]}
        pagination={{ clickable: true }}
      >
        {CUSTOMER_REVIEWS.map(({ avatar, author, title, review }) => (
          <SwiperSlide key={author}>
            <S.Card>
              <S.CardHeader>
                <S.Avatar width="40" height="40" src={avatar} loading="lazy" />
                <div>
                  <img
                    height="15"
                    src={ratingStars}
                    alt="rating 5 stars"
                    loading="lazy"
                  />
                  <S.PersonDescription>{t(author)}</S.PersonDescription>
                </div>
              </S.CardHeader>
              <S.CardTitle>{t(title)}</S.CardTitle>
              <S.CardText>{t(review)}</S.CardText>
            </S.Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Wrapper>
  )
}
