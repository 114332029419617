import styled from 'styled-components'

import {
  commonListStyles,
  commonTitleStyles,
} from 'modules/subscriptions/styles'

import { Button } from 'components/Button'

import checkIconGreen from 'assets/images/check-icon-wavy-borders-green.svg'
import checkIconBlue from 'assets/images/check-icon-wavy-borders.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color } from 'root-constants/common'

type TProps = {
  isThirteenCohort: boolean
}
export const StyledSubscriptionsVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 80px 0 100px;
  `,
  Title: styled.h3<TProps>`
    ${commonTitleStyles};
    margin-bottom: 16px;
    font-size: ${({ isThirteenCohort }) => (isThirteenCohort ? 22 : 24)}px;
    color: ${({ isThirteenCohort }) =>
      isThirteenCohort ? Color.DEFAULT_TEXT : Color.BLUE};
  `,
  Subtitle: styled.h3`
    margin-bottom: 48px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  `,
  Congratulations: styled.h3<TProps>`
    margin-bottom: 16px;
    font-weight: ${({ isThirteenCohort }) => (isThirteenCohort ? 800 : 900)};
    font-size: ${({ isThirteenCohort }) => (isThirteenCohort ? 14 : 13)}px;
    line-height: 16px;
    text-align: center;
    color: ${({ isThirteenCohort }) =>
      isThirteenCohort ? Color.DANGER : Color.DEFAULT_TEXT};
  `,
  List: styled.div<TProps>`
    ${commonListStyles};

    li {
      background-image: ${({ isThirteenCohort }) =>
        isThirteenCohort ? `url(${checkIconGreen})` : `url(${checkIconBlue})`};
      color: ${({ isThirteenCohort }) =>
        isThirteenCohort ? Color.LIGHT_TEXT : Color.DEFAULT_TEXT};
    }
  `,
  Disclaimer: styled.p<TProps>`
    font-weight: 500;
    font-size: ${({ isThirteenCohort }) => (isThirteenCohort ? 16 : 13)}px;
    line-height: 18px;
    margin-bottom: 48px;
    color: ${({ isThirteenCohort }) =>
      isThirteenCohort ? Color.LIGHT_TEXT : Color.DEFAULT_TEXT};
    text-align: center;
  `,
  SubscriptionDescription: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 48px;
    text-align: center;
    color: #565976;

    & a {
      color: #565976;
      text-decoration: underline;
    }
  `,
  CancelDisclaimer: styled.p<TProps>`
    font-weight: 500;
    font-size: ${({ isThirteenCohort }) => (isThirteenCohort ? 14 : 13)}px;
    line-height: 18px;
    margin-bottom: 48px;
    color: ${({ isThirteenCohort }) =>
      isThirteenCohort ? Color.LIGHT_TEXT : Color.DEFAULT_TEXT};
    text-align: center;
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
}
