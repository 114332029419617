import styled from 'styled-components'

import label from 'assets/images/check-circle-green.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledBloodPressureGraphVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 40px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 24px;
  `,
  GraphWrapper: styled.div`
    display: flex;
    padding: 16px 12px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
    margin-bottom: 40px;
  `,
  BenefitsList: styled.ul`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.LIGHT_TEXT};

    li {
      background-image: url(${label});
      background-repeat: no-repeat;
      background-position: left top;
      padding-left: 32px;
      margin-bottom: 16px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    li:first-child {
      margin-bottom: 8px;
    }

    strong {
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      color: ${Color.LIGHT_TEXT};
    }
  `,
  ImageContainer: styled.div`
    max-width: 273px;
    width: 100%;
    aspect-ratio: 273/177;

    img {
      max-width: 273px;
      margin-bottom: 16px;
      margin-left: 30px;
    }
  `,
}
