import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useProgressBarList } from 'hooks/useProgressBarList'

import { TPageProps } from 'models/common.model'

import { Color } from 'root-constants/common'

import { StyledAdaptingProfileVariant1 as S } from './AdaptingProfileVariant1.styles'

export const AdaptingProfileVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const {
    firstCounterPercent,
    secondCounterPercent,
    thirdCounterPercent,
    isFirstProcessingFinished,
    isSecondProcessingFinished,
    isThirdProcessingFinished,
  } = useProgressBarList(true)

  const barsList = useMemo(
    () => [
      {
        listTitle: 'onboarding.adaptingProfile.healthProfile',
        counter: firstCounterPercent,
        textColor: `${Color.DEFAULT_TEXT}`,
      },
      {
        listTitle: 'onboarding.adaptingProfile.habits',
        counter: secondCounterPercent,
        textColor: isFirstProcessingFinished
          ? `${Color.DEFAULT_TEXT}`
          : `${Color.MIDDLE_GRAY}`,
      },
      {
        listTitle: 'onboarding.adaptingProfile.dailyRoutine',
        counter: thirdCounterPercent,
        textColor: isSecondProcessingFinished
          ? `${Color.DEFAULT_TEXT}`
          : `${Color.MIDDLE_GRAY}`,
      },
    ],
    [
      firstCounterPercent,
      secondCounterPercent,
      thirdCounterPercent,
      isFirstProcessingFinished,
      isSecondProcessingFinished,
    ],
  )

  useEffect(() => {
    if (!isThirdProcessingFinished) return
    handleContinue('')
  }, [handleContinue, isThirdProcessingFinished])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.adaptingProfile.title`}</S.Title>
        <S.ProgressBarsList>
          {barsList.map(({ counter, listTitle, textColor }) => (
            <S.ProgressContainer key={listTitle}>
              <S.ProgressHeader>
                <S.ProgressTitle color={textColor}>
                  <Trans i18nKey={listTitle} />
                </S.ProgressTitle>
                <S.ProgressPercent color={textColor}>
                  {counter}%
                </S.ProgressPercent>
              </S.ProgressHeader>
              <S.ProgressBar max={100} value={counter} />
            </S.ProgressContainer>
          ))}
        </S.ProgressBarsList>
      </S.Column>
    </S.Wrapper>
  )
}
