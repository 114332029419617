export const EMAIL_DOMAINS = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'live.com',
  'msn.com',
  'me.com',
  'mac.com',
  'googlemail.com',
  'facebook.com',
  'verizon.net',
  'rediffmail.com',
  'zohomail.com',
  'zoho.com',
  'mail.com',
  'google.com',
  'comcast.com',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'att.net',
  'gmz.com',
]

export const EMAIL_DOMAIN_REGEXP = /@(.*)/
export const EMAIL_USERNAME_REGEXP = /(.*)@/
