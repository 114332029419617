import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { HealthStats } from 'modules/summary/components/HealthStats'
import { HeartStats } from 'modules/summary/components/HeartStats'

import { Button } from 'components/Button'

import warningIcon from 'assets/images/sprite/warning.svg'

import { goTo } from 'browser-history'

import { StyledSummaryHealthProfile as S } from './SummaryHealthProfile.styles'

export const SummaryHealthProfile: React.FC<TPageProps> = ({ pageId }) => {
  const { search } = useLocation()
  const { t } = useTranslation()

  const { currentSubscriptionPageId } = useGetPageInfo()
  const { name } = useUserData()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: pageId,
    })
  }, [pageId])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({
      pageName: pageId,
    })
    goTo({ pathname: currentSubscriptionPageId, search })
  }, [pageId, currentSubscriptionPageId, search])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          <Trans
            i18nKey="summary.summaryHealthProfile.title"
            values={{
              name: name ? `${name},` : '',
            }}
          />
        </S.Title>
        <HealthStats />

        <S.DisclaimerContainer>
          <S.DisclaimerIcon svg={warningIcon} width={24} />
          <S.DisclaimerText>{t`summary.summaryHealthProfile.reduceRisk`}</S.DisclaimerText>
        </S.DisclaimerContainer>

        <HeartStats />

        <S.Subtitle>
          <Trans i18nKey="summary.summaryHealthProfile.trackMetrics" />
        </S.Subtitle>
        <S.Text>
          <Trans i18nKey="summary.summaryTestResults.note" />
        </S.Text>
        <S.Text marginBottom={40}>
          {t`summary.summaryHealthProfile.chartData`}
        </S.Text>
        <S.StickyButtonContainer>
          <Button type="button" onClick={handleContinue}>
            {t`actions.getCardi`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
