import styled from 'styled-components'

import { Input } from 'components/Input'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledEmailVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 24px 0 20px;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 40px;

    strong {
      color: ${({ theme }) => theme.colors.highlightedText};
      font-weight: inherit;
    }
  `,
  Subtitle: styled.h3`
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Color.LIGHT_TEXT};

    strong {
      font-weight: 900;
    }
  `,
  Input: styled(Input)`
    height: 60px;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.placeholderTextColor};
      font-style: italic;
      font-weight: 300;
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.placeholderTextColor};
      font-style: italic;
      font-weight: 300;
    }
  `,
  InputWrapper: styled.div`
    margin-bottom: 12px;
    width: 100%;
    position: relative;
  `,
  AutoCompleteWrapper: styled.div`
    width: 100%;
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 62px;
    left: 0;
    border-radius: 12px;
    overflow-y: auto;
    background-color: white;
    border: ${({ theme }) => `1px solid ${theme.colors.primaryBorder}`};
    z-index: 1;
  `,
  AutoCompleteButton: styled.button`
    height: 40px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-decoration: none;
    text-align: left;
    color: ${Color.DEFAULT_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px dotted #bcc9d0;
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Disclaimer: styled.p`
    margin-bottom: 40px;
    color: ${Color.LIGHT_TEXT};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
}
