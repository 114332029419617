import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import { StyledEasyToUse as S } from './EasyToUse.styles'

const PAGE_NAME = 'User comment page'

export const EasyToUseVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.easyToUse.title`}</S.Title>
        <S.Feedback>
          <S.Text>
            {t`onboarding.easyToUse.review`}
            <br />
            <S.Name>{t`onboarding.easyToUse.author`}</S.Name>
          </S.Text>
        </S.Feedback>
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
