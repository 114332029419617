import highPressureGraph from 'assets/images/blood-pressure-graph-high.jpg'
import lowPressureGraph from 'assets/images/blood-pressure-graph-low.jpg'
import normalPressureGraph from 'assets/images/blood-pressure-graph-normal.jpg'

import { BLOOD_PRESSURE_OPTION_VALUES } from 'root-constants/common'

export const MAX_SYMPTOMS_SIZE = 1

export const IMAGES = {
  [BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80]: lowPressureGraph,
  [BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_80]: normalPressureGraph,
  [BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89]: highPressureGraph,
  [BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90]: highPressureGraph,
  [BLOOD_PRESSURE_OPTION_VALUES.DONT_KNOW]: highPressureGraph,
}
