import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { StyledHeartDiseaseVariant3 as S } from './HeartDiseaseVariant3.styles'
import { OPTION_VALUES } from './constants'

export const HeartDiseaseVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.heartDisease.title', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, pageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.heartDisease.title`}</S.Title>
        <S.Subtitle>{t`onboarding.heartDisease.subtitle`}</S.Subtitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.PARENTS_SIBLINGS}
            checked={answers.includes(OPTION_VALUES.PARENTS_SIBLINGS)}
          >
            <QuestionButton data-has-check-icon>
              <Trans i18nKey="onboarding.heartDisease.parentsSiblings" />
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.GRANDPARENTS}
            checked={answers.includes(OPTION_VALUES.GRANDPARENTS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.heartDisease.grandParents`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_HISTORY}
            checked={answers.includes(OPTION_VALUES.NO_HISTORY)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.heartDisease.noHistory`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.UNSURE}
            checked={answers.includes(OPTION_VALUES.UNSURE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.heartDisease.unsure`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
