import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGetPrices } from 'hooks/useGetPrices'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'
import { BENEFITS } from 'modules/subscriptions/pages/CancelOffer/constants'

import { ReviewsSlider } from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'

import { SubscriptionTag } from 'root-constants/common'

import { PlanBlockVariant1 } from '../../components/cancel-offer/PlanBlockVariant1'
import { StyledCancelOffer as S } from './CancelOffer.styles'

export const CancelOffer: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )

  const { goal } = useUserData()
  const hasIncludedVAT = useVatInfo()
  const { currentPaymentPageId } = useGetPageInfo()
  const { hasPrices, handleShowPayment } = usePaywall(
    `${currentPaymentPageId}${search}`,
  )

  useGetPrices({
    screenName: ScreenName.CANCEL_OFFER,
    tags: hasIncludedVAT
      ? `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.TAX}`
      : `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.NO_TAX}`,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.CANCEL_OFFER,
      goal,
    })
  }, [subscriptions, goal])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans i18nKey="subscriptions.cancelOffer.title" />
        </S.Title>
        <S.CancelOfferText>
          <Trans i18nKey="subscriptions.cancelOffer.subtitle" />
        </S.CancelOfferText>

        <S.PromoBlock>
          <Trans
            i18nKey="subscriptions.cancelOffer.promoDescription"
            components={{ b: <b />, span: <span /> }}
          />
        </S.PromoBlock>
        <S.SubscriptionBlockTitle>
          {t`subscriptions.investInHealth`}
        </S.SubscriptionBlockTitle>

        <PlanBlockVariant1 />

        <S.Button onClick={handleShowPayment}>
          {t`subscriptions.cancelOffer.discount`}
        </S.Button>
        <S.Title>{t`subscriptions.cancelOffer.cardiBenefits`}</S.Title>

        <S.BenefitsList>
          {BENEFITS.map((path) => (
            <li key={path}>
              <Trans i18nKey={path} />
            </li>
          ))}
        </S.BenefitsList>
        <ReviewsSlider
          titlePath="subscriptions.customersLove"
          marginBottom={16}
        />
        <S.Button onClick={handleShowPayment}>
          {t`subscriptions.cancelOffer.discount`}
        </S.Button>
        <MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
