import React from 'react'

import { TPageProps } from 'models/common.model'

import { KitRadioOptionPage } from 'pages/kit-options-page'

import { CustomPageId } from 'root-constants/pages'

import { KIT_BLOOD_PRESSURE_OPTION_DATA, TITLE_PATH } from './constants'

export const BloodPressureVariant6: React.FC<TPageProps> = (props) => (
  <KitRadioOptionPage
    {...props}
    pageId={CustomPageId.BLOOD_PRESSURE}
    options={KIT_BLOOD_PRESSURE_OPTION_DATA}
    titlePath={TITLE_PATH}
  />
)
