import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ProgressRounded } from 'components/ProgressRounded'
import { ReviewsSlider } from 'components/ReviewSlider'

import ratingStars from 'assets/images/rating-stars.svg'

import { Cohort } from 'root-constants/common'

import { StyledPlanProcessingVariant2 as S } from './PlanProcessingVariant2.styles'
import {
  APP_HIGHLIGHTS_DATA,
  PAGE_NAME,
  PROCESSING_DATA,
  PROCESSING_DATA_2,
  PROGRESS_COLORS,
} from './constants'

export const PlanProcessingVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const [progressText, setProgressText] = useState('')

  const isTenOrTwelveCohort = useMemo(
    () =>
      cohortToUse === Cohort.CARDIMATE_10 ||
      cohortToUse === Cohort.CARDIMATE_12,
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  const { linearCounterValue } = useCounter(true, {
    duration: isTenOrTwelveCohort ? 6000 : 7500,
    callback: handleContinue,
  })

  useEffect(() => {
    const processingData = isTenOrTwelveCohort
      ? PROCESSING_DATA_2
      : PROCESSING_DATA
    const loaderPoint = processingData.find(
      ({ threshold }) => threshold === linearCounterValue,
    )

    if (loaderPoint) {
      setProgressText(loaderPoint.text)
    }
  }, [isTenOrTwelveCohort, linearCounterValue])

  return (
    <S.Wrapper>
      <S.Column>
        <S.ProgressContainer>
          <ProgressRounded
            value={linearCounterValue}
            pathColor={PROGRESS_COLORS[cohortToUse].pathColor}
            trailColor={PROGRESS_COLORS[cohortToUse].trailColor}
          />
          <S.ProgressText key={progressText}>{t(progressText)}</S.ProgressText>
        </S.ProgressContainer>

        <S.Title>{t`onboarding.processing.title3`}</S.Title>
        <S.Subtitle>{t`onboarding.processing.subtitle2`}</S.Subtitle>

        <S.AppHighlightsContainer>
          {APP_HIGHLIGHTS_DATA.map(({ imageSrc, title, text, hasRating }) => (
            <S.Highlight key={title}>
              <S.HighlightImage src={imageSrc} alt="icon" />
              <S.HighlightTitle>{t(title)}</S.HighlightTitle>
              {hasRating && (
                <S.HighlightRating src={ratingStars} alt="rating" />
              )}
              <S.HighlightText>
                <Trans i18nKey={text} />
              </S.HighlightText>
            </S.Highlight>
          ))}
        </S.AppHighlightsContainer>

        <ReviewsSlider />
      </S.Column>
    </S.Wrapper>
  )
}
