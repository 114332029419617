import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import bloodPressureDescription from 'assets/images/blood-pressure-description.webp'

import { StyledBloodPressure as S } from './BloodPressure.styles'

const PAGE_NAME = 'Social Proof Blood Pressure'

export const BloodPressureDescriptionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.TitlePressureDescription>
          {t`onboarding.bloodPressure.highPressure`}
        </S.TitlePressureDescription>
        <S.Description>
          <Trans i18nKey="onboarding.bloodPressure.pressureDescription" />
        </S.Description>
        <S.Image src={bloodPressureDescription} alt="heart" loading="lazy" />
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
