import { useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectFacebookPixelId } from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { FBConsent } from 'root-constants/common'

export const useFacebookPixelInitialization = (): void => {
  const uuid = useSelector(selectUUID)
  const facebookPixelId = useSelector(selectFacebookPixelId)
  const userCountryCode = useSelector(selectUserCountryCode)

  const { isEUUser, hasAdvertisingAndTargetingCookie } =
    useCookieConsentAnswer()

  useLayoutEffect(() => {
    if (!window.fbq || !facebookPixelId || !uuid || !userCountryCode) return

    window.fbq('init', facebookPixelId, { external_id: uuid })
    window.fbq('track', 'PageView')
  }, [facebookPixelId, userCountryCode, uuid])

  useEffect(() => {
    if (!window.fbq || !isEUUser) return

    window.fbq(
      'consent',
      hasAdvertisingAndTargetingCookie ? FBConsent.GRANT : FBConsent.REVOKE,
    )
  }, [hasAdvertisingAndTargetingCookie, isEUUser])
}
