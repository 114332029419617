import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { eventLogger } from 'services/eventLogger.service'

import { HeaderWithButton } from 'components/HeaderWithButton'
import { ReviewsSlider } from 'components/ReviewSlider'

import logoImg from 'assets/images/app-name-logo.png'

import { goTo } from 'browser-history'

import { HeartHealth } from '../../components/HeartHealth'
import { PersonalSummary } from '../../components/PersonalSummary'
import { StyledSummaryVariant3 as S } from './SummaryVariant3.styles'

const PAGE_NAME = 'Heart Health Improvement Plan'

export const SummaryVariant3: React.FC = () => {
  const { t } = useTranslation()
  const { currentSubscriptionPageId } = useGetPageInfo()
  const { search } = useLocation()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: PAGE_NAME,
    })
  }, [])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({
      pageName: PAGE_NAME,
    })
    goTo({ pathname: currentSubscriptionPageId, search })
  }, [currentSubscriptionPageId, search])

  return (
    <S.Wrapper>
      <HeaderWithButton onClick={handleContinue} />
      <S.Content>
        <S.PersonalSummaryTitle>{t`summary.yourSummary`}</S.PersonalSummaryTitle>
        <PersonalSummary />
        <HeartHealth handleButtonClick={handleContinue} />
        <ReviewsSlider
          titlePath="summary.successStories"
          titleBottomMargin={48}
          marginBottom={42}
        />
        <S.Button onClick={handleContinue}>{t`actions.startNow`}</S.Button>
        <S.Logo height={28} src={logoImg} alt="cardi mate logo" />
        <S.Description>
          <S.Description>{t`commonComponents.description`}</S.Description>
        </S.Description>
      </S.Content>
    </S.Wrapper>
  )
}
