import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'

import {
  CURRENCY_SYMBOLS,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledDescription as S } from './Description.styles'

export const TrialDescription: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)

  return (
    <ul>
      <S.DescriptionItem>
        {t`payment.description.followInstructions`}
      </S.DescriptionItem>
      <S.DescriptionItem>
        {t('payment.description.trialPeriodV2', {
          trialPrice,
          fullPrice,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
          minimumFractionDigits: 2,
        })}
      </S.DescriptionItem>
    </ul>
  )
}
