import styled, { css } from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TTextProps = {
  textColor: string
}

const progressValueStyles = css`
  border-radius: 5px;
  transition: all 0.2s ease-out;
`

export const StyledAdaptingProfileVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    min-height: 100dvh;
    background-color: #f2f3f5;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    align-items: center;
    padding-top: 24px;
  `,
  Title: styled.h1`
    margin-bottom: 24px;
    color: ${Color.GRAY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  ProgressBarsList: styled.ul`
    min-width: 343px;
  `,
  ProgressContainer: styled.li`
    width: 100%;
    margin-bottom: 44px;
  `,
  ProgressHeader: styled.header`
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ProgressText: styled.span<TTextProps>`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${({ textColor }) => textColor};
  `,
  ProgressBar: styled.progress`
    width: 100%;
    height: 8px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 5px;
    overflow: hidden;
    background: #e9e9e9;

    &::-webkit-progress-bar {
      background: #e9e9e9;
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
      background-color: #35b3e5;
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
      background-color: #35b3e5;
    }
    &::-ms-fill {
      ${progressValueStyles};
      background-color: #35b3e5;
    }
  `,
}
