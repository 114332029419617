import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledHeartRate = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 48px 0 21px;
    justify-content: ${({ theme }) => theme.arrangements?.verticalAlignment};
    height: calc(100svh - ${HEADER_HEIGHT}px);
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 40px;
  `,
}
