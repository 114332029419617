import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

export const StyledEmailVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    background-color: #f2f3f5;
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding-top: 24px;
  `,
  Title: styled.h2`
    margin-bottom: 8px;
    color: ${Color.GRAY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    strong {
      color: ${Color.RED};
    }
  `,
  Subtitle: styled.h3`
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GRAY_80};
  `,
  InputWrapper: styled.div`
    margin-bottom: 30px;
    width: 100%;
    position: relative;
  `,
  ValidationText: styled.p`
    display: flex;
    position: absolute;
    left: 16px;
    bottom: -20px;
    color: #f83b00;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    svg {
      margin-right: 3px;
    }
  `,
  AutoCompleteWrapper: styled.div`
    width: 100%;
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 65px;
    left: 0;
    border-radius: 12px;
    overflow-y: auto;
    background-color: white;
    border: ${({ theme }) => `1px solid ${theme.colors.primaryBorder}`};
    z-index: 1;
  `,
  AutoCompleteButton: styled.button`
    height: 40px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-decoration: none;
    text-align: left;
    color: ${Color.DEFAULT_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px dotted #bcc9d0;
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Disclaimer: styled.p`
    display: flex;
    margin-bottom: 40px;
    color: ${Color.GRAY_80};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;

    svg {
      margin-right: 8px;
    }
  `,
}
