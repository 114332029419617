import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledPlanProcessingVariant5 as S } from './PlanProcessingVariant5.styles'
import { PAGE_NAME, PROCESSING_TEXTS } from './constants'

export const PlanProcessingVariant5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { counter } = useCounter(true, {
    duration: 8000,
  })

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  useEffect(() => {
    const nextStepId = setTimeout(() => {
      handleContinue('')
    }, 10000)

    return () => {
      clearTimeout(nextStepId)
    }
  }, [handleContinue])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.processing.title6`}</S.Title>
        <S.ProgressBar max={100} value={counter} />
        <S.Block>
          {PROCESSING_TEXTS.map((text, index) => (
            <S.Item key={text} data-item={index}>
              {t(text)}
            </S.Item>
          ))}
        </S.Block>
        <S.Disclaimer>{t`onboarding.processing.trustedByMillion`}</S.Disclaimer>
      </S.Column>
    </S.Wrapper>
  )
}
