import styled from 'styled-components'

import { StyledOption } from 'components/Option/Option.styles'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

type TTitleProps = {
  marginBottom?: number
}
export const StyledDynamicLeftToRightVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    min-height: 100dvh;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 24px;
    flex-grow: 1;
  `,
  Title: styled.h1<TTitleProps>`
    ${baseTitleStyles};
    font-weight: 700;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    color: ${Color.GRAY_100};
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GRAY_80};
    margin-bottom: 24px;
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    flex-grow: 1;
  `,
}
