import styled from 'styled-components'

export const StyledDisclaimer = {
  Text: styled.p`
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #565976;
  `,
}
