import styled from 'styled-components'

import { Button } from 'components/Button'

export const StyledReduceStress = {
  Title: styled.h2`
    margin-bottom: 48px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    text-align: center;
    color: #5f96f7;
  `,
  Button: styled(Button)`
    margin-bottom: 48px;
  `,
}
