import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledProgressRounded = {
  Wrapper: styled.div`
    width: 63px;
    height: 63px;
  `,
  Value: styled.div`
    padding-bottom: 18px;
    color: ${Color.DEFAULT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
}
