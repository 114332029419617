import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import firebase from 'firebase/app'

import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import { selectError, selectIsFetching } from 'root-redux/selects/common'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { eventLogger } from 'services/eventLogger.service'

import { GlobalStatusBar } from 'components/GlobalStatusBar'
import { Modal } from 'components/Modal'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import appleIcon from 'assets/images/continue-with-apple.svg'
import emailIcon from 'assets/images/continue-with-email.svg'

import {
  IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY,
  LoginMethod,
  PlatformOS,
} from 'root-constants/common'

import { StyledAccount as S, StyledAccountButton } from './Account.styles'
import { EmailAccount } from './components/EmailAccount'

const signInFirebase = (provider) =>
  firebase.auth().signInWithRedirect(provider)

export const Account: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)

  const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID

  const [isModalShown, setIsModalShown] = useState(false)
  const [isEmailLoginShown, setIsEmailLoginShown] = useState<boolean>(isAndroid)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  useEffect(() => {
    const isAccountVisited = JSON.parse(
      sessionStorage.getItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY) || 'false',
    )

    if (isAccountVisited) return

    eventLogger.logCreateAccountShown()
    sessionStorage.setItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY, 'true')
  }, [])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  const handleContinueWithApple = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.APPLE })

    await signInFirebase(new firebase.auth.OAuthProvider('apple.com'))
  }, [])

  const handleContinueWithEmail = useCallback(() => {
    setIsEmailLoginShown(true)

    eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
  }, [])

  const handleButtonsClick = useCallback(
    (event) => {
      if (error) {
        event.stopPropagation()
        setIsModalShown(true)
      }
    },
    [error],
  )

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <S.Wrapper>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}
      {isEmailLoginShown ? (
        <EmailAccount
          onBack={() => {
            setIsEmailLoginShown(false)
          }}
        />
      ) : (
        <S.Column>
          <GlobalStatusBar completedStageNumber={3} />
          <S.Title>
            <Trans i18nKey="login.title" />
          </S.Title>
          <S.Text>{t`login.subtitle`}</S.Text>

          <S.Buttons onClickCapture={handleButtonsClick}>
            <StyledAccountButton.Email onClick={handleContinueWithEmail}>
              <img src={emailIcon} width={18} alt="email" />
              <span>{t`login.continueWithEmail`}</span>
            </StyledAccountButton.Email>

            {!isAndroid && (
              <StyledAccountButton.Apple onClick={handleContinueWithApple}>
                <img src={appleIcon} width={24} alt="apple" />
                <span>{t`login.continueWithApple`}</span>
              </StyledAccountButton.Apple>
            )}
          </S.Buttons>

          <S.Footer>
            <S.Description>
              <Trans
                i18nKey="login.disclaimer"
                components={{
                  privacyPolicy: <PrivacyPolicyLink />,
                  termsOfUse: <TermsOfUseLink />,
                }}
              />
            </S.Description>
          </S.Footer>
        </S.Column>
      )}

      <Modal
        onClose={() => {
          setIsModalShown(false)
          dispatch(resetErrorAction())
        }}
        isShown={isModalShown}
      >
        {error}
      </Modal>
    </S.Wrapper>
  )
}
