export const PAGE_NAME = 'Symptoms question'
export const OPTION_VALUES = {
  HEADACHES: 'headaches',
  NOSEBLEEDS: 'nosebleeds',
  FATIGUE: 'fatigue',
  VISION_PROBLEMS: 'vision_problems',
  CHEST_PAIN: 'chest_pain',
  DIFFICULTY_BREATHING: 'difficulty_breathing',
  DIFFICULTY_CONCENTRATING: 'difficulty_concentrating',
  FACIAL_REDNESS: 'facial_redness',
  DIZZINESS: 'dizziness',
  REDUCE_MUSCLE_MASS: 'reduce_muscle_mass',
  LIBIDO: 'decreased_libido',
  NONE: 'none_of_the_above',
}
export const OPTION_VALUES_SYMPTOMS_5 = [
  { value: OPTION_VALUES.CHEST_PAIN, text: 'onboarding.symptoms.chestPain' },
  {
    value: OPTION_VALUES.FACIAL_REDNESS,
    text: 'onboarding.symptoms.facialRedness',
  },
  {
    value: OPTION_VALUES.VISION_PROBLEMS,
    text: 'onboarding.symptoms.visionProblems',
  },
  {
    value: OPTION_VALUES.DIFFICULTY_BREATHING,
    text: 'onboarding.symptoms.difficultyBreathing',
  },
  { value: OPTION_VALUES.DIZZINESS, text: 'onboarding.symptoms.dizziness' },
  { value: OPTION_VALUES.NONE, text: 'onboarding.consumption.none' },
]
export const OPTION_VALUES_SYMPTOMS_6 = [
  { value: OPTION_VALUES.FATIGUE, text: 'onboarding.symptoms.fatigue' },
  {
    value: OPTION_VALUES.REDUCE_MUSCLE_MASS,
    text: 'onboarding.symptoms.muscleMass',
  },
  {
    value: OPTION_VALUES.LIBIDO,
    text: 'onboarding.symptoms.libido',
  },
  {
    value: OPTION_VALUES.NONE,
    text: 'onboarding.consumption.none',
  },
]
