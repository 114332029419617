import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { LOGOS_DATA } from 'pages/social-proof-pressure/constants'

import { Button } from 'components/Button'

import animationConfig from 'assets/animations/users-count-animation.json'

import { StyledSocialProofPressureVariant1 as S } from './SocialProofPressureVariant1.styles'

export const SocialProofPressureVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLSpanElement>(null)
  const { age } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        name: 'usersCountAnimation',
        container: animationContainerRef.current,
        animationData: animationConfig,
        loop: false,
      })
    }

    return () => lottie.destroy('usersCountAnimation')
  }, [])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Text>
          <Trans
            i18nKey="onboarding.socialProofPressure.text"
            values={{ age }}
            components={{ span: <span ref={animationContainerRef} /> }}
          />
        </S.Text>
        <S.StickyWrapper>
          <S.LogosWrapper>
            {LOGOS_DATA.map(({ image, id }) => (
              <img src={image} alt="logo" key={id} />
            ))}
          </S.LogosWrapper>
          <Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </S.StickyWrapper>
      </S.Column>
    </S.Wrapper>
  )
}
