import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrency } from 'modules/payment/redux/selects'

import moneyBox from 'assets/images/pig-money-box.png'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledGettingHealthy as S } from './GettingHealthy.styles'

export const GettingHealthy: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)

  return (
    <S.Wrapper>
      <S.Image src={moneyBox} alt="money-box" loading="lazy" />
      <S.Title>{t`subscriptions.gettingHealthy.title`}</S.Title>
      <S.Text>
        <Trans
          i18nKey="subscriptions.gettingHealthy.text"
          values={{ currencySymbol: CURRENCY_SYMBOLS[currency] }}
        />
      </S.Text>
    </S.Wrapper>
  )
}
