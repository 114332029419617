import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { TAnswer } from 'models/common.model'

import { PageId } from 'root-constants/pages'

export const useIsHealthConditionSkippablePage = (
  pageIdentificatorAnswer: string,
): boolean => {
  const userAnswers = useSelector(selectAnswers)

  return useMemo(() => {
    if (!userAnswers) return false

    return !(userAnswers[PageId.HEALTH_CONDITION_2] as TAnswer)?.includes(
      pageIdentificatorAnswer,
    )
  }, [pageIdentificatorAnswer, userAnswers])
}
