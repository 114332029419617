import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

export const usePasswordInputField = (): TInputFieldHook => {
  const { t } = useTranslation()

  const [password, setPassword] = useState<IInputFieldState>({
    value: '',
    isValid: true,
    validationText: t`login.shortPassword`,
  })

  const validatePassword = useCallback(
    (passwordValue) => {
      const formattedPassword = passwordValue.trim()

      if (formattedPassword.length < 8 && formattedPassword !== '') {
        setPassword((prevState) => ({
          ...prevState,
          value: formattedPassword,
          isValid: false,
          validationText: t`login.shortPassword`,
        }))
        return
      }

      setPassword((prevState) => ({
        ...prevState,
        value: formattedPassword,
        isValid: true,
        validationText: '',
      }))
    },
    [t],
  )

  return [password, setPassword, validatePassword]
}
