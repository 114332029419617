import styled from 'styled-components'

import { AccessVariant1 } from 'modules/subscriptions/components/common/Access'
import { HeartAgeVariant1 } from 'modules/subscriptions/components/common/HeartAge'
import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'

import { HeaderWithButton } from 'components/HeaderWithButton'
import { ReviewsSlider } from 'components/ReviewSlider'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledSubscriptionsVariant10 = {
  Wrapper: styled.div`
    ${basePageStyles};
    background-color: #f2f3f5;
  `,
  HeaderWithButton: styled(HeaderWithButton)`
    button {
      width: 140px;
      background-color: ${Color.RED};
    }
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 72px 0 100px;
    align-items: center;
  `,
  SubheaderWithTimer: styled(SubheaderWithTimer)`
    div {
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
    }

    strong {
      font-size: 17px;
      font-weight: 800;
      line-height: 24px;
    }
  `,
  Title: styled.h3`
    margin-bottom: 8px;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: ${Color.GRAY_100};

    strong {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      color: ${Color.RED};
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    color: ${Color.GRAY_80};
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  HeartAge: styled(HeartAgeVariant1)`
    h3 {
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
    }

    p {
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
    }
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 12px;
  `,
  CancelInfo: styled.p`
    margin-bottom: 32px;
    color: ${Color.GRAY_80};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 24px;
  `,
  SubscriptionDescription: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 32px;
    text-align: center;
    color: ${Color.GRAY_80};
    margin-top: 24px;

    & a {
      color: ${Color.GRAY_80};
      text-decoration: underline;
    }
  `,
  AccessVariant: styled(AccessVariant1)`
    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  ReviewsSlider: styled(ReviewsSlider)`
    h3 {
      font-weight: 700;
    }

    span {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: ${Color.GRAY_100};
    }

    h4 {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: ${Color.GRAY_100};
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: ${Color.GRAY_80};
    }
  `,
  MoneyBackGuarantee: styled(MoneyBackGuarantee)`
    text-align: start;
    color: ${Color.GRAY_80};
  `,
}
