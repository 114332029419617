import styled, { css } from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import {
  BottomStickyBase,
  baseColumnStyles,
  basePageStyles,
} from 'common-styles'
import { BASE_COLUMN_WIDTH, Color, HEADER_HEIGHT } from 'root-constants/common'

const StyledAccountButtonBase = css`
  ${StyledButtonBase};
  padding: 0 28px;
  background-image: none;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 800;

  :not(:last-child) {
    margin-bottom: 12px;
  }

  img {
    margin-right: 10px;
  }
`

export const StyledAccountButton = {
  Email: styled.button`
    ${StyledAccountButtonBase};
    background-image: linear-gradient(180deg, #00d4ce, #00cb97);

    img {
      margin-right: 16px;
    }
  `,
  Apple: styled.button`
    ${StyledAccountButtonBase};
    background-image: linear-gradient(180deg, #446fb7, #153e83);
  `,
}

export const StyledAccount = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    align-items: center;
    padding: 16px 0 40px;
  `,
  Title: styled.h1`
    margin: 24px 0 12px;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    text-align: center;

    strong {
      color: ${({ theme }) => theme.colors.highlightedText};
      font-weight: inherit;
    }
  `,
  Text: styled.p`
    margin-bottom: 48px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
  `,
  Buttons: styled.div`
    width: ${BASE_COLUMN_WIDTH}px;
  `,
  Description: styled.span`
    display: block;
    padding-top: 20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #b2b8cd;

    a {
      color: inherit;
      text-decoration: underline;
      font-weight: 700;
    }
  `,
  Footer: styled.footer`
    ${BottomStickyBase};

    @media (max-height: 640px) {
      position: relative;
      bottom: 0;
    }
  `,
}
