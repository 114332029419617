import styled, { css } from 'styled-components'

import { Option } from 'components/Option'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { BASE_COLUMN_WIDTH, Color, HEADER_HEIGHT } from 'root-constants/common'

type TOptionProps = {
  marginBottom?: number
}

type TTextProps = TOptionProps

const additionalTextStyles = css`
  font-size: 11px;
  text-align: center;
  color: ${Color.LIGHT_TEXT};
`
export const StyledIntro = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 48px 0 40px;
  `,
  Title: styled.h1<TTextProps>`
    ${baseTitleStyles};
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 48}px`};
    font-size: 24px;
    line-height: 32px;
  `,
  Text: styled.p<TTextProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 16}px`};
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    strong {
      font-weight: 900;
      color: #5f96f7;
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    margin-bottom: 32px;
    aspect-ratio: 327/184;
  `,
  AgreementText: styled.span`
    ${additionalTextStyles};
    line-height: 14px;
    margin-bottom: 4px;
  `,
  PoliciesLinksWrapper: styled.div`
    margin-bottom: 16px;
    display: flex;
    gap: 24px;
    justify-content: center;

    a {
      font-size: 12px;
      line-height: 20px;
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
  Description: styled.div`
    ${additionalTextStyles};
    line-height: 16px;
  `,
  Option: styled(Option)<TOptionProps>`
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  `,
  QuestionButton: styled.div`
    width: ${BASE_COLUMN_WIDTH}px;
    height: 58px;
    border: none;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 900;
    line-height: 58px;
    transition: background-image 0.2s ease-out;
    margin: 0 auto;
    outline: none;
    text-decoration: none;
    text-align: center;
    color: ${Color.WHITE};
    background-image: linear-gradient(180deg, #69bcff, #2573ff);
    box-shadow: 0 8px 16px rgba(54, 132, 255, 0.2);

    input:checked + & {
      background-image: linear-gradient(180deg, #bddef9, #a4c3f9);
    }
  `,
}
