import styled from 'styled-components'

import { StickyButton } from 'components/StickyButton'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledDownloadAppPage = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.main`
    ${baseColumnStyles};
    align-items: center;
    padding: 16px 0 40px;
  `,
  Title: styled.h1`
    margin: 24px 0 12px;
    font-weight: 900;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  `,
  AdditionalText: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 48px;
  `,
  List: styled.ul`
    padding: 24px 13px 24px 20px;
    background-color: ${Color.WHITE};
    border-radius: 16px;
    border: ${({ theme }) => `1px solid ${theme.colors.primaryBorder}`};
    box-shadow: ${({ theme }) => theme.shadows.primaryShadow};
  `,
  ListItem: styled.li`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    button {
      padding: 0;
      cursor: pointer;
      border: none;
      outline: none;
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.highlightedText};
      background-color: transparent;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  `,
  ListLabel: styled.span`
    flex-shrink: 0;
    margin-right: 15px;
    font-size: 16px;
    font-weight: 800;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.highlightedText};
  `,
  ListText: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(10, 50, 77, 0.8);
  `,
  StickyButton: styled(StickyButton)`
    @media (orientation: landscape) {
      position: static;
      margin-top: 50px;
    }
  `,
}
