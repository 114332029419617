import styled from 'styled-components'

import { StickyButton } from 'components/StickyButton'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofDoctorVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    align-items: center;
  `,
  Title: styled.h1`
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    font-weight: 900;
    margin-bottom: 16px;
  `,
  Text: styled.p`
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryText};

    strong {
      font-weight: 900;
      font-size: 16px;
      line-height: 22px;
    }
  `,
  Img: styled.img`
    aspect-ratio: 375/272;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  StickyButton: styled(StickyButton)`
    bottom: 40px;
    margin-top: 18px;

    @media (orientation: landscape) {
      position: fixed;
      bottom: 40px;
    }
  `,
}
