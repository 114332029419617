import { createGlobalStyle } from 'styled-components'

import { Color } from 'root-constants/common'

export const BackgroundGlobalStyles = createGlobalStyle`
  :root {
    --base-background-color: ${Color.WHITE};
  }
`

export const FontFamilyGlobalStyles = createGlobalStyle`
  * {
    font-family: 'Open Sans', sans-serif;
  }
`
