import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledSubscriptionsVariant7 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 48px 0 42px;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 8px;
  `,
  Subtitle: styled.h3`
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
}
