import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { BurgerMenu } from 'components/BurgerMenu'
import { ProgressBarV2 } from 'components/ProgressBarV2'

import appNameLogo from 'assets/images/app-name-logo.png'
import logo from 'assets/images/logo.png'

import {
  COHORTS_WITH_BURGER_MENU,
  PAGES_WITH_BURGER_MENU,
} from 'root-constants/common'

import { StyledHeaderV2 as S } from './HeaderV2.styles'

export const HeaderV2: React.FC = () => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { currentPageId, hasHeader, hasProgressbar } = useGetPageInfo()

  const hasBurgerMenu = useMemo(
    () =>
      COHORTS_WITH_BURGER_MENU.includes(cohortToUse) &&
      PAGES_WITH_BURGER_MENU.includes(currentPageId),
    [cohortToUse, currentPageId],
  )

  return !hasHeader ? null : (
    <S.Wrapper>
      <S.Header>
        {hasProgressbar && <S.Logo src={logo} alt="logo" />}
        {!hasProgressbar && <S.AppNameLogo src={appNameLogo} alt="logo" />}
        <ProgressBarV2 />
      </S.Header>
      {hasBurgerMenu && <BurgerMenu />}
    </S.Wrapper>
  )
}
