import React from 'react'

import { TPageProps } from 'models/common.model'

import { OPTIONS_DATA, TITLE_PATHS } from 'pages/gender/constants'
import { KitRadioOptionPage } from 'pages/kit-options-page/'

import { PageId } from 'root-constants/pages'

export const GenderVariant4: React.FC<TPageProps> = (props) => (
  <KitRadioOptionPage
    {...props}
    options={OPTIONS_DATA}
    titlePath={TITLE_PATHS[PageId.KIT_GENDER_1]}
  />
)
