import styled from 'styled-components'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'
import { StripePaymentForm } from 'modules/payment/components/StripePaymentForm'

import backIcon from 'assets/images/back.svg'

import { Color } from 'root-constants/common'

type TPaymentMethodProps = {
  isShown: boolean
}

export const StyledNoPaypalPaymentFlows = {
  Wrapper: styled.div`
    width: 100%;
    padding: 24px 0 40px;
  `,
  Title: styled.h1`
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
  `,
  StripePaymentForm: styled(StripePaymentForm)<TPaymentMethodProps>`
    display: ${({ isShown }) => (isShown ? 'grid' : 'none')};
    margin-bottom: 40px;

    label {
      padding-bottom: 4px;
    }
  `,
  PaymentRequestButton: styled(PaymentRequestButton)<TPaymentMethodProps>`
    display: ${({ isShown }) => (isShown ? 'block' : 'none')};
  `,
  AdditionalInfoContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  PaymentServiceImage: styled.img`
    max-width: 256px;
    margin-bottom: 16px;
  `,
  Guarantee: styled.span`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${Color.MIDDLE_GRAY};
    margin-bottom: 32px;
  `,
  BackButton: styled.button`
    box-sizing: content-box;
    height: 22px;
    display: block;
    padding: 0 0 0 18px;
    margin-bottom: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #5f96f7;
    text-decoration: underline;
    border: none;
    outline: none;
    background-color: transparent;
    background-image: url(${backIcon});
    background-size: 8px 11px;
    background-position: 2px 6px;
    background-repeat: no-repeat;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
}
