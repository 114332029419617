import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import {
  selectCurrency,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'

import {
  CURRENCY_SYMBOLS,
  TRIAL_COHORTS_WITH_TOTAL_AMOUNT,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledTrialAmount as S } from './TrialAmount.styles'

export const TrialAmount: React.FC = () => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const currency = useSelector(selectCurrency)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)

  const hasTotalAmount = useMemo(
    () => TRIAL_COHORTS_WITH_TOTAL_AMOUNT.includes(cohortToUse),
    [cohortToUse],
  )

  return hasTotalAmount ? (
    <S.IntroOfferContainer>
      <S.Title>{t`payment.paymentFlow.getPlan`}</S.Title>
      <S.Description>
        {t('payment.trialTotalAmount.total', {
          currencySymbol: CURRENCY_SYMBOLS[currency],
          trialPrice,
          context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
          minimumFractionDigits: 2,
        })}
      </S.Description>
    </S.IntroOfferContainer>
  ) : (
    <S.Container>
      <span>{t('payment.trialTotalAmount.period', { trialPeriodDays })}</span>{' '}
      <strong>
        {CURRENCY_SYMBOLS[currency]}
        {trialPrice}
      </strong>
    </S.Container>
  )
}
