import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryHeartPlan = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    padding: 48px 0 40px;
  `,
  Title: styled.h1`
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
    margin-bottom: 24px;

    strong {
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: 30px;
      color: ${({ theme }) => theme.colors.highlightedText};
    }
  `,
  BenefitsWrapper: styled.div`
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 8px 20px 0 #dfe3f3;
    margin-bottom: 32px;
    width: 327px;
  `,
  BenefitsContentWrapper: styled.div`
    display: flex;
    gap: 12px;
  `,
  BenefitsContent: styled.div``,
  BenefitsIcon: styled.img`
    width: 42px;
    height: 42px;
  `,
  BenefitsTitle: styled.p`
    color: #898e97;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  BenefitsText: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

    ::first-letter {
      text-transform: uppercase;
    }
  `,
  SummaryWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 32px;
  `,
  SummaryContentWrapper: styled.div`
    display: flex;
    gap: 8px;
  `,
  SummaryIcon: styled.img`
    width: 24px;
    height: 24px;
  `,
  SummaryText: styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primaryText};

    strong {
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.highlightedText};
    }
  `,
  UsersWrapper: styled.div`
    padding: 16px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 8px 20px 0 #dfe3f3;
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 40px;
  `,
  HeartImage: styled.img`
    width: 52px;
    height: 52px;
  `,
  UsersContent: styled.div``,
  UsersPercentage: styled.p`
    color: #01cda1;
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
  `,
  UsersText: styled.p`
    color: ${({ theme }) => theme.colors.primaryText};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
}
