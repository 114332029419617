import styled from 'styled-components'

export const StyledPaymentRequestButton = {
  Wrapper: styled.div`
    width: 100%;
    margin: 0 0 40px;

    & * {
      border-radius: 16px;
    }
  `,
}
