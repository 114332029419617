import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { StickyButton } from 'components/StickyButton'

import { StyledSocialProofRiskVariant1 as S } from './SocialProofRiskVariant1.styles'

export const SocialProofRiskVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()

  const { userGender, isMale } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath: isMale ? alternativePagePath : nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Text>
          <Trans
            i18nKey="onboarding.socialProofRisk.title"
            context={userGender}
          />
        </S.Text>
        <StickyButton onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
