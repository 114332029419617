import styled from 'styled-components'

import { Option } from 'components/Option'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { BASE_COLUMN_WIDTH, Color, HEADER_HEIGHT } from 'root-constants/common'

type TOptionProps = {
  marginBottom?: number
}

export const StyledIntroVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0 46px;
  `,
  Title: styled.h1`
    font-size: 28px;
    font-weight: 900;
    line-height: 34px;
    text-align: center;
    margin-bottom: 16px;

    strong {
      color: #3588ff;
      font-weight: 900;
    }
  `,
  Subtitle: styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    max-width: 280px;
    margin-bottom: 40px;

    strong {
      font-weight: 900;
    }
  `,
  ImageContainer: styled.div`
    margin-bottom: 32px;
    aspect-ratio: 328/166;
    width: 100%;
  `,
  Option: styled(Option)<TOptionProps>`
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  `,
  QuestionButton: styled.div`
    width: ${BASE_COLUMN_WIDTH}px;
    height: 58px;
    border: none;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 900;
    line-height: 58px;
    transition: background-image 0.2s ease-out;
    margin: 0 auto;
    outline: none;
    text-decoration: none;
    text-align: center;
    color: ${Color.WHITE};
    background-image: linear-gradient(180deg, #69bcff, #2573ff);
    box-shadow: 0 8px 16px rgba(54, 132, 255, 0.2);

    input:checked + & {
      background-image: linear-gradient(180deg, #bddef9, #a4c3f9);
    }
  `,
}
