import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

import { Color } from 'root-constants/common'

import { StyledProgressRounded as S } from './ProgressRounded.styles'

type TProps = {
  value: number
  pathColor?: string
  trailColor?: string
}

export const ProgressRounded: React.FC<TProps> = ({
  value,
  pathColor,
  trailColor = Color.WHITE,
}) => (
  <S.Wrapper>
    <svg style={{ height: 0, width: 0, position: 'absolute' }}>
      <defs>
        <linearGradient id="progress">
          <stop offset="0" stopColor="#00d4ce" />
          <stop offset="1" stopColor="#00cb97" />
        </linearGradient>
      </defs>
    </svg>
    <CircularProgressbarWithChildren
      value={value}
      styles={{
        path: {
          stroke: pathColor || `url(#progress)`,
          strokeLinecap: 'round',
          strokeWidth: '8px',
          transition: 'all 0.5s ease-out',
        },
        trail: {
          stroke: trailColor,
          strokeWidth: '8px',
        },
      }}
    >
      <S.Value>{value}%</S.Value>
    </CircularProgressbarWithChildren>
  </S.Wrapper>
)
