import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectEmail } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useUserData } from 'hooks/useUserData'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'

import { Events, eventLogger } from 'services/eventLogger.service'

import { PaymentMethod, PaymentSystem } from 'root-constants/common'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from '../redux/selects'

export const usePrimerAnalytics = (): {
  logPaypalPaymentStarted: (eventName?: Events) => void
} => {
  const screenName = useSelector(selectScreenName)
  const email = useSelector(selectEmail)
  const price = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currency = useSelector(selectCurrency)
  const stripePriceId = useSelector(selectSubscriptionLookupKey)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const stripeAccountId = useSelector(selectStripeAccountId)

  const { goal } = useUserData()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const productId = createIntroOfferProductId({
    priceId: stripePriceId,
    trialPriceId,
    trialPeriodQuantity: trialPeriodDays,
  })

  const logPaypalPaymentStarted = useCallback(
    (eventName = Events.PURCHASE_STARTED) => {
      eventLogger.logPurchaseStarted({
        eventName,
        email,
        screenName,
        productId: trialPriceId ? productId : stripePriceId,
        priceDetails: {
          price,
          trial: !!trialPrice,
          currency,
        },
        paymentMethod: PaymentMethod.PAYPAL,
        paymentSystem: PaymentSystem.PRIMER,
        goal,
        isPersonalDataAllowed,
        stripeAccountName,
        stripeAccountId,
      })
    },
    [
      currency,
      email,
      goal,
      isPersonalDataAllowed,
      price,
      productId,
      screenName,
      stripeAccountId,
      stripeAccountName,
      stripePriceId,
      trialPrice,
      trialPriceId,
    ],
  )

  return {
    logPaypalPaymentStarted,
  }
}
