export const OPTION_VALUES = {
  REGULARLY: 'regularly',
  SOCIALLY: 'socially',
  NO: 'no',
  NOT_ANSWER: 'not_answer',
}
export const OPTION_VALUES_SMOKING_3 = [
  { value: OPTION_VALUES.REGULARLY, text: 'onboarding.smoking.regularly' },
  { value: OPTION_VALUES.SOCIALLY, text: 'onboarding.smoking.socially' },
  { value: OPTION_VALUES.NO, text: 'actions.no' },
  { value: OPTION_VALUES.NOT_ANSWER, text: 'onboarding.smoking.notAnswer' },
]
export const TITLE_PATH = 'onboarding.smoking.title2'
