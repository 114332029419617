import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import img from 'assets/images/social-proof-doctor.jpg'

import { StyledSocialProofDoctorVariant2 as S } from './SocialProofDoctorVariant2.styles'

export const SocialProofDoctorVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { age, userGender } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Img src={img} alt="doctor" />
      <S.Column>
        <S.Title>{t`onboarding.didYouKnow.title`}</S.Title>
        <S.Text>
          <Trans
            i18nKey="onboarding.socialProofDoctor.socialProof"
            context={userGender}
            values={{ age }}
          />
        </S.Text>
        <S.Text>
          <Trans i18nKey="onboarding.socialProofDoctor.cardiMate2" />
        </S.Text>
        <S.StickyButton onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </S.StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
