import { PageId } from 'root-constants/pages'

export const ONBOARDING_START = 0
export const ONBOARDING_END = 3

export const PROFILE_BREAKPOINTS = [
  PageId.GENDER_2,
  PageId.KNOW_HEART_RATE_2,
  PageId.HEALTHY_LIFESTYLE,
  PageId.EMAIL_1,
]

export const PROFILE_TEXTS = [
  'commonComponents.onboardingProgressbar.demographicProfile',
  'commonComponents.onboardingProgressbar.healthSummary',
  'commonComponents.onboardingProgressbar.lifestyleRiskSummary',
  'commonComponents.onboardingProgressbar.personalPlan',
]
