import styled from 'styled-components'

export const StyledSpinner = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: var(--full-height, 100vh);
    background-color: var(--base-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
