import styled from 'styled-components'

export const StyledCustomerRatingVariant2 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
    max-width: ${({ theme }) => theme.common.columnWidth};
    width: 100%;
  `,
  RatingWrapper: styled.div`
    padding: 32px 0;
    display: flex;
    justify-content: center;
    gap: 8px;
    border-top: 1px solid #c6c6c8;
    border-bottom: 1px solid #c6c6c8;
    max-width: ${({ theme }) => theme.common.columnWidth};
    width: 100%;
  `,
  RatingData: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    max-width: 167px;
    width: 100%;

    img {
      max-width: 61px;
      max-height: 40px;
    }
  `,
  RatingDataText: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.primaryText};
    text-align: center;
  `,
  HeartRateText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.primaryText};
    text-align: center;

    strong {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: ${({ theme }) => theme.colors.secondaryText};
    }
  `,
  HeartRateWrapper: styled.div`
    display: flex;
    border-bottom: 1px solid #c6c6c8;
    margin-top: 32px;
    padding-bottom: 32px;
    max-width: ${({ theme }) => theme.common.columnWidth};
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  LeftLeaf: styled.img`
    max-height: 110px;
    max-width: 80px;
    margin-right: -15px;
  `,
  RightLeaf: styled.img`
    max-height: 110px;
    max-width: 80px;
    margin-left: -15px;
  `,
  HeartRateDataWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 177px;
    width: 100%;

    img {
      width: 95px;
      margin-top: 8px;
    }

    img:last-of-type {
      max-width: 24px;
      max-height: 24px;
      margin-top: 14px;
    }
  `,
  Title: styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.secondaryText};
  `,
}
