import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

export const StyledNameVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    background-color: #f2f3f5;
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 24px;
  `,
  Title: styled.h1`
    margin-bottom: 24px;
    color: ${Color.GRAY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  NameForm: styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  SkipButton: styled.button`
    padding: 0;
    margin-top: 24px;
    border: none;
    background: transparent;
    color: ${Color.GRAY_80};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
  `,
}
