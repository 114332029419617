import styled, { keyframes } from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const fadein = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const StyledPlanProcessingVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 0;
  `,
  ProgressContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
  `,
  ProgressText: styled.p`
    padding-left: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    animation: ${fadein} 0.5s ease-in-out;
  `,
  Title: styled.h2`
    margin-bottom: 16px;
    text-align: center;
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    color: ${({ theme }) => theme.colors.secondaryText};
  `,
  Subtitle: styled.h3`
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  `,
  AppHighlightsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 103px));
    grid-gap: 9px;
    margin-bottom: 24px;
  `,
  Highlight: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px 12px;
    border: ${({ theme }) => `1px solid ${theme.colors.primaryBorder}`};
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: ${({ theme }) => theme.shadows.primaryShadow};
  `,
  HighlightImage: styled.img`
    max-height: 28px;
    margin-bottom: 10px;
  `,
  HighlightTitle: styled.h3`
    margin-bottom: 4px;
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
  `,
  HighlightText: styled.p`
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  `,
  HighlightRating: styled.img`
    height: 15px;
    margin-top: -2px;
  `,
}
