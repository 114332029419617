import styled from 'styled-components'

export const StyledAccessVariant1 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
  `,
  Title: styled.h3`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    color: ${({ theme }) => theme.colors.secondaryText};
  `,
  Column: styled.div`
    display: flex;
    gap: 23px;
    align-items: center;

    &:nth-of-type(2) {
      flex-direction: row-reverse;
    }
  `,
  ImageContainer: styled.div`
    max-width: 150px;
    width: 100%;
    aspect-ratio: 150/312;

    img {
      max-width: 150px;
      max-height: 312px;
    }
  `,
  DataWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Icon: styled.img`
    max-width: 154px;
    height: 80px;
  `,
  Subtitle: styled.h4`
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.secondaryText};
  `,
  Text: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.primaryText};
  `,
}
