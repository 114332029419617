import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useInputValidation } from 'hooks/useInputValidation'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Popover } from 'components/Popover'

import { goTo } from 'browser-history'
import { Language, STATIC_HEART_AGE } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledAge as S } from './Age.styles'
import { AGE_MAX, AGE_MIN, PAGE_NAME } from './constants'

export const AgeVariant1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    value: age,
    isValid: isAgeValid,
    handleInputChange: handleChange,
  } = useInputValidation()

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(
        setMultipleAnswerAction({
          answers: {
            [CustomPageId.AGE]: age,
            heart_age: +age + STATIC_HEART_AGE,
          },
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.age.question3', { lng: Language.EN }),
        answers: age,
        pageName: PAGE_NAME,
      })

      goTo(nextPagePath)
    },
    [age, dispatch, nextPagePath, t],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <form onSubmit={handleSubmit}>
          <S.Title>{t`onboarding.age.title`}</S.Title>
          <S.InputWrapper>
            <Popover
              isShown={!isAgeValid}
              position="center"
              text={t('commonComponents.inputValidationText', {
                minValue: AGE_MIN,
                maxValue: AGE_MAX,
              })}
            >
              <Input
                type="number"
                placeholder={t`onboarding.age.placeholder`}
                min={AGE_MIN}
                max={AGE_MAX}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.validity.valid)
                }}
                isValid={isAgeValid}
                inputMode="numeric"
              />
            </Popover>
          </S.InputWrapper>
          <Button type="submit" disabled={!(isAgeValid && age)}>
            {t`actions.continue`}
          </Button>
        </form>
      </S.Column>
    </S.Wrapper>
  )
}
