import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledSymptomsVariant1 = {
  GraphWrapper: styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px 16px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #dfe3f3;
    max-width: 327px;
    width: 100%;
    margin-bottom: 50px;
  `,
  BenefitsData: styled.div`
    display: flex;
    gap: 12px;
  `,
  BenefitsIcon: styled.img`
    width: 36px;
    height: 36px;
  `,
  BenefitsTextWrapper: styled.div`
    margin-bottom: 20px;
  `,
  BenefitsTitle: styled.p`
    color: #898e97;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  BenefitsText: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

    ::first-letter {
      text-transform: uppercase;
    }
  `,
  ImageContainer: styled.div`
    max-width: 295px;
    width: 100%;
    aspect-ratio: 295/176;
  `,
}
