import React, { useRef } from 'react'

import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'

import colonIcon from 'assets/images/sprite/colon.svg'

import { StyledTimer as S } from './Timer.styles'

const FIVE_MINUTES_IN_SECONDS = 300

export const Timer: React.FC = () => {
  const timerElementRef = useRef<HTMLDivElement>(null)

  const { minutes, seconds } = useTimerForTarget(
    timerElementRef,
    FIVE_MINUTES_IN_SECONDS,
  )

  return (
    <S.Wrapper ref={timerElementRef}>
      {minutes && seconds && (
        <S.TimerContainer>
          <S.Timer>{minutes}</S.Timer>
          <S.SvgImage svg={colonIcon} />
          <S.Timer>{seconds}</S.Timer>
        </S.TimerContainer>
      )}
    </S.Wrapper>
  )
}
