export const OPTION_VALUES = {
  DAILY: 'daily',
  SEVERAL: 'several_times',
  OCCASIONALLY: 'occasionally',
  NEVER: 'never',
}
export const OPTION_VALUES_ALCOHOL_3 = [
  { value: OPTION_VALUES.DAILY, text: 'onboarding.alcohol.daily' },
  { value: OPTION_VALUES.SEVERAL, text: 'onboarding.alcohol.several' },
  {
    value: OPTION_VALUES.OCCASIONALLY,
    text: 'onboarding.alcohol.occasionally',
  },
  { value: OPTION_VALUES.NEVER, text: 'onboarding.alcohol.never' },
]
export const TITLE_PATH = 'onboarding.alcohol.title2'
