import { TAnswer, TAnswers } from 'models/common.model'
import {
  IAppConfig,
  IFirebaseConfig,
  IPaymentConfig,
} from 'models/config.model'
import { IAppState } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'
import { IStep, IVariant } from 'models/variant.model'

import { ScreenName } from 'services/eventLogger.service'

import { Language } from 'root-constants/common'

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching
export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList
export const selectError = (state: IAppState): any => state.common.error
export const selectCurrentVariantCohort = (state: IAppState): string =>
  state.common.currentVariant?.cohort || ''
export const selectCurrentVariantCohortToUse = (state: IAppState): string =>
  state.common.currentVariant?.parentCohort ||
  state.common.currentVariant?.cohort ||
  ''
export const selectCurrentVariantSteps = (state: IAppState): IStep[] =>
  state.common.currentVariant?.steps || []
export const selectFacebookPixelId = (state: IAppState): string =>
  state.common.currentVariant?.facebookPixelId || ''
export const selectCurrentVariant = (state: IAppState): IVariant | null =>
  state.common.currentVariant || null
export const selectGrowthBookExperiments = (
  state: IAppState,
): Record<string, Record<string, string>> | null =>
  state.common.currentVariant?.growthBookExperiments || null
export const selectHasUpsellOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasUpsellOffer || false
export const selectHasCancelOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasCancelOffer || false

export const selectSubscriptionList = (state: IAppState): ISubscription[] =>
  state.common.subscriptions || []

export const selectConfig = (state: IAppState): IAppConfig | null =>
  state.common.appConfig
export const selectAmplitudeApiKey = (state: IAppState): string | null =>
  state.common.appConfig?.amplitudeApiKey || null
export const selectGiaApiKeys = (
  state: IAppState,
): Record<string, string> | null => state.common.appConfig?.giaApiKeys || null
export const selectFirebaseConfig = (
  state: IAppState,
): IFirebaseConfig | null => state.common.appConfig?.firebaseConfig || null
export const selectGoogleAnalyticsId = (state: IAppState): string | null =>
  state.common.appConfig?.googleAnalyticsId || null
export const selectCookieConsentCountries = (state: IAppState): string[] =>
  state.common.appConfig?.cookiesConsentCountries || []

export const selectPaymentConfig = (state: IAppState): IPaymentConfig | null =>
  state.common.paymentConfig
export const selectStripeAccountId = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountId || ''
export const selectStripeAccountName = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountName || ''
export const selectStripePublicKey = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.publicKey || ''
export const selectPayPalClientId = (state: IAppState): string =>
  state.common.paymentConfig?.paypal.clientId || ''
export const selectAnswers = (state: IAppState): Partial<TAnswers> | null =>
  state.common.answers
export const selectUserHeartAge = (state: IAppState): TAnswer =>
  state.common.answers?.heart_age || ''
export const selectLanguage = (state: IAppState): Language =>
  state.common.language
export const selectScreenName = (state: IAppState): ScreenName =>
  state.common.screenName
export const selectTaxAmount = (state: IAppState): number =>
  state.common.taxAmount
export const selectIsInContextShown = (state: IAppState): boolean =>
  state.common.isInContextShown
