import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { CurrentEnvironment } from 'root-constants/common'

export type TCurrentEnvKeys = keyof typeof CurrentEnvironment
export type TCurrentEnvValues = typeof CurrentEnvironment[TCurrentEnvKeys]

export const getEnvironment = () => {
  const currentEnv = getCurrentEnv()

  return {
    isProdEnvironment: currentEnv === CurrentEnvironment.PROD,
    isDevEnvironment: currentEnv === CurrentEnvironment.DEV,
    isStageEnvironment: currentEnv === CurrentEnvironment.STAGE,
  }
}
