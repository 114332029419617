import styled, { css } from 'styled-components'

import heartRateImg from 'assets/images/easy-to-use-heart-rate.jpg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const feedbackStyles = css`
  padding: 168px 12px 12px;
  margin-bottom: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  overflow: hidden;
`

export const StyledEasyToUse = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 48px 0 52px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 40px;
  `,
  Feedback: styled.div`
    ${feedbackStyles};
    background-image: linear-gradient(
        180deg,
        rgba(10, 50, 77, 0) 45%,
        #130a02 58%
      ),
      url(${heartRateImg});
  `,
  Text: styled.p`
    font-style: italic;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    background-color: ${Color.WHITE};
    border-radius: 8px;
    padding: 20px 16px;
  `,
  Name: styled.span`
    color: #b2b8cd;
  `,
}
