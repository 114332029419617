import styled from 'styled-components'

import { Button } from 'components/Button'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryTestResults = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    padding: 24px 0 40px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 24px;
  `,
  Subtitle: styled.h3`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    margin-bottom: 24px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 327/216;
    margin-bottom: 32px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #dfe3f3;
    padding: 20px 16px;
  `,
  Note: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.primaryText};
    margin-bottom: 40px;
  `,
  Button: styled(Button)`
    text-transform: capitalize;
  `,
}
