import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  getOneLinkAction,
  sendUserAnswersAction,
} from 'root-redux/actions/user'
import { selectError } from 'root-redux/selects/common'
import { selectOneLink } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import { GlobalStatusBar } from 'components/GlobalStatusBar'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import { IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY } from 'root-constants/common'

import { StyledDownloadAppPage as S } from './DownloadApp.styles'

export const DownloadApp: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const oneLink = useSelector(selectOneLink)
  const error = useSelector(selectError)

  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  useLayoutEffect(() => {
    dispatch(getOneLinkAction(() => setIsErrorModalShown(true)))
  }, [dispatch])

  useEffect(() => {
    sessionStorage.removeItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY)
    eventLogger.logGettingAppShown()
  }, [])

  useEffect(() => {
    dispatch(sendUserAnswersAction(true))
  }, [dispatch])

  const handleContinue = useCallback(() => {
    window.location.href = oneLink
  }, [oneLink])

  const handleClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      eventLogger.logDownloadClicked(handleContinue)
    },
    [handleContinue],
  )

  return (
    <>
      {!oneLink && <Spinner />}
      {oneLink && (
        <S.Wrapper>
          <S.Column>
            <GlobalStatusBar completedStageNumber={4} />
            <S.Title>{t`download.title`}</S.Title>
            <S.AdditionalText>{t`download.subtitle`}</S.AdditionalText>
            <S.List>
              <S.ListItem>
                <S.ListLabel>1.</S.ListLabel>
                <S.ListText>
                  {t`download.clickButton`}
                  <button type="button" onClick={handleClick}>
                    {t`download.buttonText`}
                  </button>
                  {t`download.cardiApp`}
                </S.ListText>
              </S.ListItem>
              <S.ListItem>
                <S.ListLabel>2.</S.ListLabel>
                <S.ListText>{t`download.openApp`}</S.ListText>
              </S.ListItem>
            </S.List>
            <S.StickyButton onClick={handleClick}>
              {t`download.downloadApp`}
            </S.StickyButton>
          </S.Column>
        </S.Wrapper>
      )}
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
