import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'

import ratingStars from 'assets/images/rating-stars.svg'
import reviewAvatar1 from 'assets/images/review-avatar-1.jpg'
import reviewAvatar2 from 'assets/images/review-avatar-2.jpg'

import { goTo } from 'browser-history'

import { StyledSummaryPremiumClub as S } from './SummaryPremiumClub.styles'

const PAGE_NAME = 'Cardi Mate Premium Club'

export const SummaryPremiumClub: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()

  const { currentSubscriptionPageId } = useGetPageInfo()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: PAGE_NAME,
    })
  }, [])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({
      pageName: PAGE_NAME,
    })

    goTo({ pathname: currentSubscriptionPageId, search })
  }, [currentSubscriptionPageId, search])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`summary.premiumClub.title`}</S.Title>
        <S.Subtitle>{t`summary.premiumClub.subtitle`}</S.Subtitle>

        <S.CardContainer>
          <S.Card>
            <S.Avatar src={reviewAvatar1} alt="first-author" />
            <S.Rating src={ratingStars} alt="rating" />
            <S.Author>{t`commonComponents.reviewsSlider.firstUser.name`}</S.Author>
            <S.CardTitle>{t`commonComponents.reviewsSlider.firstUser.title`}</S.CardTitle>
            <S.Review>{t`commonComponents.reviewsSlider.firstUser.review`}</S.Review>
          </S.Card>

          <S.Card>
            <S.Avatar src={reviewAvatar2} alt="second-author" />
            <S.Rating src={ratingStars} alt="rating" />
            <S.Author>{t`commonComponents.reviewsSlider.secondUser.name`}</S.Author>
            <S.CardTitle>{t`commonComponents.reviewsSlider.secondUser.title`}</S.CardTitle>
            <S.Review>{t`commonComponents.reviewsSlider.secondUser.review`}</S.Review>
          </S.Card>
        </S.CardContainer>

        <Button type="button" onClick={handleContinue}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
