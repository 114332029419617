import styled from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

export const StyledHeartCompanionVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    background-color: #f2f3f5;
    min-height: 100dvh;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 24px;
  `,
  Title: styled.h1`
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: ${Color.GRAY_100};
  `,
  SwiperContainer: styled.div`
    max-width: 375px;
    margin-bottom: 30px;
    flex-grow: 1;

    .swiper-wrapper {
      padding-bottom: 55px;
    }

    .swiper-slide {
      max-width: 343px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 6px;
      background-color: #b2b8cd;
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background-color: ${Color.DANGER};
    }
  `,
  AvatarContainer: styled.div`
    aspect-ratio: 163/163;
    width: 100%;
    max-width: 163px;
    padding: 16px;
    border: 1px solid #e1ebff;
    background-color: #f6f8ff;
    border-radius: 50%;
    transform: translateY(26px);
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
  `,
  ReviewContainer: styled.div`
    padding: 34px 12px 24px;
    border-radius: 16px;
    box-shadow: 0 8px 20px 0 #dfe3f3;
    background-color: ${Color.WHITE};
  `,
  Author: styled.p`
    margin-bottom: 8px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  Review: styled.p`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    display: flex;
    justify-content: center;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #f0f2fa 24%
    );
  `,
}
