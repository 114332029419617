import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { COMMON_OPTION_VALUES, Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledHormonalMedsVariant1 as S } from './HormonalMedsVariant1.styles'

export const HormonalMedsVariant1: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.hormonalMeds.title', { lng: Language.EN }),
    nextPagePath: `${PageId.HEART_COMPANION_2}${search}`,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.hormonalMeds.title`}</S.Title>
        <S.Subtitle>{t`onboarding.hormonalMeds.subtitle`}</S.Subtitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.YES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.yes`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.NO}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.no`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.PREFER_NOT_TO_ANSWER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.notToAnswer`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
