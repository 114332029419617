import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'

import { CURRENCY_SYMBOLS, PERIOD_NAMES } from 'root-constants/common'

import { StyledTotalAmountVAT as S } from './TotalAmountVAT.styles'

export const TotalAmountVAT: React.FC = () => {
  const { t } = useTranslation()
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const currency = useSelector(selectCurrency)

  return (
    <S.Container>
      <Trans
        i18nKey="payment.totalAmountVAT"
        values={{
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price: currentPrice.toFixed(2),
          periodQuantity,
          minimumFractionDigits: 2,
        }}
        components={{
          periodName: (
            <S.PeriodName>{t(PERIOD_NAMES[periodName])}</S.PeriodName>
          ),
        }}
      />
    </S.Container>
  )
}
