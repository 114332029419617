import styled, { css } from 'styled-components'

import { Option } from 'components/Option'

import {
  ModalContainerBase,
  ModalWrapperBase,
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TTextProps = {
  color: string
}

const commonButtonStyles = css`
  text-align: center;
  padding: 12px 20px;
  border-radius: 16px;
  color: ${Color.WHITE};
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  max-width: 135px;
  width: 100%;
  border: none;
  cursor: pointer;
`
export const StyledCardiacRiskVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 40px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 40px;
  `,
  AnimationWrapper: styled.div`
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
  `,
  AnimationTitle: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
  `,
  VariantsWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
  `,
  Text: styled.p<TTextProps>`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${({ color }) => color};
  `,
  Modal: styled.div`
    ${ModalWrapperBase};
    background-color: rgba(107, 114, 128, 0.75);
  `,
  Container: styled.div`
    ${ModalContainerBase};
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    padding: 32px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    width: 327px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 64px;
      height: 64px;
    }
  `,
  ContainerTitle: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 16px 0 20px;
    text-align: center;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  Option: styled(Option)`
    flex: 50%;
  `,
  YesButton: styled.div`
    ${commonButtonStyles};
    background: #7ba52a;
  `,
  NoButton: styled.div`
    ${commonButtonStyles};
    background: #b2b8cd;
  `,
}
