import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useVatInfo } from 'hooks/useVatInfo'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
} from 'modules/payment/redux/selects'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledDescription as S } from './Description.styles'

export const Description: React.FC = () => {
  const { t } = useTranslation()
  const price = useSelector(selectSubscriptionFullPrice)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const currency = useSelector(selectCurrency)

  const hasIncludedVAT = useVatInfo()

  return trialPriceId ? (
    <ul>
      <S.DescriptionItem>
        {t`payment.description.followInstructions`}
      </S.DescriptionItem>
      <S.DescriptionItem>
        {t('payment.description.trialPeriod', {
          periodQuantity,
          price: price.toFixed(2),
          currencySymbol: CURRENCY_SYMBOLS[currency],
          count: periodQuantity,
          minimumFractionDigits: 2,
        })}
      </S.DescriptionItem>
    </ul>
  ) : (
    <ul>
      <S.DescriptionItem>
        {t`payment.description.followInstructions`}
      </S.DescriptionItem>
      <S.DescriptionItem>{t`payment.description.autoRenew`}</S.DescriptionItem>
      <S.DescriptionItem>
        {t('payment.description.period', {
          periodQuantity,
          price: price.toFixed(2),
          currencySymbol: CURRENCY_SYMBOLS[currency],
          count: periodQuantity,
          minimumFractionDigits: 2,
          ...(hasIncludedVAT && { context: 'vat' }),
        })}
      </S.DescriptionItem>
    </ul>
  )
}
