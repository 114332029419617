import reviewAvatar1 from 'assets/images/review-avatar-1.jpg'
import reviewAvatar2 from 'assets/images/review-avatar-2.jpg'
import reviewAvatar3 from 'assets/images/review-avatar-3.jpg'
import reviewAvatar4 from 'assets/images/review-avatar-4.jpg'
import reviewAvatar5 from 'assets/images/review-avatar-5.webp'
import reviewAvatar6 from 'assets/images/review-avatar-6.webp'
import reviewAvatar7 from 'assets/images/review-avatar-7.webp'
import reviewAvatar8 from 'assets/images/review-avatar-8.webp'

export const CUSTOMER_REVIEWS = [
  {
    avatar: reviewAvatar1,
    author: 'commonComponents.reviewsSlider.firstUser.name',
    title: 'commonComponents.reviewsSlider.firstUser.title',
    review: 'commonComponents.reviewsSlider.firstUser.review',
  },
  {
    avatar: reviewAvatar2,
    author: 'commonComponents.reviewsSlider.secondUser.name',
    title: 'commonComponents.reviewsSlider.secondUser.title',
    review: 'commonComponents.reviewsSlider.secondUser.review',
  },
  {
    avatar: reviewAvatar3,
    author: 'commonComponents.reviewsSlider.thirdUser.name',
    title: 'commonComponents.reviewsSlider.thirdUser.title',
    review: 'commonComponents.reviewsSlider.thirdUser.review',
  },
  {
    avatar: reviewAvatar4,
    author: 'commonComponents.reviewsSlider.fourthUser.name',
    title: 'commonComponents.reviewsSlider.fourthUser.title',
    review: 'commonComponents.reviewsSlider.fourthUser.review',
  },
]

export const ALTERNATIVE_CUSTOMER_REVIEWS = [
  {
    avatar: reviewAvatar5,
    author: 'commonComponents.reviewsSlider.fifthUser.name',
    title: 'commonComponents.reviewsSlider.fifthUser.title',
    review: 'commonComponents.reviewsSlider.fifthUser.review',
  },
  {
    avatar: reviewAvatar6,
    author: 'commonComponents.reviewsSlider.sixthUser.name',
    title: 'commonComponents.reviewsSlider.sixthUser.title',
    review: 'commonComponents.reviewsSlider.sixthUser.review',
  },
  {
    avatar: reviewAvatar7,
    author: 'commonComponents.reviewsSlider.seventhUser.name',
    title: 'commonComponents.reviewsSlider.seventhUser.title',
    review: 'commonComponents.reviewsSlider.seventhUser.review',
  },
  {
    avatar: reviewAvatar8,
    author: 'commonComponents.reviewsSlider.eighthUser.name',
    title: 'commonComponents.reviewsSlider.eighthUser.title',
    review: 'commonComponents.reviewsSlider.eighthUser.review',
  },
]
