import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { TPageProps } from 'models/common.model'

import { KitRadioOptionPage } from 'pages/kit-options-page'

import { PageId } from 'root-constants/pages'

import { OPTIONS_DATA } from './constants'

export const HormonalMedsVariant2: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()

  const { search } = useLocation()

  return (
    <KitRadioOptionPage
      {...props}
      options={OPTIONS_DATA}
      titlePath={t`onboarding.hormonalMeds.title`}
      nextPagePath={`${PageId.KIT_HEART_COMPANION_1}${search}`}
    />
  )
}
