import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StickyButton } from 'components/StickyButton'

import { StyledSocialProofConsumptionVariant1 as S } from './SocialProofConsumptionVariant1.styles'

export const SocialProofConsumptionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Text>{t`onboarding.socialProofConsumption.title`}</S.Text>
        <StickyButton onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
