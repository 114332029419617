import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledNameVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 24px;
    align-items: center;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 32px;
  `,
  NameForm: styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  NameInput: styled.input`
    width: 100%;
    padding: 18px 16px;
    border-radius: 12px;
    color: ${Color.DEFAULT_TEXT};
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    border: ${({ theme }) => `1px solid ${theme.colors.inputBorder}`};
    background-color: ${Color.WHITE};
    outline: none;
    transition: border-color 0.2s ease-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 24px;

    :focus {
      border-color: ${({ theme }) => theme.colors.activeInputBorder};
    }

    ::placeholder {
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: 24px;
      opacity: 0.7;
      color: ${Color.LIGHT_TEXT};
    }
  `,
  SkipButton: styled.button`
    border: none;
    background: transparent;
    color: ${Color.LIGHT_TEXT};
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    position: relative;
    padding: 0;
    margin-top: 24px;

    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0;
      left: 0;
      bottom: 3px;
      border-bottom: 1px solid ${Color.LIGHT_TEXT};
    }
  `,
}
