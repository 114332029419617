import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { GOAL_TO_MARKUP } from 'pages/social-proof-health-risks/constants'

import { StickyButton } from 'components/StickyButton'

import { StyledSocialProofHealthRisksVariant1 as S } from './SocialProofHealthRisksVariant1.styles'

export const SocialProofHealthRisksVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { goal } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Text>
          <Trans i18nKey={t(GOAL_TO_MARKUP[goal])} />
        </S.Text>
        <StickyButton onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
