import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PAGE_NAME } from 'pages/main-goal/constants'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Goal, Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledMainGoalVariant5 as S } from './MainGoalVariant5.styles'

export const MainGoalVariant5: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId: CustomPageId.MAIN_GOAL,
    pageName: PAGE_NAME,
    question: t('onboarding.mainGoal.title2', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.MAIN_GOAL,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.mainGoal.title2`}</S.Title>
        <S.Text>{t`onboarding.additionalGoals.chooseAll`}</S.Text>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={Goal.HEART_ATTACK_PREVENTION}
            checked={answers.includes(Goal.HEART_ATTACK_PREVENTION)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.heartAttackPrevention`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Goal.BLOOD_PRESSURE}
            checked={answers.includes(Goal.BLOOD_PRESSURE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.controlBloodPressure`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Goal.HEART_RATE}
            checked={answers.includes(Goal.HEART_RATE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.controlHeartRate`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Goal.LOWER_CHOLESTEROL}
            checked={answers.includes(Goal.LOWER_CHOLESTEROL)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.lowerCholesterol`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
