import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import lottie from 'lottie-web/build/player/lottie_light'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'

import animationConfig from 'assets/animations/heart-score.json'

import { goTo } from 'browser-history'

import { StyledSummaryHeartScoreGraph as S } from './SummaryHeartScoreGraph.styles'

const PAGE_NAME = 'Heart score analysis - Graph'
const MONTHS_NUMBER_FOR_ANIMATION = 5

export const SummaryHeartScoreGraph: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null)

  const monthSequence = useMemo(
    () =>
      Array.from({ length: MONTHS_NUMBER_FOR_ANIMATION }, (_, i) =>
        dayjs().add(i, 'month').format('MMM'),
      ),
    [],
  )

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: PAGE_NAME,
    })
  }, [])

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        name: 'heartScoreGraph',
        container: animationContainerRef.current,
        animationData: animationConfig,
        loop: false,
      })
    }

    return () => lottie.destroy('heartScoreGraph')
  }, [])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({
      pageName: PAGE_NAME,
    })
    goTo(nextPagePath)
  }, [nextPagePath])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`summary.summaryHeartScore.title`}</S.Title>
        <S.GraphContainer>
          <div ref={animationContainerRef} />
          <S.GoalDate>
            <Trans
              i18nKey="summary.summaryHeartScore.byDate"
              values={{
                date: dayjs().add(3, 'month').format('MMM, YYYY'),
              }}
            />
          </S.GoalDate>
          <S.DatesContainer>
            {monthSequence.map((item, index) => (
              <S.Month key={item} data-order={index}>
                {item}
              </S.Month>
            ))}
          </S.DatesContainer>
        </S.GraphContainer>

        <S.Subtitle>{t`summary.summaryHeartScore.noWorries`}</S.Subtitle>
        <S.Disclaimer>{t`summary.summaryHeartScore.firstImprovements`}</S.Disclaimer>
        <Button type="button" onClick={handleContinue}>
          {t`actions.continue`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
