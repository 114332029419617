export const OPTION_VALUES = {
  PARENTS_SIBLINGS: 'parents_siblings',
  GRANDPARENTS: 'grandparents',
  NO_HISTORY: 'no_history',
  UNSURE: 'unsure',
}
export const OPTION_VALUES_HEART_DISEASE_4 = [
  {
    value: OPTION_VALUES.PARENTS_SIBLINGS,
    text: 'onboarding.heartDisease.parentsSiblings',
  },
  {
    value: OPTION_VALUES.GRANDPARENTS,
    text: 'onboarding.heartDisease.grandParents',
  },
  {
    value: OPTION_VALUES.NO_HISTORY,
    text: 'onboarding.heartDisease.noHistory',
  },
  { value: OPTION_VALUES.UNSURE, text: 'onboarding.heartDisease.unsure' },
]
