import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import {
  Color,
  MediaBreakpoint,
  SENIOR_FLOW_HEADER_HEIGHT,
} from 'root-constants/common'

export const StyledDynamicSocialProof = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    min-height: 100dvh;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;
  `,
  Title: styled.h1`
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: ${Color.GRAY_100};
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GRAY_80};
    margin-bottom: 24px;
    flex-grow: 1;
  `,
  Img: styled.img`
    aspect-ratio: 375/247;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
}
