import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledSwitch = {
  Root: styled.label`
    display: flex;
    cursor: pointer;
    width: 60px;
    height: 32px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
  `,
  Input: styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + span {
      transform: translateX(calc(90% + 1px));
    }

    &:checked ~ div {
      background-color: ${Color.DANGER};
      border: 1px solid ${Color.DANGER};
    }
  `,
  Track: styled.div`
    width: 100%;
    transition: 0.2s;
    border-radius: 16px;
    background: #dfe4e7;
  `,
  Button: styled.span`
    position: absolute;
    top: 3px;
    left: 3px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
  `,
}
