import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { EffectCreative, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'

import bloodPressureScreen from 'assets/images/app-screen-blood-pressure.png'
import graphScreen from 'assets/images/app-screen-graph.png'
import pulseScreen from 'assets/images/app-screen-pulse.png'

import { goTo } from 'browser-history'

import { StyledSummaryAppScreens as S } from './SummaryAppScreens.styles'

const PAGE_NAME = 'All-in-One heart health app'

export const SummaryAppScreens: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: PAGE_NAME,
    })
  }, [])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({
      pageName: PAGE_NAME,
    })
    goTo(nextPagePath)
  }, [nextPagePath])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Column>
          <S.Title>{t`summary.appScreens.title`}</S.Title>
          <S.Subtitle>{t`summary.appScreens.subtitle`}</S.Subtitle>
        </S.Column>

        <S.SwiperContainer>
          <Carousel
            loop
            centeredSlides
            slidesPerView="auto"
            modules={[Pagination, EffectCreative]}
            pagination={{ clickable: true }}
            spaceBetween={12}
            effect="creative"
            creativeEffect={{
              prev: {
                scale: 0.86,
                translate: ['-100%', 0, 0],
              },
              next: {
                scale: 0.86,
                translate: ['100%', 0, 0],
              },
            }}
          >
            <SwiperSlide>
              <img src={pulseScreen} alt="pulse" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={bloodPressureScreen} alt="blood pressure" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={graphScreen} alt="graph" />
            </SwiperSlide>
          </Carousel>
        </S.SwiperContainer>

        <Button type="button" onClick={handleContinue}>
          {t`actions.continue`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
