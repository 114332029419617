import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledGettingHealthy = {
  Wrapper: styled.div`
    position: relative;
    margin: 50px 0 48px;
    padding: 32px 24px 24px;
    background: ${Color.WHITE};
    border-radius: 16px;
  `,
  Title: styled.h3`
    padding-bottom: 4px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  `,
  Image: styled.img`
    max-width: 77px;
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
  `,
}
