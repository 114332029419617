import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'

import { StyledCholesterolLevelVariant1 as S } from './CholesterolLevelVariant1.styles'

const PAGE_NAME = 'Cholesterol level question'
const OPTION_VALUES = {
  LESS_ONE_POINT_FIVE: '<1,5',
  BETWEEN_ONE_POINT_SIX_AND_THREE: '1,6 - 3',
  BETWEEN_FOUR_AND_FIVE: '4 - 5',
  MORE_THAN_SIX: '>6',
  DONT_KNOW: 'dont_know',
}
export const CholesterolLevelVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.cholesterolLevel.question', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )
  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.cholesterolLevel.question`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.LESS_ONE_POINT_FIVE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.LESS_ONE_POINT_FIVE}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.BETWEEN_ONE_POINT_SIX_AND_THREE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {OPTION_VALUES.BETWEEN_ONE_POINT_SIX_AND_THREE}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.BETWEEN_FOUR_AND_FIVE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {OPTION_VALUES.BETWEEN_FOUR_AND_FIVE}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.MORE_THAN_SIX}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.MORE_THAN_SIX}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DONT_KNOW}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.doNotKnow`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
