import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

export const StyledPopover = {
  Wrapper: styled.div`
    position: relative;
  `,
  Popover: styled.div`
    position: absolute;
    bottom: calc(100% + 14px);
    width: 245px;
    padding: 13px 16px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.popoverBackground};
    box-shadow: 0 8px 12px rgba(152, 159, 187, 0.1);
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: ${({ theme }) => theme.colors.popoverText};
    z-index: 1;

    &[data-position='center'] {
      left: 50%;
      transform: translateX(-50%);
    }

    &[data-position='left'] {
      left: 0;
    }

    &[data-position='right'] {
      right: 0;
    }
  `,
  SvgImage: styled(SvgImage)`
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    fill: ${({ theme }) => theme.colors.popoverBackground};
  `,
}
