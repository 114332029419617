import styled, { css } from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TTextProps = {
  color: string
}

const progressValueStyles = css`
  border-radius: 5px;
  transition: all 0.2s ease-out;
`

export const StyledAdaptingProfileVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 40px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 40px;
  `,
  ProgressBarsList: styled.ul`
    min-width: 311px;
  `,
  ProgressContainer: styled.li`
    width: 100%;
    margin-bottom: 44px;
  `,
  ProgressHeader: styled.header`
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ProgressTitle: styled.span<TTextProps>`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${({ color }) => color};
  `,

  ProgressPercent: styled.span<TTextProps>`
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: ${({ color }) => color};
  `,
  ProgressBar: styled.progress`
    width: 100%;
    height: 8px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 5px;
    overflow: hidden;
    background: linear-gradient(0deg, #dce1f2 0%, #dce1f2 100%), #fff;

    &::-webkit-progress-bar {
      background: linear-gradient(0deg, #dce1f2 0%, #dce1f2 100%), #fff;
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
      background-color: ${Color.DANGER};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
      background-color: ${Color.DANGER};
    }
    &::-ms-fill {
      ${progressValueStyles};
      background-color: ${Color.DANGER};
    }
  `,
}
