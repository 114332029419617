import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { COMMON_OPTION_VALUES, Language } from 'root-constants/common'

import { StyledHeartHealthConcern2 as S } from './HeartHealthConcernVariant2.styles'

const PAGE_NAME = 'Heart Health Concern'

export const HeartHealthConcernVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.heartHealthConcern.title2', {
          lng: Language.EN,
        }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      goTo(
        answer === COMMON_OPTION_VALUES.NO ? alternativePagePath : nextPagePath,
      )
    },
    [dispatch, pageId, alternativePagePath, nextPagePath, t],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.heartHealthConcern.title2`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.YES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.yes`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.NO}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.no`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.MAYBE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.maybe`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
