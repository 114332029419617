import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import {
  STATEMENT_PAGES_DATA,
  STATEMENT_PAGES_NAMES,
  STATEMENT_PAGES_TITLES,
} from 'pages/statement/constants'

import { Option, OptionType } from 'components/Option'

import { Language } from 'root-constants/common'

import { StyledStatementVariant1 as S } from './StatementVariant1.styles'

export const StatementVariant1: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: STATEMENT_PAGES_NAMES[pageId],
    question: t(STATEMENT_PAGES_TITLES[pageId], { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t(STATEMENT_PAGES_TITLES[pageId])}</S.Title>
        <S.OptionsContainer>
          {STATEMENT_PAGES_DATA.map(({ image, description, value }) => (
            <S.OptionsWrapper key={value}>
              <Option
                {...optionProps}
                value={value}
                disabled={isAnswersDisabled}
              >
                <S.QuestionButton>
                  <img src={image} alt={value} />
                </S.QuestionButton>
              </Option>
              <S.Description>{t(description)}</S.Description>
            </S.OptionsWrapper>
          ))}
        </S.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
