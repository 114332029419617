import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionOldPriceBeforeCustomDiscount,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'

import { CURRENCY_SYMBOLS, PERIOD_NAMES } from 'root-constants/common'

import { StyledPaymentSummary as S } from './PaymentSummary.styles'

export const PaymentSummary: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const selectedPrice = useSelector(selectSubscriptionFullPrice)
  const oldPrice = useSelector(selectSubscriptionOldPriceBeforeCustomDiscount)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)

  return (
    <S.Wrapper>
      <S.Container>
        <S.PriceContainer>
          <span>
            {t('payment.paymentSummary.planDescription', {
              periodQuantity,
              periodName: t(PERIOD_NAMES[periodName]),
            })}
          </span>
          <span>
            {CURRENCY_SYMBOLS[currency]}
            {oldPrice.fullPrice}
          </span>
        </S.PriceContainer>
        <S.PriceContainer>
          <span>
            {t('payment.paymentSummary.discount', {
              discountAmount: oldPrice.percentOfDiscount,
            })}
          </span>
          <em>
            -{CURRENCY_SYMBOLS[currency]}
            {oldPrice.amountOfDiscount}
          </em>
        </S.PriceContainer>
        <S.PriceContainer>
          <strong>{t`payment.paymentSummary.total`}</strong>
          <strong>
            {CURRENCY_SYMBOLS[currency]}
            {selectedPrice}
          </strong>
        </S.PriceContainer>
      </S.Container>
    </S.Wrapper>
  )
}
