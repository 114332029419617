import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants/common'

export const basePageStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const baseColumnStyles = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: ${({ theme }) => theme.common.columnAlignItems};
  justify-content: flex-start;
  width: ${({ theme }) => theme.common.columnWidth};
`

export const baseTitleStyles = css`
  font-weight: 900;
  font-size: ${({ theme }) => theme.common.titleFontSize};
  line-height: ${({ theme }) => theme.common.titleLineHeight};
  text-align: ${({ theme }) => theme.common.textAlign};

  strong {
    font-weight: 900;
    color: #5f96f7;
  }
`
export const baseSubtitleStyles = css`
  font-weight: 500;
  font-size: ${({ theme }) => theme.common.subtitleFontSize};
  line-height: ${({ theme }) => theme.common.subtitleLineHeight};
  text-align: ${({ theme }) => theme.common.textAlign};
`

export const baseInputStyles = css`
  display: block;
  width: 100%;
  height: 58px;
  padding: 18px;
  color: ${Color.DEFAULT_TEXT};
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  border-radius: 12px;
  border: ${({ theme }) => `1px solid ${theme.colors.inputBorder}`};
  background-color: ${Color.WHITE};
  outline: none;
  transition: border-color 0.2s ease-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  :focus {
    border-color: ${({ theme }) => theme.colors.activeInputBorder};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #cbccd7;
  }
  :-ms-input-placeholder {
    color: #cbccd7;
  }
`

export const ModalWrapperBase = css`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(49, 59, 100, 0.5);
  overflow-y: scroll;
  padding: 40px 0;
`

export const ModalContainerBase = css`
  width: 288px;
  padding: 32px 24px;
  margin: auto;
  background-color: white;
  border-radius: 16px;
`

export const BottomStickyBase = css`
  position: fixed;
  z-index: 1;
  bottom: 40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  &[data-is-virtual-keypad-active='true'] {
    position: relative;
    bottom: 0;
  }

  &[data-is-auto-complete-shown='true'] {
    z-index: -1;
  }

  @media (orientation: landscape) {
    position: relative;
    bottom: 0;
  }
`

export const StyledPaymentStatus = {
  Wrapper: styled.div`
    ${ModalWrapperBase};
  `,
  Container: styled.div`
    ${ModalContainerBase};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Image: styled.img`
    margin-bottom: 12px;
    aspect-ratio: 72/72;
    max-width: 72px;
    width: 100%;
  `,
  Title: styled.h2`
    margin-bottom: 12px;
    font-weight: 900;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    max-width: 240px;
    margin-bottom: 36px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
  `,
  Button: styled(Button)`
    width: 100%;
    border-radius: 12px;
  `,
  Disclaimer: styled.p`
    margin: 0 -8px;
    padding-top: 16px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
  `,
}
