import styled from 'styled-components'

type TOptionsContainerProps = {
  marginBottom?: number
  paddingBottom?: number
}

export const StyledOption = {
  OptionsContainer: styled.div<TOptionsContainerProps>`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 0}px`};
    padding-bottom: ${({ paddingBottom }) => paddingBottom || 15}px;
    z-index: 1;
  `,
  OptionContainer: styled.label`
    cursor: pointer;
    display: block;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    margin-bottom: 12px;

    & > input {
      display: none;
    }
  `,
}
