import styled from 'styled-components'

import { basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledUsersAroundTheWorldVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 40px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/320;
  `,
  Title: styled.h2`
    font-size: 28px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 37px;
    max-width: 328px;

    strong {
      font-size: 28px;
      font-weight: 900;
      line-height: 32px;
      color: ${Color.DANGER};
    }
  `,
  LogosContainer: styled.div`
    max-width: 328px;
    width: 100%;
    aspect-ratio: 327/28;
    margin-bottom: 40px;
  `,
}
