import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useInputValidation } from 'hooks/useInputValidation'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Popover } from 'components/Popover'

import { goTo } from 'browser-history'
import {
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  Language,
} from 'root-constants/common'

import { StyledHeight as S } from './Height.styles'
import {
  CM_MAX,
  CM_MIN,
  FEET_MAX,
  FEET_MIN,
  INCH_MAX,
  INCH_MIN,
  PAGE_NAME,
} from './constants'

export const HeightVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inchRef = useRef<HTMLInputElement>(null)
  const countryCode = useSelector(selectUserCountryCode)

  const [isImperialUsed, setIsImperialUsed] = useState(
    COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(countryCode),
  )

  const {
    value: feet,
    isValid: isFeetValid,
    handleInputChange: handleFeetChange,
  } = useInputValidation()
  const {
    value: inch,
    isValid: isInchValid,
    handleInputChange: handleInchChange,
  } = useInputValidation()
  const {
    value: cm,
    isValid: isCmValid,
    handleInputChange: handleCmChange,
  } = useInputValidation()

  const answers = useMemo(
    () => (isImperialUsed ? `${feet} feet ${inch} inch` : `${cm} centimeters`),
    [cm, feet, inch, isImperialUsed],
  )

  const isSubmitButtonDisabled = useMemo(
    () =>
      !(isImperialUsed
        ? isFeetValid && isInchValid && feet && inch
        : isCmValid && cm),
    [isCmValid, cm, isFeetValid, feet, isInchValid, inch, isImperialUsed],
  )

  useEffect(() => {
    if (isFeetValid && feet) {
      inchRef.current?.focus()
    }
  }, [isFeetValid, feet])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      dispatch(
        setAnswersAction({
          pageId,
          answers: isImperialUsed ? [feet, inch] : cm,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.height.title', { lng: Language.EN }),
        pageName: PAGE_NAME,
        answers,
      })
      goTo(nextPagePath)
    },
    [
      answers,
      cm,
      dispatch,
      feet,
      inch,
      isImperialUsed,
      nextPagePath,
      pageId,
      t,
    ],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.height.title`}</S.Title>
        <S.ButtonsWrapper>
          <S.SystemButton
            onClick={() => setIsImperialUsed(true)}
            data-is-active={isImperialUsed}
          >
            {t`commonComponents.imperial`}
          </S.SystemButton>
          <S.SystemButton
            onClick={() => setIsImperialUsed(false)}
            data-is-active={!isImperialUsed}
          >
            {t`commonComponents.metric`}
          </S.SystemButton>
        </S.ButtonsWrapper>

        <form onSubmit={handleSubmit}>
          {isImperialUsed && (
            <S.ImperialSystemWrapper>
              <S.Popover
                isShown={!isFeetValid}
                position="left"
                text={t('commonComponents.inputValidationText', {
                  minValue: FEET_MIN,
                  maxValue: FEET_MAX,
                })}
              >
                <Input
                  type="number"
                  placeholder={t`commonComponents.feet`}
                  min={FEET_MIN}
                  max={FEET_MAX}
                  onChange={(e) => {
                    handleFeetChange(e.target.value, e.target.validity.valid)
                  }}
                  isValid={isFeetValid}
                  inputMode="numeric"
                />
              </S.Popover>
              <S.Popover
                isShown={!isInchValid}
                position="right"
                text={t('commonComponents.inputValidationText', {
                  minValue: INCH_MIN,
                  maxValue: INCH_MAX,
                })}
              >
                <Input
                  type="number"
                  placeholder={t`commonComponents.inch`}
                  min={INCH_MIN}
                  max={INCH_MAX}
                  onChange={(e) => {
                    handleInchChange(e.target.value, e.target.validity.valid)
                  }}
                  isValid={isInchValid}
                  inputRef={inchRef}
                  inputMode="numeric"
                />
              </S.Popover>
            </S.ImperialSystemWrapper>
          )}

          {!isImperialUsed && (
            <S.MetricSystemWrapper>
              <Popover
                isShown={!isCmValid}
                position="center"
                text={t('commonComponents.inputValidationText', {
                  minValue: CM_MIN,
                  maxValue: CM_MAX,
                })}
              >
                <Input
                  type="number"
                  placeholder={t`commonComponents.centimeters`}
                  min={CM_MIN}
                  max={CM_MAX}
                  onChange={(e) => {
                    handleCmChange(e.target.value, e.target.validity.valid)
                  }}
                  isValid={isCmValid}
                  inputMode="numeric"
                />
              </Popover>
            </S.MetricSystemWrapper>
          )}

          <Button type="submit" disabled={isSubmitButtonDisabled}>
            {t`actions.continue`}
          </Button>
        </form>
      </S.Column>
    </S.Wrapper>
  )
}
