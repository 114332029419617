import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import {
  BLOOD_PRESSURE_OPTION_VALUES,
  Cohort,
  Language,
} from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledBloodPressureVariant5 as S } from './BloodPressureVariant5.styles'
import { PAGE_NAME } from './constants'

export const BloodPressureVariant5: React.FC<TPageProps> = ({
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohortToUse)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId: CustomPageId.BLOOD_PRESSURE,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.bloodPressure.averagePressure', {
          lng: Language.EN,
        }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      if (answer === BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_80) {
        goTo(alternativePagePath)
        return
      }
      goTo(nextPagePath)
    },
    [t, dispatch, nextPagePath, alternativePagePath],
  )

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.BLOOD_PRESSURE,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const isSeventeenCohort = useMemo(
    () => cohort === Cohort.CARDIMATE_17,
    [cohort],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {isSeventeenCohort
            ? t`onboarding.bloodPressure.doYouKnow`
            : t`onboarding.bloodPressure.averagePressure`}
        </S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.bloodPressure.below`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_80}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.bloodPressure.above`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.DONT_KNOW}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.doNotKnow`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
