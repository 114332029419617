import React from 'react'

import { StyledStickyButtonContainer as S } from './StickyButtonContainer.styles'

type TProps = {
  children: React.ReactNode
}

export const StickyButtonContainer: React.FC<TProps> = ({
  children,
  ...props
}) => <S.Wrapper {...props}>{children}</S.Wrapper>
