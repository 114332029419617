import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohortToUse,
  selectTaxAmount,
} from 'root-redux/selects/common'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { CURRENCY_SYMBOLS, Cohort, PERIOD_NAMES } from 'root-constants/common'

import { StyledPlanItemVariant1 as S } from './PlanItemVariant1.styles'

export const VatPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    mainPrices: { periodQuantity, periodName, monthly, weekly },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const taxAmount = useSelector(selectTaxAmount)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const hasAlternativeVariant = useFeatureIsOn('web_432_vat_test')

  const isThirteenCohort = useMemo(
    () => cohortToUse === Cohort.CARDIMATE_13,
    [cohortToUse],
  )

  const pricePerMonthWithoutTaxes = useMemo(
    () => getPriceWithoutTaxes({ price: monthly, taxAmount }),
    [monthly, taxAmount],
  )

  const pricePerWeekWithoutTaxes = useMemo(
    () => getPriceWithoutTaxes({ price: weekly, taxAmount }),
    [weekly, taxAmount],
  )

  const pricePerPeriod = useMemo(() => {
    if (hasAlternativeVariant) {
      return monthly
    }
    if (isThirteenCohort) {
      return pricePerWeekWithoutTaxes
    }
    return pricePerMonthWithoutTaxes
  }, [
    hasAlternativeVariant,
    isThirteenCohort,
    monthly,
    pricePerMonthWithoutTaxes,
    pricePerWeekWithoutTaxes,
  ])

  return (
    <S.PlanItem
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.specialOffer`}
    >
      <S.Container>
        <S.PlanTitle>
          {t('subscriptions.planPeriodV2', {
            periodName: t(PERIOD_NAMES[periodName]),
            periodQuantity,
          })}
        </S.PlanTitle>
        <S.PaymentInformation>
          {t('subscriptions.billingCycle', {
            periodName: t(PERIOD_NAMES[periodName]),
            count: periodQuantity,
          })}
        </S.PaymentInformation>
      </S.Container>
      <S.Container>
        <S.PlanPrice>
          {CURRENCY_SYMBOLS[currency]}
          {pricePerPeriod}
        </S.PlanPrice>
        <S.PricePeriod>
          {isThirteenCohort
            ? t`subscriptions.perWeek`
            : t`subscriptions.perMonth`}
        </S.PricePeriod>
      </S.Container>
    </S.PlanItem>
  )
}
