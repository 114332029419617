import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/mulish/300-italic.css'
import '@fontsource/mulish/400.css'
import '@fontsource/mulish/500.css'
import '@fontsource/mulish/600-italic.css'
import '@fontsource/mulish/700.css'
import '@fontsource/mulish/800.css'
import '@fontsource/mulish/900.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/raleway/700.css'

import { store } from 'root-redux/store/store'

import { App } from './App'
import './i18n'
import './styles/rootStyles.css'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // Facebook Pixel
    fbq: any
    // AppsFlyer WebSDK
    AF: any
    // ZenDesk plugin
    zE: any
    zESettings: any
    // Google analytics
    gtag: any
    MSStream: any
    // hotjar
    hj: any
  }
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <App />
  </Provider>,
)
