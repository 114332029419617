import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGiaFlowTrackerInitialization } from 'hooks/useGiaFlowTrackerInitialization'
import { growthbook } from 'hooks/useGrowthBook'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useStartSession } from 'hooks/useStartSession'
import { useUtmTags } from 'hooks/useUtmTags'

import { CookieConsent } from 'components/CookieConsent'
import { Header } from 'components/Header'
import { Theme } from 'components/Theme'

import { Cohort } from 'root-constants/common'

import { RouteList } from '../../RouteList'
import { ProgressBarV3 } from '../ProgressBarV3'
import {
  BackgroundGlobalStyles,
  FontFamilyGlobalStyles,
} from './LayoutProvider.styles'
import { HEADERS, WHITE_BACKGROUND_COHORTS } from './constants'

export const LayoutProvider: React.FC = () => {
  const { pathname, search } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const userCountryCode = useSelector(selectUserCountryCode)

  useUtmTags()
  useGAPageTracker()
  useGiaFlowTrackerInitialization()
  useAmplitudeInitialization()
  useStartSession()
  useScrollToTop()

  const { isEUUser, cookieConsent } = useCookieConsentAnswer()

  const isCookieConsentAvailable = useMemo(
    () => !cookieConsent && isEUUser,
    [cookieConsent, isEUUser],
  )

  const hasWhiteBackground = useMemo(
    () => WHITE_BACKGROUND_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  const hasOpenSansFontFamily = useMemo(
    () =>
      cohortToUse === Cohort.CARDIMATE_20 ||
      cohortToUse === Cohort.CARDIMATE_21,
    [cohortToUse],
  )

  const hasAlternativeProgressBar = useMemo(
    () =>
      cohortToUse === Cohort.CARDIMATE_15 ||
      cohortToUse === Cohort.CARDIMATE_17 ||
      cohortToUse === Cohort.CARDIMATE_19,
    [cohortToUse],
  )

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      cohort,
    })
  }, [cohort, pathname, search, userCountryCode])

  const CurrentHeader = HEADERS[cohortToUse] || Header

  return (
    <Theme>
      {hasWhiteBackground && <BackgroundGlobalStyles />}
      {hasOpenSansFontFamily && <FontFamilyGlobalStyles />}

      <CurrentHeader />
      {hasAlternativeProgressBar && <ProgressBarV3 />}
      {isCookieConsentAvailable && <CookieConsent />}
      <RouteList />
    </Theme>
  )
}
