import { Cohort } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const FEMALE_OPTIONAL_PAGES = [
  PageId.HORMONAL_MEDS_1,
  PageId.MENOPAUSE_1,
]

export const MALE_OPTIONAL_PAGES = [PageId.SYMPTOMS_4, PageId.TESTOSTERONE_1]

export const ONBOARDING_START = 0
export const ONBOARDING_END = 3

export const PROFILE_BREAKPOINTS = {
  [Cohort.CARDIMATE_15]: [
    PageId.MAIN_GOAL_4,
    PageId.PHYSICAL_ACTIVITY_3,
    PageId.SOCIALIZE_FREQUENCY,
    PageId.HEART_COMPANION_1,
  ],
  [Cohort.CARDIMATE_17]: [
    PageId.MAIN_GOAL_4,
    PageId.DAILY_WATER_1,
    PageId.SYMPTOMS_3,
    PageId.HEART_COMPANION_2,
  ],
  [Cohort.CARDIMATE_19]: [
    PageId.MAIN_GOAL_5,
    PageId.DAILY_WATER_1,
    PageId.SYMPTOMS_3,
    PageId.HEART_COMPANION_2,
  ],
}

export const PROFILE_TEXTS = {
  [Cohort.CARDIMATE_15]: [
    'commonComponents.onboardingProgressbarV3.healthProfile',
    'commonComponents.onboardingProgressbarV3.lifestyle',
    'commonComponents.onboardingProgressbarV3.socialPattern',
  ],
  [Cohort.CARDIMATE_17]: [
    'commonComponents.onboardingProgressbarV3.healthProfile',
    'commonComponents.onboardingProgressbarV3.habitsAndRoutine',
    'commonComponents.onboardingProgressbarV3.personalDetails',
  ],
  [Cohort.CARDIMATE_19]: [
    'commonComponents.onboardingProgressbarV3.healthProfile',
    'commonComponents.onboardingProgressbarV3.habitsAndRoutine',
    'commonComponents.onboardingProgressbarV3.personalDetails',
  ],
}
