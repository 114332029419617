/* eslint-disable max-lines */
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  savePlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectScreenName,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { TAnswer } from 'models/common.model'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { DEFAULT_CARDHOLDER_NAME } from 'modules/payment/constants'
import { getRedirectUrl } from 'modules/payment/helpers/getRedirectUrl'

import {
  CENTS_IN_DOLLAR,
  PaymentMethod,
  PaymentSystem,
  PlanAddition,
} from 'root-constants/common'

import { checkIsRetryAllowed } from '../../helpers/checkIsRetryAllowed'
import { getPurchaseFailedEventParams } from '../../helpers/getPurchaseFailedEventParams'
import { getPurchaseStartedEventParams } from '../../helpers/getPurchaseStartedEventParams'
import { getPurchaseSuccessEventParams } from '../../helpers/getPurchaseSuccessEventParams'
import { logFailedPayment } from '../../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../../helpers/logSuccessfulPayment'
import {
  selectCreatedSubscriptionId,
  selectCurrency,
  selectPaymentClientSecret,
  selectPlanId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from '../selects'

const MODULE_NAME = 'PAYMENT'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_SUBSCRIPTION_ID`
export const SET_IS_PAYMENT_FLOWS_SHOWN = `${MODULE_NAME}/SET_IS_PAYMENT_FLOWS_SHOWN`
export const SET_PAYMENT_METHOD = `${MODULE_NAME}/SET_PAYMENT_METHOD`
export const SET_PLAN_ADDITIONS = `${MODULE_NAME}/SET_PLAN_ADDITIONS`
export const SET_PAYMENT_REQUEST_BUTTON_TYPE = `${MODULE_NAME}/SET_PAYMENT_REQUEST_BUTTON_TYPE`

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<never> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

const setSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_SUBSCRIPTION_ID,
  payload,
})

export const setIsPaymentFlowsShownAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_PAYMENT_FLOWS_SHOWN,
  payload,
})

export const setSelectedSubscriptionAction = (
  payload: ISubscription,
): IAction<ISubscription> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const setPaymentMethodAction = (
  payload: PaymentMethod,
): IAction<PaymentMethod> => ({
  type: SET_PAYMENT_METHOD,
  payload,
})

export const setPlanAdditionsAction = (
  payload: PlanAddition[],
): IAction<PlanAddition[]> => ({
  type: SET_PLAN_ADDITIONS,
  payload,
})

export const setPaymentRequestButtonTypeAction = (
  payload: PaymentMethod,
): IAction<PaymentMethod> => ({
  type: SET_PAYMENT_REQUEST_BUTTON_TYPE,
  payload,
})
export const purchaseAction =
  ({
    stripe,
    paymentPageId,
    card,
    name,
    createPaymentResFromDigitalWallet,
    goal,
  }: {
    stripe: Stripe
    paymentPageId: string
    card?: StripeCardNumberElement
    name?: string
    goal: TAnswer
    createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const planId = selectPlanId(state)
    const priceId = selectSubscriptionLookupKey(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const uuid = selectUUID(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const stripeAccountName = selectStripeAccountName(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)
    const cohort = selectCurrentVariantCohort(state)
    const screenName = selectScreenName(state)
    const selectedSubscription = selectSubscription(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    const commonPurchaseStartedParams = getPurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    dispatch(startFetching(PURCHASE))

    const paymentMethod =
      createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      paymentSystem: PaymentSystem.STRIPE,
      goal,
    })

    try {
      const createPaymentResponse =
        card && !createPaymentResFromDigitalWallet
          ? await stripe.createPaymentMethod({
              type: 'card',
              card,
              billing_details: { name: name || DEFAULT_CARDHOLDER_NAME },
            })
          : (createPaymentResFromDigitalWallet as PaymentMethodResult)

      if (
        !createPaymentResponse?.paymentMethod &&
        createPaymentResponse?.error
      ) {
        const {
          error: { type, message },
        } = createPaymentResponse

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          error: createPaymentResponse.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal,
        })
        dispatch(setErrorAction(message || type))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const isFreeTrial = !!trialPeriodDays && !trialPriceId

      const createSubscriptionResponse = await paymentApi.createSubscription({
        uuid,
        planId,
        trialPeriodDays,
        cohort,
      })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        // It's a hack for fixing behavior after changing response
        // from WS BE after getting payment_method
        if (
          createSubscriptionResponse.data?.error?.startsWith(
            'creating new customer',
          )
        ) {
          let error = { type: createSubscriptionResponse.data?.error }

          try {
            error = JSON.parse(
              createSubscriptionResponse.data.error.split(
                'creating new customer: ',
              )[1],
            )
          } finally {
            logFailedPayment({
              ...commonPurchaseFailedParams,
              price: currentPrice,
              paymentResponse: error,
              paymentMethod,
              paymentSystem: PaymentSystem.STRIPE,
              goal,
            })
            dispatch(
              setErrorAction({ type: createSubscriptionResponse.data?.error }),
            )
            dispatch(stopFetching(PURCHASE))
            createPaymentResFromDigitalWallet?.complete('fail')
          }
          return
        }

        if (isFreeTrial) {
          logFailedPayment({
            ...commonPurchaseFailedParams,
            price: currentPrice,
            paymentResponse: { type: createSubscriptionResponse.data?.error },
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
            goal,
          })
          dispatch(
            setErrorAction({ type: createSubscriptionResponse.data?.error }),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        switch (createSubscriptionResponse.status) {
          case 409:
            dispatch(setErrorAction(i18n.t('payment.haveSubscription')))
            break
          default:
            dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: currentPrice,
          paymentResponse: { type: createSubscriptionResponse.data?.error },
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal,
        })
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      if (isFreeTrial) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          productId: createSubscriptionResponse.data.purchase.product_id,
          trialPeriodDays:
            createSubscriptionResponse.data.purchase.trial_period_days,
          subscriptionId:
            createSubscriptionResponse.data.purchase.subscription_id,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal,
          stripeAccountName,
        })

        dispatch(
          sendUserConfigAction({
            payment_currency: currency,
            payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
            subscription_price: currentPrice,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: priceId,
            trial_price: trialPrice,
            trial_period: trialPeriodDays,
            is_cancel_offer_purchased: screenName === ScreenName.CANCEL_OFFER,
          }),
        )
        dispatch(savePlanAdditionsAction())

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          createSubscriptionResponse.data.purchase.client_secret,
        ),
      )
      dispatch(
        setSubscriptionIdAction(
          createSubscriptionResponse.data.purchase.subscription_id,
        ),
      )

      const cardPaymentResponseFirst = await stripe.confirmCardPayment(
        createSubscriptionResponse.data.purchase.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        !cardPaymentResponseFirst?.paymentIntent &&
        cardPaymentResponseFirst?.error
      ) {
        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: currentPrice,
          paymentResponse: cardPaymentResponseFirst.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseFirst = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFirst.error as StripeError,
        })

        if (!retryResponseFirst.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseSecond = await stripe.confirmCardPayment(
          retryResponseFirst.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseSecond?.paymentIntent &&
          !cardPaymentResponseSecond?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPrice:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseFirst.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseFirst.data.subscription.subscription_id,
            discountApplied:
              retryResponseFirst.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
            goal,
            stripeAccountName,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              discount_applied:
                !!retryResponseFirst.data.subscription.discount_applied,
              subscription_price: currentPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
              is_cancel_offer_purchased: screenName === ScreenName.CANCEL_OFFER,
            }),
          )
          dispatch(savePlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: currentPrice,
          paymentResponse: cardPaymentResponseSecond.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseSecond = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseSecond.error as StripeError,
        })

        if (!retryResponseSecond.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseThird = await stripe.confirmCardPayment(
          retryResponseSecond.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseThird?.paymentIntent &&
          !cardPaymentResponseThird?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPrice:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseSecond.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseSecond.data.subscription.subscription_id,
            discountApplied:
              retryResponseSecond.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
            goal,
            stripeAccountName,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              discount_applied:
                !!retryResponseSecond.data.subscription.discount_applied,
              subscription_price: currentPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
              is_cancel_offer_purchased: screenName === ScreenName.CANCEL_OFFER,
            }),
          )
          dispatch(savePlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          paymentResponse: cardPaymentResponseThird.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          price: currentPrice,
          goal,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseThird = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseThird.error as StripeError,
        })

        if (!retryResponseThird.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseFourth = await stripe.confirmCardPayment(
          retryResponseThird.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseFourth?.paymentIntent &&
          !cardPaymentResponseFourth?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPrice:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseThird.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseThird.data.subscription.subscription_id,
            discountApplied:
              retryResponseThird.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
            goal,
            stripeAccountName,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              discount_applied:
                !!retryResponseThird.data.subscription.discount_applied,
              subscription_price: currentPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
              is_cancel_offer_purchased: screenName === ScreenName.CANCEL_OFFER,
            }),
          )
          dispatch(savePlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: currentPrice,
          paymentResponse: cardPaymentResponseFourth.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal,
        })

        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseFourth.error),
          ),
        )

        // Needed for reset invoice on BE
        await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFourth.error as StripeError,
        })

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const { paymentIntent } = cardPaymentResponseFirst

      if (paymentIntent.status === 'requires_payment_method') {
        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          error: {
            type: 'requires_payment_method',
          },
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal,
        })
        dispatch(setErrorAction('Payment failed'))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const threeDSesureURL = paymentIntent.next_action?.redirect_to_url?.url

      if (paymentIntent.status === 'requires_action' && threeDSesureURL) {
        dispatch(set3DSecureIframeUrlAction(threeDSesureURL))
        dispatch(stopFetching(PURCHASE))
        return
      }

      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        productId: createSubscriptionResponse.data.purchase.product_id,
        trialPeriodDays:
          createSubscriptionResponse.data.purchase.trial_period_days,
        subscriptionId:
          createSubscriptionResponse.data.purchase.subscription_id,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
        goal,
        stripeAccountName,
      })

      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
          subscription_price: currentPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: trialPrice,
          trial_period: trialPeriodDays,
          is_cancel_offer_purchased: screenName === ScreenName.CANCEL_OFFER,
        }),
      )
      dispatch(savePlanAdditionsAction())
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('success')
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
    }
  }

export const check3DSecure =
  ({
    stripe,
    goal,
  }: {
    stripe: Stripe
    goal: TAnswer
    screenName: ScreenName
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const priceId = selectSubscriptionLookupKey(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
    const clientSecret = selectPaymentClientSecret(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const subscriptionId = selectCreatedSubscriptionId(state)
    const stripeAccountName = selectStripeAccountName(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)

    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    if (!clientSecret) {
      console.error('Error: client secret is needed')
      return
    }

    dispatch(startFetching(CHECK_3D_SECURE))

    const response = await stripe.retrievePaymentIntent(clientSecret)

    if (response.paymentIntent?.status === 'succeeded') {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        trialPeriodDays,
        subscriptionId,
        goal,
        stripeAccountName,
        paymentSystem: PaymentSystem.STRIPE,
      })

      dispatch(reset3DSecureIframeUrlAction())
      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: PaymentMethod.CREDIT_CARD,
          subscription_price: currentPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: trialPeriodPrice,
          trial_period: trialPeriodDays,
        }),
      )
      return
    }

    if (response.paymentIntent?.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type: 'requires_payment_method',
        },
        paymentSystem: PaymentSystem.STRIPE,
        goal,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction('Payment failed'))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    if (response.error) {
      const {
        error: { type, message },
      } = response

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: response.error,
        goal,
        paymentSystem: PaymentSystem.STRIPE,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction('Error: unhandled checking 3D Secure error'))
    dispatch(stopFetching(CHECK_3D_SECURE))
  }
