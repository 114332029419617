import styled, { css } from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const baseSystemButtonsWrapperStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.measurementSystemBackground};
  border-radius: 12px;
  margin-bottom: 8px;
`

const baseSystemButtonStyles = css`
  width: calc(50% - 4px);
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  color: #565976;
  border-radius: 10px;
  border: none;
  padding: 8px 0;
`

export const StyledWeight = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 46px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
  `,
  ButtonsWrapper: styled.div`
    ${baseSystemButtonsWrapperStyles};
    gap: 4px;
  `,
  ButtonWrapper: styled.div`
    ${baseSystemButtonsWrapperStyles};
  `,
  SystemButton: styled.button`
    ${baseSystemButtonStyles};
    background-color: ${Color.WHITE};
  `,
  SingleSystemButton: styled.button`
    ${baseSystemButtonStyles};
    background-color: ${Color.WHITE};
  `,
  SystemWrapper: styled.div`
    width: 100%;
    margin-bottom: 4px;
  `,
  BmiWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Color.WHITE};
    border-radius: 16px;
    padding: 24px 32px;
    margin: 29px 0 49px;
    filter: drop-shadow(0px 9px 16px rgba(76, 103, 122, 0.21));
  `,
  BmiTitle: styled.h2`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: center;
  `,
  BmiValue: styled.strong<{ isNormalWeight: boolean }>`
    font-weight: 900;
    color: ${({ isNormalWeight }) => (isNormalWeight ? '#01CDA1' : '#FD417F')};
  `,
  BmiLabel: styled.span<{ isNormalWeight: boolean }>`
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: ${Color.WHITE};
    padding: 4px 7px;
    border-radius: 4px;
    margin-bottom: 12px;
    background-color: ${({ isNormalWeight }) =>
      isNormalWeight ? '#01CDA1' : '#FD417F'};
  `,
  BmiDescription: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #565976;
    text-align: center;
  `,
}
