import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledSummaryVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 80px 0 48px;
  `,
  PersonalSummaryTitle: styled.h3`
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
  `,
  Logo: styled.img`
    margin-bottom: 16px;
  `,
  Description: styled.div`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
  `,
  Button: styled(Button)`
    margin-bottom: 48px;
  `,
}
