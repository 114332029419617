import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useFirstPageFinish } from 'hooks/useFirstPageFinish'
import { useURLTitle } from 'hooks/useURLTitle'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { QUESTION, RANGES_V2 } from 'pages/age-range/constants'

import { OptionType } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import arrowIcon from 'assets/images/sprite/circle-right-arrow.svg'

import { goTo } from 'browser-history'
import { STATIC_HEART_AGE } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledAgeRangeVariant4 as S } from './AgeRangeVariant4.styles'

export const AgeRangeVariant4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { handleChange, isAnswersDisabled, setIsAnswersDisabled } =
    useDelayedContinue()
  const handleFirstPageFinish = useFirstPageFinish()
  const urlTitle = useURLTitle()

  const handleContinue = useCallback(
    (age) => {
      dispatch(
        setMultipleAnswerAction({
          answers: {
            [CustomPageId.AGE]: age,
            heart_age: +age + STATIC_HEART_AGE,
          },
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: QUESTION,
        answers: age,
        pageName: CustomPageId.AGE,
      })

      goTo(nextPagePath)
    },
    [dispatch, nextPagePath],
  )

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.AGE,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleFirstPageFinish()
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleFirstPageFinish, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {urlTitle || <Trans i18nKey="onboarding.ageRange.title3" />}
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.ageRange.subtitle4" />
        </S.Subtitle>
        <S.Text>{t`onboarding.ageRange.quiz`}</S.Text>
        <S.OptionsContainer>
          {RANGES_V2.map(({ value, img, text }) => (
            <S.Option
              {...optionProps}
              key={value}
              disabled={isAnswersDisabled}
              value={value}
            >
              <S.ActionContainer>
                <S.Action>
                  <S.RangeImage src={img} alt="range" />
                  <S.Answer />
                  <S.Range>
                    {t('onboarding.ageRange.age', { value: text })}
                    <SvgImage svg={arrowIcon} width={24} />
                  </S.Range>
                </S.Action>
              </S.ActionContainer>
            </S.Option>
          ))}
        </S.OptionsContainer>
        <S.AgreementText>
          <Trans
            i18nKey="onboarding.ageRange.agreement"
            components={{
              termsOfUse: <TermsOfUseLink />,
              privacyPolicy: <PrivacyPolicyLink />,
            }}
          />
        </S.AgreementText>
        <S.Description>{t`commonComponents.description`}</S.Description>
      </S.Column>
    </S.Wrapper>
  )
}
