import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { BASE_COLUMN_WIDTH, Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledHeartCompanionVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: ${({ theme }) => theme.common.wrapperPaddingTop};
  `,
  Title: styled.h1`
    ${baseTitleStyles};
  `,
  SwiperContainer: styled.div`
    max-width: 375px;
    margin-bottom: 30px;

    .swiper-wrapper {
      padding-bottom: 55px;
    }

    .swiper-slide {
      max-width: ${BASE_COLUMN_WIDTH}px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 6px;
      background-color: #b2b8cd;
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background-color: ${Color.DANGER};
    }
  `,
  AvatarContainer: styled.div`
    aspect-ratio: 163/163;
    width: 100%;
    max-width: 163px;
    padding: 16px;
    border: 1px solid #e1ebff;
    background-color: #f6f8ff;
    border-radius: 50%;
    transform: translateY(26px);
  `,
  ReviewContainer: styled.div`
    padding: 34px 12px 24px;
    border-radius: 16px;
    box-shadow: 0 8px 20px 0 #dfe3f3;
    background-color: ${Color.WHITE};
  `,
  Author: styled.p`
    margin-bottom: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  Review: styled.p`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
}
