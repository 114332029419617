import styled from 'styled-components'

import { commonTitleStyles } from 'modules/subscriptions/styles'

type TWrapperProps = {
  marginBottom?: number
}
type TTitleProps = {
  titleBottomMargin?: number
  textAlign?: string
}

export const StyledReviewsSlider = {
  Wrapper: styled.div<TWrapperProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 0}px`};
    max-width: ${({ theme }) => theme.common.columnWidth};

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: ${({ theme }) => theme.common.columnWidth};
    }

    .swiper-pagination {
      position: static;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px 0 0;
    }

    .swiper .swiper-pagination-bullets .swiper-pagination-bullet {
      width: ${({ theme }) => theme.common.swiperPaginationBulletSize};
      height: ${({ theme }) => theme.common.swiperPaginationBulletSize};
      background-color: ${({ theme }) => theme.colors.swiperPaginationBullet};
      border-radius: 50%;
      margin: ${({ theme }) => theme.common.swiperPaginationBulletMargin};
      opacity: 1;
    }

    .swiper .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background-color: ${({ theme }) =>
        theme.colors.swiperPaginationBulletActive};
    }
  `,
  Title: styled.h3<TTitleProps>`
    ${commonTitleStyles};
    color: ${({ theme }) => theme.colors.secondaryText};
    margin-bottom: ${({ titleBottomMargin }) => `${titleBottomMargin || 16}px`};
    text-align: ${({ textAlign }) => textAlign || 'center'};
  `,
  Card: styled.div`
    padding: 24px 24px 32px;
    background-color: white;
    border-radius: 16px;
    border: ${({ theme }) => `1px solid ${theme.colors.primaryBorder}`};
  `,
  CardHeader: styled.header`
    margin-bottom: 16px;
    display: flex;
    gap: 12px;
  `,
  Avatar: styled.img`
    border-radius: 50%;
  `,
  PersonDescription: styled.span`
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
  `,
  CardTitle: styled.h4`
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
  `,
  CardText: styled.p`
    font-size: 13px;
    line-height: 20px;
  `,
}
