import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

export const StyledFoodsVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    min-height: 100dvh;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-top: 24px;
  `,
  Title: styled.h1`
    margin-bottom: 8px;
    color: ${Color.GRAY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  Subtitle: styled.h2`
    margin-bottom: 24px;
    color: ${Color.GRAY_80};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  `,
}
