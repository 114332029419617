import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { GOAL_TO_MARKUP } from 'pages/users-around-the-world/constants'

import { Button } from 'components/Button'

import logos from 'assets/images/logos.png'
import users from 'assets/images/users-across-the-world.jpg'

import { StyledUsersAroundTheWorldVariant2 as S } from './UsersAroundTheWorldVariant2.styles'

export const UsersAroundTheWorldVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { goal } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageContainer>
          <img src={users} alt="users" />
        </S.ImageContainer>
        <S.Title>
          <Trans
            i18nKey="onboarding.usersAroundTheWorld2.title"
            values={{ goal: t(GOAL_TO_MARKUP[goal as string]) }}
          />
        </S.Title>
        <S.LogosContainer>
          <img src={logos} alt="partners" />
        </S.LogosContainer>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
