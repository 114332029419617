import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledAge = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 46px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 74px;
  `,
  InputWrapper: styled.div`
    width: 100%;
    margin-bottom: 4px;
  `,
  DescriptionWrapper: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 16px;
    padding: 16px 32px 24px;
    margin: 29px 0 49px;
    filter: drop-shadow(0px 9px 16px rgba(76, 103, 122, 0.21));
  `,
  DescriptionImage: styled.img`
    margin: 0 auto 8px;
  `,
  DescriptionTitle: styled.h2`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 8px;
    text-align: center;
  `,
  DescriptionText: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #565976;
    text-align: center;
  `,
}
