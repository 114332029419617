import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FeaturesReady, useFeatureIsOn } from '@growthbook/growthbook-react'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { setPlanAdditionsAction } from 'modules/payment/redux/actions/common'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'
import { AccessVariant1 } from 'modules/subscriptions/components/common/Access'
import { CustomerRatingVariant1 } from 'modules/subscriptions/components/common/CustomerRating'
import { Guides } from 'modules/subscriptions/components/common/Guides'
import { HeartAgeVariant1 } from 'modules/subscriptions/components/common/HeartAge'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { PLAN_ITEMS, TRIAL_PLAN_ITEMS } from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { HeaderWithButton } from 'components/HeaderWithButton'
import { ReviewsSlider } from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  CURRENCY_SYMBOLS,
  PlanAddition,
  SubscriptionTag,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledSubscriptionsVariant9 as S } from './SubscriptionsVariant9.styles'

export const SubscriptionsVariant9: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const price = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currency = useSelector(selectCurrency)
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  const hasFreeGuides = useFeatureIsOn('web_car886_free_guides')

  useGetPrices({
    tags: SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const planItem = useMemo(() => {
    return PLAN_ITEMS[subscriptionBlockType]
  }, [subscriptionBlockType])

  const isTrialPlan = useMemo(
    () => TRIAL_PLAN_ITEMS.includes(subscriptionBlockType),
    [subscriptionBlockType],
  )

  useEffect(() => {
    if (hasFreeGuides) {
      dispatch(setPlanAdditionsAction([PlanAddition.FREE_GUIDES]))
    }
  }, [hasFreeGuides, dispatch])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <HeaderWithButton onClick={handleScroll} />

      <S.Content>
        <SubheaderWithTimer discount={isTrialPlan ? 50 : 75} />
        <S.Title>
          <Trans i18nKey="subscriptions.achieveBloodPressure" />
        </S.Title>
        <S.Disclaimer>{t`subscriptions.bloodPressureProgram`}</S.Disclaimer>
        <HeartAgeVariant1 />
        <FeaturesReady timeout={0} fallback={<div />}>
          {hasFreeGuides && <Guides />}
        </FeaturesReady>
        <S.SelectPlanBlock
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={planItem}
        />
        <S.Button onClick={handleShowPayment}>{t`actions.getCardi`}</S.Button>
        {isTrialPlan ? (
          <S.SubscriptionDescription>
            <Trans
              i18nKey="subscriptions.paidTrial.subscriptionDescription"
              values={{
                context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
                currencySymbol: CURRENCY_SYMBOLS[currency],
                currencyCode: currency.toUpperCase(),
                trialPrice,
                price,
                minimumFractionDigits: 2,
              }}
              components={{
                termsOfUse: <TermsOfUseLink />,
                supportLink: <SupportLink />,
              }}
            />
          </S.SubscriptionDescription>
        ) : (
          <S.CancelInfo>{t`subscriptions.disclaimer`}</S.CancelInfo>
        )}
        <AccessVariant1 />
        <CustomerRatingVariant1 />
        <ReviewsSlider
          titlePath="subscriptions.customersLove"
          titleBottomMargin={24}
          marginBottom={32}
          textAlign="start"
        />
        <S.MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
