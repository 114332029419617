import styled from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

type TMeasurementProps = {
  lineBottom: number
}

export const StyledSocialProofVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    min-height: 100dvh;
    background-color: #f2f3f5;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;
    padding-top: 16px;
  `,
  Measurements: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    aspect-ratio: 343/343;
    margin-bottom: 40px;
    padding: 24px 20px;
  `,
  AnimationContainer: styled.div`
    position: absolute;
    top: 0;
    right: -80px;
    width: 100%;
    z-index: 1;
  `,
  Measurement: styled.p<TMeasurementProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #35b3e5;
    font-family: 'Digital Numbers Regular', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase;

    svg {
      position: absolute;
      left: 0;
      bottom: ${({ lineBottom }) => `${lineBottom}px`};
    }
  `,
  Text: styled.p`
    flex-grow: 1;
    margin-bottom: 24px;
    color: ${Color.BLUE_DARK};
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-transform: uppercase;

    strong {
      color: ${Color.RED};
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    background-image: none;
  `,
}
