import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { HEALTH_CONDITION_OPTION_VALUES, Language } from 'root-constants/common'

import { StyledOverallCondition as S } from './OverallCondition.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const OverallConditionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.overallCondition.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked && value === HEALTH_CONDITION_OPTION_VALUES.NONE) {
          setAnswers([HEALTH_CONDITION_OPTION_VALUES.NONE])
          return
        }

        if (isChecked) {
          const newValues = [...answers, value].filter(
            (item) => item !== HEALTH_CONDITION_OPTION_VALUES.NONE,
          )
          setAnswers(newValues)
          return
        }

        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      },
    }),
    [answers, pageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.overallCondition.title`}</S.Title>
        <S.Subtitle>
          {t('onboarding.overallCondition.subtitle', { context: cohortToUse })}
        </S.Subtitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.OVERWEIGHT}
            checked={answers.includes(OPTION_VALUES.OVERWEIGHT)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.overweight`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.STRESS}
            checked={answers.includes(OPTION_VALUES.STRESS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.stress`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.BARELY_ACTIVE}
            checked={answers.includes(OPTION_VALUES.BARELY_ACTIVE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.barelyActive`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DIET}
            checked={answers.includes(OPTION_VALUES.DIET)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.diet`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NONE}
            checked={answers.includes(OPTION_VALUES.NONE)}
          >
            <QuestionButton data-has-none-button-check-icon>
              {t`actions.none`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
