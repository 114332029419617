import React from 'react'

import heartLevel1Image from 'assets/images/heart-level-1.svg'
import heartLevel2Image from 'assets/images/heart-level-2.svg'

import { StyledHeartLevel as S } from './HeartLevel.styles'

type TProps = React.HTMLAttributes<HTMLDivElement> & {
  percent: number
  iconVariant: 1 | 2
}

export const HeartLevel: React.FC<TProps> = ({
  percent,
  iconVariant,
  children,
}) => (
  <S.Wrapper>
    <S.Img
      src={iconVariant === 1 ? heartLevel1Image : heartLevel2Image}
      height={53}
      alt="heart level"
    />
    <S.Content>
      <S.Title>{percent}%</S.Title>
      <S.Text>{children}</S.Text>
    </S.Content>
  </S.Wrapper>
)
