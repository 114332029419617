import styled from 'styled-components'

import { BottomStickyBase } from 'common-styles'

import { StyledButtonBase } from '../Button/Button.styles'

export const StyledStickyButton = styled.button`
  ${StyledButtonBase};
  ${BottomStickyBase};
`
