import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Gender } from 'root-constants/common'

import { StyledGender as S } from './Gender.styles'

const PAGE_NAME = 'Gender question'

export const GenderVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.gender.title', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.gender.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={Gender.MALE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.male`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={Gender.FEMALE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.female`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={Gender.OTHER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.other`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
