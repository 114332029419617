import styled, { css } from 'styled-components'

import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

const progressValueStyles = css`
  background-color: ${Color.DANGER};
  transition: all 0.2s ease-out;
`

export const StyledProgressBarV2 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    max-width: ${BASE_COLUMN_WIDTH}px;
    margin: 0 auto;
  `,
  TitleWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
  `,
  Title: styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${Color.LIGHT_TEXT};
  `,
  PagesCounter: styled.p`
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
  `,
  ProgressContainer: styled.div`
    display: flex;
    justify-content: center;
    column-gap: 4px;
  `,
  Progress: styled.progress`
    width: 106px;
    height: 4px;
    appearance: none;
    background-color: #d0ddd4;
    border-radius: 2px;
    overflow: hidden;
    border: none;
    display: block;

    &::-webkit-progress-bar {
      background-color: #d0ddd4;
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
