import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import { Gender } from 'root-constants/common'

import { StyledFacts as S } from './Facts.styles'
import { ETHNICITY_TEXTS } from './constants'

type TProps = {
  marginTop?: number
}

export const Facts: React.FC<TProps> = ({ marginTop }) => {
  const { t } = useTranslation()
  const { isMale, ethnicity } = useUserData()

  return (
    <S.Wrapper marginTop={marginTop}>
      <S.Title>{t`summary.facts`}</S.Title>
      <S.List>
        <li>{t`summary.heartHealth.fact1`}</li>
        <li>
          {t('summary.heartHealth.fact2', {
            context: isMale ? Gender.MALE : Gender.FEMALE,
          })}
        </li>
        {ethnicity && ETHNICITY_TEXTS[ethnicity] && (
          <li>{t(ETHNICITY_TEXTS[ethnicity])}</li>
        )}
      </S.List>
    </S.Wrapper>
  )
}
