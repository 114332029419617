import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { ProgressBarV4 } from 'components/ProgressBarV4'

import appNameLogo from 'assets/images/app-name-logo-2.png'
import arrowLeft from 'assets/images/sprite/arrow-left.svg'

import { goBack } from 'browser-history'
import {
  COHORTS_WITH_BURGER_MENU,
  PAGES_WITH_BURGER_MENU,
} from 'root-constants/common'

import { StyledHeaderV3 as S } from './HeaderV3.styles'

export const HeaderV3: React.FC = () => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { currentPageId, hasHeader, hasProgressbar } = useGetPageInfo()

  const hasBurgerMenu = useMemo(
    () =>
      COHORTS_WITH_BURGER_MENU.includes(cohortToUse) &&
      PAGES_WITH_BURGER_MENU.includes(currentPageId),
    [cohortToUse, currentPageId],
  )

  return !hasHeader ? null : (
    <S.Wrapper>
      <S.Header>
        {hasProgressbar && (
          <S.ArrowButton svg={arrowLeft} width={24} onClick={goBack} />
        )}
        {!hasProgressbar && <S.AppNameLogo src={appNameLogo} alt="logo" />}
        <ProgressBarV4 />
      </S.Header>
      {hasBurgerMenu && <S.BurgerMenu />}
    </S.Wrapper>
  )
}
