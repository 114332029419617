import React from 'react'
import { useTranslation } from 'react-i18next'

import { eventLogger } from 'services/eventLogger.service'

import { AppLinks } from 'root-constants/common'

import { OuterLink } from '../OuterLink'

export const TermsOfUseLink: React.FC = () => {
  const { t } = useTranslation()

  return (
    <OuterLink
      href={AppLinks.TOU_LINK}
      onClick={() => eventLogger.logTermsOfUseClicked()}
    >
      {t`commonComponents.termsOfUse`}
    </OuterLink>
  )
}
