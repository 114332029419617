import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

import { EMAIL_REGEXP } from 'root-constants/common'

export const useEmailInputField = (
  initialValue = '',
  errorMessage = '',
  resetErrorMessage: () => void = () => undefined,
): TInputFieldHook => {
  const { t } = useTranslation()

  const [email, setEmail] = useState<IInputFieldState>({
    value: initialValue,
    isValid: true,
    validationText: t`login.invalidEmail`,
  })

  const validateEmail = useCallback(
    (emailValue: string) => {
      const formattedEmail = emailValue.toLowerCase().trim()

      if (!EMAIL_REGEXP.test(formattedEmail) && formattedEmail !== '') {
        setEmail((prevState) => ({
          ...prevState,
          value: formattedEmail,
          isValid: false,
          validationText: t`login.invalidEmail`,
        }))
        return
      }

      setEmail((prevState) => ({
        ...prevState,
        value: formattedEmail,
        isValid: true,
        validationText: '',
      }))
    },
    [t],
  )

  useEffect(() => {
    if (errorMessage) {
      setEmail((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: errorMessage,
      }))

      resetErrorMessage()
    }
  }, [errorMessage, resetErrorMessage])

  return [email, setEmail, validateEmail]
}
