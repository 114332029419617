import styled from 'styled-components'

export const StyledPersonalSummary = {
  Wrapper: styled.div`
    margin-bottom: 48px;
    padding: 28px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0 8px 12px rgba(152, 159, 187, 0.1);
    border-radius: 16px;
  `,
  Item: styled.div`
    margin: 0 12px;
    display: flex;
    align-items: center;
    gap: 4px;
  `,
  Text: styled.span`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
  `,
  Unit: styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(44, 47, 84, 0.5);
  `,
  Divider: styled.div`
    margin: 0 12px;
    width: 1px;
    height: 17px;
    background-color: #d5daeb;
  `,
}
