import styled from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

export const StyledEatingHabitsVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    min-height: 100dvh;
    background-color: #f2f3f5;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;
    padding-top: 24px;
  `,
  Content: styled.div`
    flex-grow: 1;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 343/367;
  `,
  Title: styled.h2`
    margin-bottom: 8px;
    color: ${Color.GRAY_100};
    line-height: 40px;
    font-size: 32px;
    font-weight: 700;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #f2f3f5 24%
    );
  `,
}
