export const OPTIONS_DATA = [
  {
    value: 'up_to_fifteen_minutes',
    text: 'onboarding.mealTime.upToFifteenMinutes',
  },
  {
    value: 'up_to_thirty_minutes',
    text: 'onboarding.mealTime.upToThirtyMinutes',
  },
  { value: 'up_to_one_hour', text: 'onboarding.mealTime.upToOneHour' },
  { value: 'more_than_one_hour', text: 'onboarding.mealTime.moreThanOneHour' },
]
