import styled from 'styled-components'

export const StyledHeartStats = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 32px;
  `,
  Content: styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
  `,
  IconWrapper: styled.div`
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    background-color: rgba(217, 70, 70, 0.12);
  `,
  Icon: styled.img`
    width: 20px;
    height: 20px;
  `,
  Text: styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primaryText};

    strong {
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.highlightedText};
    }
  `,
}
