import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserHeartAge } from 'root-redux/selects/common'

import heartUpsetImage from 'assets/images/heart-upset.svg'

import { StyledHeartAge as S } from './HeartAge.styles'

export const HeartAge: React.FC = () => {
  const { t } = useTranslation()
  const userHeartAge = useSelector(selectUserHeartAge)

  return (
    <S.Wrapper>
      <S.Title>
        <Trans
          i18nKey="summary.heartAge.title"
          values={{ age: userHeartAge }}
          components={[<strong />]}
        />
      </S.Title>
      <S.Text>{t`summary.heartAge.subtitle`}</S.Text>
      <S.Img src={heartUpsetImage} height={127} alt="heart upset" />
      <S.Text>{t`summary.heartAge.text`}</S.Text>
    </S.Wrapper>
  )
}
