import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryAppBenefits = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    height: calc(100svh - ${HEADER_HEIGHT}px);
    padding: 48px 0;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 16px;
  `,
  Table: styled.table`
    position: relative;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    border: 1px solid #b2b8cd;
    border-radius: 16px;
    border-spacing: 0;

    &::after {
      content: '';
      position: absolute;
      top: 6px;
      right: 6px;
      width: 95px;
      height: calc(100% - 12px);
      background-color: #d7e7ff;
      border-radius: 16px;
      z-index: -1;
    }
  `,
  TableHead: styled.thead`
    th {
      padding: 17px 21px;
    }

    strong {
      font-weight: 900;
    }
  `,
  TableBody: styled.tbody`
    tr {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: auto;
        width: 100%;
        height: 1px;
        background-color: #cad0e4;
      }
    }

    th {
      height: 60px;
      padding: 12px 21px;
    }
  `,
  CheckIcon: styled(SvgImage)`
    display: block;
    width: 20px;
    margin: 0 auto;
  `,
  CrossIcon: styled(SvgImage)`
    display: block;
    width: 24px;
    margin: 0 auto;
  `,
  DisclaimerContainer: styled.div`
    flex-grow: 1;
    margin-bottom: 16px;
  `,
  Disclaimer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 24px;
    border-radius: 16px;
    background-color: #f0f2fa;
  `,
  DisclaimerImage: styled.img`
    width: 32px;
    margin-right: 16px;
  `,
  DisclaimerText: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    strong {
      font-weight: 900;
    }
  `,
}
