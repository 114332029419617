import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import stressDescription from 'assets/images/stress-description.webp'

import { StyledStress as S } from './Stress.styles'

const PAGE_NAME = 'Social Proof Stress'

export const StressDescriptionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.TitleStressDescription>
          {t`onboarding.stress.stressImpact`}
        </S.TitleStressDescription>
        <S.Description>
          <Trans i18nKey="onboarding.stress.description" />
        </S.Description>
        <S.Image src={stressDescription} alt="stress" loading="lazy" />

        <S.Disclaimer>{t`onboarding.stress.disclaimer`}.</S.Disclaimer>
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
