import { paymentApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { savePlanAdditionsAction } from 'root-redux/actions/user'
import {
  selectUUID,
  selectUserPaymentMethod,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import { TAnswer } from 'models/common.model'
import { IAppState, TAppDispatchThunk } from 'models/store.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  StripeDeclineReason,
  StripeSoftDeclineReason,
} from 'modules/payment/constants'
import { getPurchaseFailedEventParams } from 'modules/payment/helpers/getPurchaseFailedEventParams'
import { getPurchaseStartedEventParams } from 'modules/payment/helpers/getPurchaseStartedEventParams'
import { getPurchaseSuccessEventParams } from 'modules/payment/helpers/getPurchaseSuccessEventParams'
import { logSuccessfulPayment } from 'modules/payment/helpers/logSuccessfulPayment'
import {
  selectPlanId,
  selectProductId,
  selectSubscriptionTrialPeriodDays,
} from 'modules/payment/redux/selects'

import { PaymentSystem } from 'root-constants/common'

const MODULE_NAME = 'UPSELL'

export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`

export const makeUpsellAction =
  (goal: TAnswer, screenName: ScreenName): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const planId = selectPlanId(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const paymentMethod = selectUserPaymentMethod(state)
    const paymentSystem = selectUserPaymentSystem(state)
    const productId = selectProductId(state)

    dispatch(startFetching(MAKE_UPSELL))

    const commonPurchaseStartedParams = getPurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      paymentSystem,
      goal,
    })

    const upsellResponse =
      paymentSystem === PaymentSystem.PRIMER
        ? await paymentApi.makePrimerUpsell({
            uuid,
            planId,
            productId,
          })
        : await paymentApi.makeUpsell({
            uuid,
            planId,
            productId,
          })

    if (upsellResponse.success && upsellResponse.data) {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        trialPeriodDays,
        subscriptionId: upsellResponse.data.subscription.subscription_id,
        paymentMethod,
        paymentSystem,
        productId: upsellResponse.data.subscription.product_id,
        goal,
        screenName,
      })

      dispatch(savePlanAdditionsAction())
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    let errorText: string

    switch (upsellResponse.data?.decline_code) {
      case StripeSoftDeclineReason.INSUFFICIENT_FUNDS:
        errorText = i18n.t('payment.paymentWaitingModal.insufficientFunds')
        dispatch(setErrorAction(errorText))
        break
      case StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED:
        errorText = i18n.t('payment.paymentWaitingModal.velocityExceeded')
        dispatch(setErrorAction(errorText))
        break
      case StripeDeclineReason.LOST_CARD:
      case StripeDeclineReason.STOLEN_CARD:
      case StripeDeclineReason.GENERIC_DECLINE_ERROR:
        errorText = i18n.t('payment.paymentWaitingModal.genericDecline')
        dispatch(setErrorAction(errorText))
        break
      default:
        errorText = i18n.t('commonComponents.commonError')
        dispatch(setErrorAction(errorText))
    }

    eventLogger.logPurchaseFailed({
      ...commonPurchaseFailedParams,
      paymentMethod,
      paymentSystem,
      error: {
        type: upsellResponse.data?.error,
        code: upsellResponse.data?.decline_code,
        description: errorText,
      },
      goal,
    })
    dispatch(stopFetching(MAKE_UPSELL))
  }
