import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledHealthCondition = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 46px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
  `,
  Text: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 40px;
  `,
}
