import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import {
  PAGE_NAME,
  PROCESSING_4_REVIEW_DATA,
  PROCESSING_DATA_4,
} from 'pages/plan-processing/constants'

import { ProgressRounded } from 'components/ProgressRounded'

import ratingStars from 'assets/images/rating-stars.svg'

import { StyledPlanProcessingVariant4 as S } from './PlanProcessingVariant4.styles'

export const PlanProcessingVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { userGender } = useUserData()
  const [progressText, setProgressText] = useState('')

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  const { linearCounterValue } = useCounter(true, {
    duration: 10000,
    callback: handleContinue,
  })

  useEffect(() => {
    const loaderPoint = PROCESSING_DATA_4.find(
      ({ threshold }) => threshold === linearCounterValue,
    )

    if (loaderPoint) {
      setProgressText(loaderPoint.text)
    }
  }, [linearCounterValue])

  return (
    <S.Wrapper>
      <S.Column>
        <S.ProgressContainer>
          <ProgressRounded
            value={linearCounterValue}
            pathColor="#01cda1"
            trailColor="#f0f2fa"
          />
          <S.ProgressText key={progressText}>{t(progressText)}</S.ProgressText>
        </S.ProgressContainer>
        <S.Title>
          <Trans i18nKey="onboarding.processing.title5" />
        </S.Title>
        <S.ReviewWrapper>
          <S.AvatarContainer>
            <S.Avatar
              src={PROCESSING_4_REVIEW_DATA[userGender].avatar}
              alt="avatar"
            />
            <S.NameContainer>
              <img src={ratingStars} alt="rating" />
              <S.PersonDescription>
                {t(PROCESSING_4_REVIEW_DATA[userGender].name)}
              </S.PersonDescription>
            </S.NameContainer>
          </S.AvatarContainer>
          <S.Text>{t`commonComponents.reviewsSlider.ninthUser.review`}</S.Text>
        </S.ReviewWrapper>
      </S.Column>
    </S.Wrapper>
  )
}
