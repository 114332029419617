import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseSubtitleStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledMonitorBloodPressureVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${({ theme }) => theme.common.wrapperPaddingTop};
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 28px;
    padding-top: ${({ theme }) => theme.common.columnPaddingTop};
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 24px;
  `,
  Subtitle: styled.h2`
    ${baseSubtitleStyles};
    margin-bottom: 24px;
    color: ${Color.LIGHT_TEXT};
  `,
}
