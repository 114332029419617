import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { BASE_COLUMN_WIDTH, Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledSocialProofPressureVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    background: linear-gradient(
        0deg,
        rgba(240, 242, 250, 0.4) 0%,
        #f0f2fa 30%,
        #d5e4ff 100%
      ),
      #f0f2fa;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 64px 0 0;
  `,
  Text: styled.p`
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 109px;

    strong {
      font-size: 32px;
      font-weight: 900;
      line-height: 40px;
      color: ${Color.DANGER};
    }

    svg {
      max-height: 40px;
      max-width: 160px;
      position: relative;
      top: 10px;
      margin-top: -10px;
    }
  `,
  LogosWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;

    img {
      max-height: 28px;
    }

    img:last-of-type {
      max-width: 140px;
    }
  `,
  StickyWrapper: styled.div`
    position: fixed;
    z-index: 1;
    bottom: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: ${BASE_COLUMN_WIDTH}px;

    @media (orientation: landscape) {
      position: relative;
      bottom: 0;
    }
  `,
}
