import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

export const StyledTimer = {
  Wrapper: styled.div`
    margin-bottom: 48px;
    height: 46px;
  `,
  TimerContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Timer: styled.strong`
    width: 40px;
    padding: 8px 0;
    color: #153e83;
    text-align: center;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    border-radius: 8px;
    background-color: #f0f2fa;
  `,
  SvgImage: styled(SvgImage)`
    padding: 0 8px;
    height: 16px;
  `,
}
