import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ACCESS_DATA } from 'modules/subscriptions/components/common/Access/constants'

import { StyledAccessVariant1 as S } from './AccessVariant1.styles'

type TProps = {
  accessData?: { image: string; icon: string; title: string; text: string }[]
}
export const AccessVariant1: React.FC<TProps> = ({
  accessData = ACCESS_DATA,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <S.Wrapper {...props}>
      <S.Title>{t`subscriptions.access.title`}</S.Title>
      {accessData.map(({ image, icon, title, text }) => (
        <S.Column key={title}>
          <S.ImageContainer>
            <img src={image} alt="phone" />
          </S.ImageContainer>
          <S.DataWrapper>
            <S.Icon src={icon} alt="icon" />
            <S.Subtitle>
              <Trans i18nKey={t(title)} />
            </S.Subtitle>
            <S.Text>{t(text)}</S.Text>
          </S.DataWrapper>
        </S.Column>
      ))}
    </S.Wrapper>
  )
}
