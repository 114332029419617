import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryHeartScore = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    min-height: calc(var(--full-height, 100vh) - ${HEADER_HEIGHT}px);
    padding: 48px 0;
  `,
  Title: styled.h1`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
    margin-bottom: 32px;
  `,
  HeartScoreContentContainer: styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  `,
  HeartDataWrapper: styled.div`
    border-radius: 16px;
    border: 1px solid #b2b8cd;
    padding: 16px;
    max-width: 113px;
    width: 100%;
    white-space: nowrap;
  `,
  WrapperTitle: styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
  `,
  HighlightedText: styled.p`
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.highlightedText};
    text-align: center;
  `,
  Text: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #565976;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 328/166;
    margin-top: 16px;
    margin-bottom: 32px;
  `,
  BenefitsText: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-top: 12px;
    text-align: center;
    margin-bottom: 32px;
    flex-grow: 1;
  `,
}
