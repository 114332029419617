import styled, { css } from 'styled-components'

import { Color } from 'root-constants/common'

const commonPaymentMethodStyles = css`
  min-width: 160px;
  height: 78px;
  border-radius: 12px;
  background-color: ${Color.WHITE};
  box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
  border: 1px solid transparent;

  input:checked + & {
    border: 1px solid ${Color.DANGER};
  }
`

export const StyledPaymentMethodSelector = {
  Wrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  `,
  InputLabel: styled.label`
    cursor: pointer;
    display: block;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    & > input {
      display: none;
    }
  `,
  CardPayment: styled.div`
    ${commonPaymentMethodStyles};
    display: flex;
    flex-direction: column;
    place-content: center;
    padding: 19px 32px;
    color: #aaacb2;

    img {
      max-width: 94px;
    }

    input:checked + & {
      color: ${Color.DEFAULT_TEXT};
    }
  `,
  CardPaymentText: styled.p`
    margin-bottom: 8px;
    text-align: center;
    font-size: 13px;
    font-weight: 800;
    line-height: 16px;
  `,
  ApplePay: styled.div`
    ${commonPaymentMethodStyles};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 72px;
      height: 46px;
    }
  `,
  GooglePay: styled.div`
    ${commonPaymentMethodStyles};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 64px;
      height: 24px;
    }
  `,
}
