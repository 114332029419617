import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StickyButton } from 'components/StickyButton'

import { Language } from 'root-constants/common'

import { StyledSocialProofDehydrationVariant1 as S } from './SocialProofDehydrationVariant1.styles'

export const SocialProofDehydrationVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.socialProofDehydration.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Text>{t`onboarding.socialProofDehydration.title`}</S.Text>
        <StickyButton onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
