import styled, { css, keyframes } from 'styled-components'

import checkIcon from 'assets/images/check-icon-green.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TProps = {
  isSeniorFlow: boolean
}

const progressValueStyles = css`
  border-radius: 10px;
  background-image: linear-gradient(90deg, #00d4ce 0%, #00cb97 100%);
  transition: all 0.2s ease-out;
`
const itemVisibility = keyframes`
  0% {
   opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const animation = css`
  animation-name: ${itemVisibility};
  animation-duration: 8000ms;
  animation-fill-mode: backwards;

  [data-item='1']& {
    animation-delay: 1000ms;
  }

  [data-item='2']& {
    animation-delay: 2000ms;
  }

  [data-item='3']& {
    animation-delay: 3000ms;
  }

  [data-item='4']& {
    animation-delay: 4000ms;
  }

  [data-item='5']& {
    animation-delay: 5000ms;
  }
`

export const StyledPlanProcessingVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    padding: 48px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
  `,
  Title2: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 64px;
  `,
  Text: styled.p<TProps>`
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 64px;
    font-size: ${({ isSeniorFlow }) => (isSeniorFlow ? 16 : 13)}px;
  `,
  ProgressBar: styled.progress`
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background-color: ${Color.WHITE};
    margin-bottom: 48px;

    &::-webkit-progress-bar {
      background-color: ${Color.WHITE};
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
    }
    &::-ms-fill {
      ${progressValueStyles};
    }
  `,
  Block: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  Item: styled.div<TProps>`
    ${animation};
    position: relative;
    padding-left: 24px;
    font-size: ${({ isSeniorFlow }) => (isSeniorFlow ? 16 : 13)}px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  `,
  Disclaimer: styled.p`
    position: fixed;
    bottom: 48px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
}
