import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryPremiumClub = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    height: calc(100svh - ${HEADER_HEIGHT}px);
    padding: 48px 0;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 8px;
  `,
  Subtitle: styled.h3`
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  CardContainer: styled.div`
    flex-grow: 1;
  `,
  Card: styled.div`
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'avatar rating'
      'avatar author'
      'title title'
      'review review';
    grid-gap: 0 12px;
    margin-bottom: 16px;
    padding: 16px 24px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    border: ${({ theme }) => `1px solid ${theme.colors.primaryBorder}`};
  `,
  Avatar: styled.img`
    grid-area: avatar;
    border-radius: 50%;
  `,
  Rating: styled.img`
    grid-area: rating;
    height: 15px;
  `,
  Author: styled.span`
    grid-area: author;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
  `,
  CardTitle: styled.h3`
    grid-area: title;
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
  `,
  Review: styled.p`
    grid-area: review;
    font-size: 13px;
    line-height: 20px;
  `,
}
