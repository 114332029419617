import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  Button,
  InputWithFloatPlaceholder,
  InputWithFloatPlaceholderTheme,
  buttonTheme,
} from 'storybook-ui'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { getFilteredEmailDomains } from 'modules/email/helpers'

import { Modal } from 'components/Modal'
import { SvgImage } from 'components/SvgImage'

import checkIcon from 'assets/images/check-circle-light-green.svg'
import lockIcon from 'assets/images/sprite/lock.svg'
import warningIcon from 'assets/images/sprite/warning-2.svg'

import { goTo } from 'browser-history'

import { StyledEmailVariant3 as S } from './EmailVariant3.styles'

export const EmailVariant3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const fetchingActionsList = useSelector(selectActionList)
  const userStatus = useSelector(selectUserStatus)

  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const [email, , validateEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)
  const { currentSubscriptionPageId } = useGetPageInfo()
  const { title, subtitle, buttonText } = useDynamicOBConfig()

  const isStatusFetching = useMemo(
    () =>
      fetchingActionsList?.includes(SEND_USER_EMAIL) ||
      fetchingActionsList?.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const isButtonDisabled = useMemo(
    () => !email.value || !email.isValid || isStatusFetching,
    [email.isValid, email.value, isStatusFetching],
  )

  const filteredEmailDomains = useMemo(
    () => getFilteredEmailDomains(deferredEmail),
    [deferredEmail],
  )

  useLayoutEffect(() => {
    if (userStatus?.email.hasEmail) {
      goTo(nextPagePath || `${currentSubscriptionPageId}${search}`)
    }
  }, [
    currentSubscriptionPageId,
    nextPagePath,
    search,
    userStatus?.email.hasEmail,
  ])

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (!email.isValid || isStatusFetching) return

      dispatch(
        sendUserEmailAction({
          email: email.value.toLowerCase(),
          unsuccessCallback: () => setIsErrorModalShown(true),
          nextPagePath,
        }),
      )
    },
    [dispatch, email.isValid, email.value, isStatusFetching, nextPagePath],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      validateEmail(value)
      setAreEmailTipsVisible(true)
    },
    [validateEmail],
  )

  const handlePrefilledEmail = useCallback(
    ({ currentTarget: { value } }) => {
      validateEmail(value)
      setAreEmailTipsVisible(false)
    },
    [validateEmail],
  )

  return (
    <>
      <S.Wrapper>
        <form onSubmit={handleSubmit}>
          <S.Content>
            <S.Title>{title || <Trans i18nKey="email.title5" />}</S.Title>
            <S.Subtitle>
              {subtitle || <Trans i18nKey="email.subtitle3" />}
            </S.Subtitle>

            <S.InputWrapper>
              <InputWithFloatPlaceholder
                theme={InputWithFloatPlaceholderTheme.CARDIMATE}
                type="text"
                value={email.value}
                isValid={email.isValid}
                label={t`email.emailPlaceholder`}
                validationIconSize={24}
                autoComplete="off"
                onChange={handleChange}
                hasValidationIcon
                checkIconSvg={checkIcon}
                border={
                  email.value && !email.isValid
                    ? `1px solid #f83b00`
                    : `1px solid #cacaca`
                }
              />
              {email.value && !email.isValid && (
                <S.ValidationText>
                  <Trans
                    i18nKey="email.invalidEmail"
                    components={{
                      emoji: <SvgImage svg={warningIcon} width={16} />,
                    }}
                  />
                </S.ValidationText>
              )}
              {areEmailTipsVisible && !!filteredEmailDomains.length && (
                <S.AutoCompleteWrapper>
                  {filteredEmailDomains.map((value) => (
                    <S.AutoCompleteButton
                      type="button"
                      key={value}
                      value={value}
                      onMouseDown={handlePrefilledEmail}
                    >
                      <S.AutoCompleteButtonTextWrapper>
                        {value}
                      </S.AutoCompleteButtonTextWrapper>
                    </S.AutoCompleteButton>
                  ))}
                </S.AutoCompleteWrapper>
              )}
            </S.InputWrapper>

            <S.Disclaimer>
              <SvgImage svg={lockIcon} width={40} />
              <span>{t`email.disclaimer`}</span>
            </S.Disclaimer>
            <Button
              type="submit"
              disabled={isButtonDisabled}
              theme={buttonTheme.CARDIMATE}
            >
              {buttonText || t`actions.continue`}
            </Button>
          </S.Content>
        </form>
      </S.Wrapper>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
