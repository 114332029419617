import React, { useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useFirstPageFinish } from 'hooks/useFirstPageFinish'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BurgerMenu } from 'components/BurgerMenu'
import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import poster from 'assets/images/video-poster.jpg'
import video from 'assets/video/intro.mp4'

import { replaceHistory } from 'browser-history'
import { Gender, Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledIntroVariant4 as S } from './IntroVariant4.styles'

const PAGE_NAME = 'Gender question'
export const IntroVariant4: React.FC<TPageProps> = ({
  pageId,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const isAlternativeVariant = useFeatureIsOn('car_183_first_page_test')

  const { handleChange, isAnswersDisabled, setIsAnswersDisabled } =
    useDelayedContinue()
  const handleFirstPageFinish = useFirstPageFinish()

  useLayoutEffect(() => {
    if (isAlternativeVariant) {
      replaceHistory({ pathname: PageId.INTRO_5, search })
    }
  }, [isAlternativeVariant, search])

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.intro.selectGender', { lng: Language.EN }),
    nextPagePath: alternativePagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleFirstPageFinish()
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      handleFirstPageFinish,
      handleChange,
      handleContinue,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Wrapper>
      <S.VideoWrapper>
        <S.Video autoPlay loop muted poster={poster} playsInline>
          <source src={video} type="video/mp4" />
        </S.Video>
      </S.VideoWrapper>
      <BurgerMenu />

      <S.Column>
        <S.Title>{t`onboarding.intro.intro4.title`}</S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.intro.intro4.subtitle" />
        </S.Subtitle>

        <S.FixedContainer>
          <S.Text>{t`onboarding.intro.selectGender`}:</S.Text>
          <StyledOption.OptionsContainer>
            <S.Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={Gender.MALE}
              marginBottom={16}
            >
              <S.QuestionButton>{t`actions.male`}</S.QuestionButton>
            </S.Option>
            <S.Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={Gender.FEMALE}
            >
              <S.QuestionButton>{t`actions.female`}</S.QuestionButton>
            </S.Option>
          </StyledOption.OptionsContainer>
        </S.FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}
