import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import diet from 'assets/images/social-proof-diet.webp'

import { StyledSocialProofVariant6 as S } from './SocialProofVariant6.styles'

export const SocialProofVariant6: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Image src={diet} alt="diet" />
      <S.Column>
        <S.Title>{t`onboarding.socialProof.cholesterolDiet`}</S.Title>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
