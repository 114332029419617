export const PAGE_NAME = 'Weight question'
export const WEIGHT_KG_MIN = 45
export const WEIGHT_KG_MAX = 140
export const WEIGHT_LB_MIN = 99
export const WEIGHT_LB_MAX = 308

export const OPTION_VALUES = {
  FEW_KILOS_TO_LOSE: 'few_kilos_to_lose',
  SLIGHTLY_OVERWEIGHT: 'slightly_overweight',
  HEAVILY_OVERWEIGHT: 'heavily_overweight',
  HEALTHY_WEIGHT: 'healthy_weight',
  NOT_TO_ANSWER: 'not_to_answer',
}
