export const MAX_SYMPTOMS_SIZE = 2

const enum Goal {
  CONTROL_HEART_HEALTH = 'heart_rate',
  CONTROL_BLOOD_PRESSURE = 'blood_pressure',
  LOWER_CHOLESTEROL = 'lower_cholesterol',
}

export const GOAL_TO_MARKUP = {
  [Goal.CONTROL_HEART_HEALTH]: 'onboarding.mainGoal.controlHeartRate',
  [Goal.CONTROL_BLOOD_PRESSURE]: 'onboarding.mainGoal.controlBloodPressure',
  [Goal.LOWER_CHOLESTEROL]: 'onboarding.mainGoal.lowerCholesterol',
}

const enum Activity {
  LIGHT = 'light',
  MODERATE = 'moderate',
  HIGH = 'high',
}
export const ACTIVITY_TO_MARKUP = {
  [Activity.LIGHT]: 'summary.summaryHeartPlan.lightlyActive',
  [Activity.MODERATE]: 'summary.summaryHeartPlan.moderatelyActive',
  [Activity.HIGH]: 'summary.summaryHeartPlan.veryActive',
}
