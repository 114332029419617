import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

export const StyledSocialProofVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    min-height: 100dvh;
    background-color: #f2f3f5;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;
    padding-top: 24px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 343/471;
  `,
  Title: styled.h2`
    flex-grow: 1;
    margin-bottom: 8px;
    color: ${Color.GRAY_100};
    line-height: 40px;
    font-size: 32px;
    font-weight: 700;
  `,
  FixedContainer: styled.div`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 24px;

    button {
      margin: 0 auto;
    }
  `,
}
