import styled from 'styled-components'

import { Option } from 'components/Option'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledAgeRangeVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 32px 0 36px;
  `,
  Title: styled.h2`
    font-size: 32px;
    font-weight: 900;
    line-height: 36px;
    color: ${Color.DANGER};
    margin-bottom: 16px;
  `,
  Subtitle: styled.h3`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  `,
  Text: styled.p`
    margin-bottom: 16px;
    color: ${Color.LIGHT_TEXT};
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  `,
  OptionsContainer: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 15px;
    margin-bottom: 4px;

    input:checked + div > div {
      background-color: var(--checked-bg-color);
    }
  `,
  Option: styled(Option)`
    margin-bottom: 20px;
  `,
  Action: styled.div`
    position: relative;
    display: flex;
    aspect-ratio: 156/165;
  `,
  RangeImage: styled.img`
    width: 100%;
    height: 140px;
    position: absolute;
    top: -10px;
    z-index: 1;
  `,
  Answer: styled.div`
    --checked-bg-color: ${Color.DANGER};
    background-color: ${Color.WHITE};
    border-radius: 12px;
    position: relative;
    flex-basis: 100%;
    transition: background-color 0.1s ease-out;
  `,
  Range: styled.span`
    background-color: ${Color.DANGER};
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 12px 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: ${Color.WHITE};

    svg {
      margin-left: 8px;
    }
  `,
  AgreementText: styled.p`
    margin-bottom: 4px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  `,
  PoliciesLinksWrapper: styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-bottom: 16px;

    a {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
  Description: styled.div`
    color: ${Color.LIGHT_TEXT};
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  `,
}
