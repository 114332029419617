import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color } from 'root-constants/common'

type TTitleProps = {
  titleFontSize?: number
  titleLineHeight?: number
  marginBottom?: number
  titleColor?: string
}

type TWrapperProps = {
  wrapperPaddingTop?: number
}

type TColumnProps = {
  columnPaddingTop?: number
}

export const StyledRadioOptionPage = {
  Wrapper: styled.div<TWrapperProps>`
    ${basePageStyles};
    padding-top: ${({ wrapperPaddingTop }) => wrapperPaddingTop || 0}px;
  `,
  Column: styled.div<TColumnProps>`
    ${baseColumnStyles};
    padding-bottom: 13px;
    padding-top: ${({ columnPaddingTop }) => columnPaddingTop || 24}px;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: ${Color.GRAY_80};
    }
  `,
  Title: styled.h2<TTitleProps>`
    margin-bottom: ${({ marginBottom }) => marginBottom || 24}px;
    font-size: ${({ titleFontSize }) => `${titleFontSize || 24}px`};
    font-weight: 700;
    line-height: ${({ titleLineHeight }) => `${titleLineHeight || 32}px`};
    color: ${({ titleColor }) => titleColor || Color.DEFAULT_TEXT}};
  `,
  Subtitle: styled.h3`
    margin-bottom: 24px;
    color: ${Color.GRAY_80};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  `,
}
