import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import femaleImage from 'assets/images/habits-female.webp'
import maleImage from 'assets/images/habits-male.webp'

import { StyledSocialProofVariant4 as S } from './SocialProofVariant4.styles'

export const SocialProofVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isMale } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.socialProof.habits`}</S.Title>
        <S.ImageContainer>
          <img src={isMale ? maleImage : femaleImage} alt="doctor" />
        </S.ImageContainer>

        <S.FixedContainer>
          <Button
            type="button"
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </S.FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}
