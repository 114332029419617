import styled from 'styled-components'

import bullets from 'assets/images/red-bullets.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledSummaryHealthPlan = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    height: calc(100svh - ${HEADER_HEIGHT}px);
    padding: 48px 0;
  `,
  PersonalSummaryTitle: styled.h3`
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
  `,
  Logo: styled.img`
    margin-bottom: 16px;
  `,
  Description: styled.div`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
  `,
  Subtitle: styled.h3`
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;

    strong {
      font-size: 16px;
      font-weight: 900;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.highlightedText};
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 327/144;
  `,
  PlanTitle: styled.h3`
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    margin: 32px 0 12px;
  `,
  BenefitsList: styled.ul`
    margin-bottom: 27px;
    flex-grow: 1;

    li {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      padding-left: 24px;
      color: #565976;
      position: relative;

      :not(:last-child) {
        margin-bottom: 12px;
      }

      ::before {
        content: '';
        background-image: url(${bullets});
        background-position: center;
        color: ${({ theme }) => theme.colors.highlightedText};
        position: absolute;
        left: 8px;
        top: 0;
        width: 8px;
        height: 13px;
      }
    }
  `,
}
