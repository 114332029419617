import icon3 from 'assets/images/customized-reports-icon.png'
import phone3 from 'assets/images/customized-reports-phone.png'
import icon2 from 'assets/images/pulse-hrv-icon.png'
import phone2 from 'assets/images/pulse-hrv-phone.png'
import icon1 from 'assets/images/tracking-system-icon.png'
import phone1 from 'assets/images/tracking-system-phone.png'

export const ACCESS_DATA = [
  {
    image: phone1,
    icon: icon1,
    title: 'subscriptions.access.trackingSystem',
    text: 'subscriptions.access.logPressure',
  },
  {
    image: phone2,
    icon: icon2,
    title: 'subscriptions.access.pulseHrv',
    text: 'subscriptions.access.measurePulse',
  },
  {
    image: phone3,
    icon: icon3,
    title: 'subscriptions.access.customizedReports',
    text: 'subscriptions.access.monitorTrends',
  },
]
export const ACCESS_DATA_V2 = [
  {
    image: phone1,
    icon: icon1,
    title: 'subscriptions.access.trackingSystem',
    text: 'subscriptions.access.logHeartRate',
  },
  {
    image: phone2,
    icon: icon2,
    title: 'subscriptions.access.pulseHrv',
    text: 'subscriptions.access.measurePulse',
  },
  {
    image: phone3,
    icon: icon3,
    title: 'subscriptions.access.customizedReports',
    text: 'subscriptions.access.monitorTrends',
  },
]
