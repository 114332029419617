import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  selectCurrentVariantCohortToUse,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useProductId } from 'hooks/useProductId'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { PaymentFlows2 } from 'modules/payment/components/PaymentFlows'
import { NO_TRIAL_NO_DISCOUNT_COHORTS } from 'modules/payment/constants'
import {
  select3DSecureIframeUrl,
  selectIsPaymentFlowsShown,
  selectSubscriptionId,
} from 'modules/payment/redux/selects'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { replaceHistory } from 'browser-history'
import { PageId } from 'root-constants/pages'

import { PaymentFlows } from '../components/PaymentFlows'
import { StyledPayment as S } from './Payment.styles'

export const PaymentVariant1: React.FC<TPageProps> = () => {
  const { search } = useLocation()
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const screenName = useSelector(selectScreenName)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const productId = useProductId()
  const { goal } = useUserData()
  const { currentSubscriptionPageId } = useGetPageInfo()

  const hasTrialOrDiscountInfo = useMemo(
    () => !NO_TRIAL_NO_DISCOUNT_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  useLayoutEffect(() => {
    if (selectedSubscriptionId) return

    replaceHistory({
      pathname: currentSubscriptionPageId as PageId,
      search,
    })
  }, [currentSubscriptionPageId, search, selectedSubscriptionId])

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        goal,
        screenName,
        stripeAccountName,
        stripeAccountId,
      })
    }
  }, [goal, productId, screenName, stripeAccountId, stripeAccountName])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <StripePaymentProcessing />
          <S.Content>
            {hasTrialOrDiscountInfo ? <PaymentFlows /> : <PaymentFlows2 />}
          </S.Content>
        </>
      )}
      {!isPaymentFlowsShown && <Spinner />}
    </S.Wrapper>
  )
}
