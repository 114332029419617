import styled from 'styled-components'

import { basePageStyles } from 'common-styles'
import {
  Color,
  HEADER_HEIGHT,
  SENIOR_FLOW_HEADER_HEIGHT,
} from 'root-constants/common'

type TProps = {
  hasAlternativeStyles: boolean
}

export const StyledPreparingPlan = {
  Wrapper: styled.div<TProps>`
    ${basePageStyles};
    padding-top: ${({ hasAlternativeStyles }) =>
      hasAlternativeStyles
        ? `${SENIOR_FLOW_HEADER_HEIGHT}px`
        : `${HEADER_HEIGHT}px;`};
    justify-content: center;
    background: ${({ hasAlternativeStyles }) =>
      hasAlternativeStyles
        ? `linear-gradient(0deg, rgba(240, 242, 250, 0.40) 0%, #f0f2fa 30%, #d5f5ff 100%), #f2f3f5`
        : `linear-gradient(
        0deg,
        rgba(240, 242, 250, 0.4) 0%,
        #f0f2fa 30%,
        #afccff 100%
      ),
      #f0f2fa`};
    min-height: 100dvh;
  `,
  Text: styled.p<TProps>`
    font-size: 32px;
    font-weight: ${({ hasAlternativeStyles }) =>
      hasAlternativeStyles ? 700 : 900};
    color: ${({ hasAlternativeStyles }) =>
      hasAlternativeStyles ? Color.GRAY_100 : Color.DEFAULT_TEXT};
    line-height: 40px;
    text-align: center;
    max-width: 327px;
  `,
}
