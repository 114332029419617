import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PAGE_NAME, PROCESSING_DATA_3 } from 'pages/plan-processing/constants'

import { ProgressRounded } from 'components/ProgressRounded'

import questionIcon from 'assets/images/question-icon.png'

import { StyledPlanProcessingVariant3 as S } from './PlanProcessingVariant3.styles'

export const PlanProcessingVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [progressText, setProgressText] = useState('')

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  const { linearCounterValue } = useCounter(true, {
    duration: 10000,
    callback: handleContinue,
  })

  useEffect(() => {
    const loaderPoint = PROCESSING_DATA_3.find(
      ({ threshold }) => threshold === linearCounterValue,
    )

    if (loaderPoint) {
      setProgressText(loaderPoint.text)
    }
  }, [linearCounterValue])

  return (
    <S.Wrapper>
      <S.Column>
        <S.ProgressContainer>
          <ProgressRounded
            value={linearCounterValue}
            pathColor="#01cda1"
            trailColor="#f0f2fa"
          />
          <S.ProgressText key={progressText}>{t(progressText)}</S.ProgressText>
        </S.ProgressContainer>
        <S.Title>
          <Trans i18nKey="onboarding.processing.title4" />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.processing.subtitle3" />
        </S.Subtitle>
        <S.ContentWrapper>
          <S.Icon src={questionIcon} alt="icon" />
          <S.Question>{t`onboarding.processing.didYouKnow`}</S.Question>
          <S.Text>
            <Trans i18nKey="onboarding.processing.text" />
          </S.Text>
        </S.ContentWrapper>
      </S.Column>
    </S.Wrapper>
  )
}
