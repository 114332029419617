import allFoods from 'assets/images/foods-all.png'
import bread from 'assets/images/foods-bread.png'
import butter from 'assets/images/foods-butter.png'
import cheese from 'assets/images/foods-cheese.png'
import chickenWings from 'assets/images/foods-chicken-wings.png'
import coldCuts from 'assets/images/foods-cold-cuts.png'
import granolaBars from 'assets/images/foods-granola-bars.png'
import iceCream from 'assets/images/foods-ice-cream.png'
import milk from 'assets/images/foods-milk.png'
import noneOfAbove from 'assets/images/foods-none.png'
import potatoChips from 'assets/images/foods-potato-chips.png'
import redMeat from 'assets/images/foods-red-meat.png'
import spreads from 'assets/images/foods-spreads.png'
import sugar from 'assets/images/foods-sugar.png'

import { CheckboxCustomValue } from 'root-constants/common'

export const FOODS_OPTION_DATA = [
  { value: 'all', text: 'onboarding.foods.all', image: allFoods },
  { value: 'cold_cuts', text: 'onboarding.foods.coldCuts', image: coldCuts },
  {
    value: 'chicken_wings',
    text: 'onboarding.foods.chickenWings',
    image: chickenWings,
  },
  { value: 'red_meat', text: 'onboarding.foods.redMeat', image: redMeat },
  { value: 'milk', text: 'onboarding.foods.milk', image: milk },
  { value: 'butter', text: 'onboarding.foods.butter', image: butter },
  { value: 'cheese', text: 'onboarding.foods.cheese', image: cheese },
  { value: 'spread', text: 'onboarding.foods.spreads', image: spreads },
  { value: 'sugar', text: 'onboarding.foods.sugar', image: sugar },
  { value: 'ice_cream', text: 'onboarding.foods.iceCream', image: iceCream },
  {
    value: 'granola_bars',
    text: 'onboarding.foods.granolaBars',
    image: granolaBars,
  },
  { value: 'bread', text: 'onboarding.foods.bread', image: bread },
  { value: 'potato_chips', text: 'onboarding.foods.chips', image: potatoChips },
  {
    value: CheckboxCustomValue.NONE_OF_THE_ABOVE,
    text: 'onboarding.foods.none',
    image: noneOfAbove,
  },
]
