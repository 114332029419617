import styled from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'
import { SvgImage } from 'components/SvgImage'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TTextProps = {
  marginBottom?: number
}

export const StyledSummaryHealthProfile = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 0 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 24px;

    strong {
      color: ${Color.DANGER};
    }
  `,
  Subtitle: styled.h2`
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;

    strong {
      font-weight: 900;
      color: ${Color.DANGER};
    }
  `,
  DisclaimerContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 16px;
    background-color: rgba(205, 87, 75, 0.12);
  `,
  DisclaimerText: styled.p`
    color: #cd574b;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  DisclaimerIcon: styled(SvgImage)`
    align-self: flex-start;
    margin-right: 8px;
  `,
  Text: styled.p<TTextProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 16}px`};
    color: ${Color.LIGHT_TEXT};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 24px 0 40px;
    background-image: none;
  `,
}
