import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { COMMON_OPTION_VALUES, Language } from 'root-constants/common'

import { StyledIrregularitiesInHR as S } from './IrregularitiesInHR.styles'

const PAGE_NAME = 'Irregularities in HR'

export const IrregularitiesInHRVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.irregularitiesInHr.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.irregularitiesInHr.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.OFTEN}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.often`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.SOMETIMES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.sometimes`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.RARELY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.rarely`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.NEVER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.never`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
