import React from 'react'

import { TPageProps } from 'models/common.model'

import { KitRadioOptionPage } from 'pages/kit-options-page'

import { OPTION_VALUES_DAILY_WATER_2, TITLE_PATH } from './constants'

export const DailyWaterVariant2: React.FC<TPageProps> = (props) => (
  <KitRadioOptionPage
    {...props}
    options={OPTION_VALUES_DAILY_WATER_2}
    titlePath={TITLE_PATH}
  />
)
