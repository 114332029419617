import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'root-constants/pages'

import { StyledBloodPressureGraphVariant4 as S } from './BloodPressureGraphVariant4.styles'
import { IMAGES } from './constants'

export const BloodPressureGraphVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const bloodPressure = userAnswers?.[CustomPageId.BLOOD_PRESSURE] as string

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.bloodPressureGraph.title`}</S.Title>

        <S.ImageContainer>
          <img src={IMAGES[bloodPressure]} alt="graph" />
        </S.ImageContainer>

        <S.StickyButtonContainer>
          <Button
            type="button"
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
