import { StripeFieldName } from 'root-constants/common'

export const getDefaultPaymentErrorsState = (): Record<
  StripeFieldName,
  {
    error: string
    isTouched: boolean
    isShown: boolean
    isComplete: boolean
  }
> => ({
  [StripeFieldName.NAME]: {
    error: '',
    isTouched: true,
    isShown: true,
    isComplete: true,
  },
  [StripeFieldName.NUMBER]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.EXPIRY]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.CVC]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
})
