import styled from 'styled-components'

export const StyledStickyButtonContainer = {
  Wrapper: styled.div`
    position: sticky;
    z-index: 1;
    width: 100%;
    max-width: 360px;
    bottom: 0;
    margin: 0 auto;
    padding: 24px 0;
    background-image: ${({ theme }) =>
      theme.colors.stickyButtonBackgroundImage};
  `,
}
