import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledSocialProofDehydrationVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    background: linear-gradient(
        0deg,
        rgba(240, 242, 250, 0.4) 0%,
        #f0f2fa 30%,
        #d5e4ff 100%
      ),
      #f0f2fa;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 64px 0 0;
  `,
  Text: styled.p`
    margin-bottom: 50px;
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
  `,
}
