import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import graph4MonthsImage from 'assets/images/graph-4-months-2.svg'

import { StyledCongratulationsHeartHealth as S } from './CongratulationsHeartHealth.styles'

export const CongratulationsHeartHealth: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t`summary.congratulations`}</S.Title>
      <S.Text>
        <Trans
          i18nKey="summary.congratulationsHeartHealth.subtitle"
          components={{ em: <em /> }}
        />
      </S.Text>
      <S.MetricsWrapper>
        <S.Metrics>
          <S.MetricsValue>{t`summary.congratulationsHeartHealth.heartHealth`}</S.MetricsValue>
          <S.MetricsDate>
            {t('summary.byDate', {
              date: dayjs().add(3, 'month').format('MMMM YYYY'),
            })}
          </S.MetricsDate>
        </S.Metrics>
        <S.GraphImage height={137} src={graph4MonthsImage} alt="graph" />
        <S.GraphMonths>
          <span>{dayjs().format('MMM')}</span>
          <span>{dayjs().add(1, 'month').format('MMM')}</span>
          <span>{dayjs().add(2, 'month').format('MMM')}</span>
          <span>{dayjs().add(3, 'month').format('MMM')}</span>
        </S.GraphMonths>
      </S.MetricsWrapper>
    </S.Wrapper>
  )
}
