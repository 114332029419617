import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { PageId } from 'root-constants/pages'

export const useGetPageInfo = () => {
  const { pathname } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)

  const currentPageId = useMemo(() => pathname.slice(1) as PageId, [pathname])

  const currentPageIndex = useMemo(
    () => steps.findIndex(({ id }) => id === currentPageId),
    [currentPageId, steps],
  )

  const isPostPaymentPage = useMemo(
    () =>
      [PageId.UPSELL_1, PageId.ACCOUNT, PageId.DOWNLOAD].includes(
        currentPageId,
      ),
    [currentPageId],
  )

  const { hasHeader, hasProgressbar } = useMemo(
    () => ({
      hasHeader: steps[currentPageIndex]?.hasHeader || isPostPaymentPage,
      hasProgressbar: steps[currentPageIndex]?.hasProgressBar,
    }),
    [currentPageIndex, isPostPaymentPage, steps],
  )

  const currentSubscriptionPageId = useMemo(
    () => steps.find((page) => page.isSubscriptions)?.id as PageId,
    [steps],
  )

  const currentPaymentPageId = useMemo(
    () => steps.find((page) => page.isPayment)?.id,
    [steps],
  )

  const isPaymentPage = useMemo(
    () => currentPaymentPageId === currentPageId,
    [currentPageId, currentPaymentPageId],
  )

  return {
    currentPageId,
    currentPageIndex,
    hasHeader,
    hasProgressbar,
    currentSubscriptionPageId,
    currentPaymentPageId,
    isPaymentPage,
    steps,
  }
}
