import styled from 'styled-components'

export const StyledHeartLevel = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background-color: white;
  `,
  Img: styled.img`
    flex-shrink: 0;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  Title: styled.span`
    font-size: 18px;
    font-weight: 900;
    line-height: 23px;
    color: #01cda1;
  `,
  Text: styled.span`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  `,
}
