import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { SvgImage } from 'components/SvgImage'

import burgerMenuIcon from 'assets/images/burger-menu-2.svg'

import { Color } from 'root-constants/common'

export const StyledHeaderV3 = {
  Wrapper: styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: ${Color.WHITE};
    box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.25) inset;
  `,
  Header: styled.header`
    position: relative;
    width: 100%;
    padding: 8px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ArrowButton: styled(SvgImage)`
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    cursor: pointer;
  `,
  AppNameLogo: styled.img`
    height: 36px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    & > button {
      background-image: url(${burgerMenuIcon});
    }

    nav {
      background-color: ${Color.WHITE};
    }
  `,
}
