export const OPTION_VALUES = {
  REGULARLY: 'regularly',
  NOT_REGULARLY: 'not_regularly',
  OCCASIONALLY: 'occasionally',
  NO: 'no',
}
export const OPTION_VALUES_BLOOD_PRESSURE_4 = [
  {
    value: OPTION_VALUES.REGULARLY,
    text: 'onboarding.monitorBloodPressure.regularly2',
  },
  {
    value: OPTION_VALUES.NO,
    text: 'onboarding.monitorBloodPressure.notRegularly',
  },
]
export const TITLE_PATH = 'onboarding.monitorBloodPressure.title2'
