import styled from 'styled-components'

import checkCircle from 'assets/images/check-circle-green.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

type TImageProps = {
  marginBottom?: number
}

export const StyledBetterHeartLife = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    align-items: center;
    padding-bottom: 40px;
    padding-top: 24px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 24px;
  `,
  BenefitsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 12px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
    margin-bottom: 32px;
  `,
  BenefitsList: styled.ul`
    li:last-child {
      margin-bottom: 0;
    }

    li {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      padding-left: 30px;
      color: ${({ theme }) => theme.colors.primaryText};
      position: relative;
      margin-bottom: 12px;

      &::first-letter {
        text-transform: capitalize;
      }

      strong {
        font-weight: 900;
      }

      ::before {
        content: '';
        background-image: url(${checkCircle});
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 22px;
        height: 22px;
      }
    }
  `,
  Image: styled.img<TImageProps>`
    aspect-ratio: 268/162;
    max-width: 268px;
    width: 100%;
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 32}px`};
  `,
}
