import styled, { css } from 'styled-components'

import { Option } from 'components/Option'

import {
  ModalContainerBase,
  ModalWrapperBase,
  baseColumnStyles,
  basePageStyles,
} from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

type TTextProps = {
  color: string
}

const commonButtonStyles = css`
  text-align: center;
  padding: 16px 20px;
  border-radius: 30px;
  color: ${Color.WHITE};
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  cursor: pointer;
`
export const StyledCardiacRiskVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    background-color: #f2f3f5;
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;
    padding: 24px 0;
  `,
  Content: styled.div`
    flex-grow: 1;
  `,
  Title: styled.h1`
    margin-bottom: 24px;
    color: ${Color.GRAY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  AnimationWrapper: styled.div`
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
  `,
  AnimationTitle: styled.p`
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.GRAY_100};
  `,
  VariantsWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
  `,
  Text: styled.p<TTextProps>`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${({ color }) => color};
  `,
  Modal: styled.div`
    ${ModalWrapperBase};
    background-color: rgba(0, 0, 0, 0.5);
  `,
  Container: styled.div`
    ${ModalContainerBase};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 20px;
    width: 328px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  ContainerTitle: styled.p`
    margin-bottom: 32px;
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `,
  Option: styled(Option)`
    width: 100%;
  `,
  YesButton: styled.div`
    ${commonButtonStyles};
    background: #a9cd50;
  `,
  NoButton: styled.div`
    ${commonButtonStyles};
    background: #f2f3f5;
    color: ${Color.BLUE_DARK};
  `,
}
