import styled, { css } from 'styled-components'

import { HEADER_HEIGHT } from 'root-constants/common'

const progressValueStyles = css`
  background-color: #01cc9c;
  border-radius: 2px;
  transition: all 0.2s ease-out;
`

export const StyledProgressBar = {
  Wrapper: styled.div`
    width: 100%;
    position: absolute;
    top: ${HEADER_HEIGHT}px;
    left: 50%;
    transform: translateX(-50%);
  `,
  Progress: styled.progress`
    width: 100%;
    height: 2px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 2px;
    overflow: hidden;
    background-color: #bcc7dd;

    &::-webkit-progress-bar {
      background-color: #bcc7dd;
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
