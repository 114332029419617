import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { useUserData } from 'hooks/useUserData'

import { SvgImage } from 'components/SvgImage'

import highHeartAttackRisk from 'assets/images/high-heart-attack-risk.png'
import mediumHeartAttackRisk from 'assets/images/medium-heart-attack-risk.png'
import battery from 'assets/images/sprite/battery.svg'
import faceFrown from 'assets/images/sprite/face-frown.svg'
import heartRate from 'assets/images/sprite/heart-rate.svg'
import hourGlass from 'assets/images/sprite/hourglass.svg'

import { COMMON_OPTION_VALUES } from 'root-constants/common'
import { CustomPageId, PageId } from 'root-constants/pages'

import { StyledHealthStats as S } from './HealthStats.styles'
import { BLOOD_PRESSURE_LEVELS, ENERGY_LEVELS } from './constants'

export const HealthStats: React.FC = () => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)

  const { age } = useUserData()

  return (
    <S.Wrapper>
      <S.Content>
        <S.StatsContainer>
          <S.LeftStatsItem justifyContent="flex-end">
            <S.StatsIconContainer>
              <SvgImage svg={heartRate} width={20} />
            </S.StatsIconContainer>
            <S.StatsTitle>{t`summary.summaryHealthProfile.bp`}</S.StatsTitle>
            <S.StatsValue>
              {t(
                BLOOD_PRESSURE_LEVELS[
                  answers?.[CustomPageId.BLOOD_PRESSURE] as string
                ],
              )}
            </S.StatsValue>
          </S.LeftStatsItem>

          <S.RightStatsItem>
            <S.StatsIconContainer>
              <SvgImage svg={battery} width={20} />
            </S.StatsIconContainer>
            <S.StatsTitle>{t`summary.summaryHealthProfile.energy`}</S.StatsTitle>
            <S.StatsValue>
              {ENERGY_LEVELS[answers?.[PageId.SLEEP_2] as string]}
            </S.StatsValue>
          </S.RightStatsItem>

          <S.LeftStatsItem>
            <S.StatsIconContainer>
              <SvgImage svg={hourGlass} width={20} />
            </S.StatsIconContainer>
            <S.StatsTitle>{t`summary.summaryHealthProfile.age`}</S.StatsTitle>
            <S.StatsValue>
              {t('summary.summaryHealthProfile.ageValue', { age })}
            </S.StatsValue>
          </S.LeftStatsItem>

          <S.RightStatsItem justifyContent="flex-end">
            <S.StatsIconContainer>
              <SvgImage svg={faceFrown} width={20} />
            </S.StatsIconContainer>
            <S.StatsTitle>{t`summary.summaryHealthProfile.stress`}</S.StatsTitle>
            <S.StatsValue>73%</S.StatsValue>
          </S.RightStatsItem>
        </S.StatsContainer>
      </S.Content>
      <S.HeartAttackContainer>
        <S.HeartAttackText>{t`summary.summaryHealthProfile.heartAttackTitle`}</S.HeartAttackText>
        <img
          src={
            answers?.[PageId.CARDIAC_RISK_1] === COMMON_OPTION_VALUES.YES
              ? mediumHeartAttackRisk
              : highHeartAttackRisk
          }
          alt="heart-attack-risk"
        />
      </S.HeartAttackContainer>
    </S.Wrapper>
  )
}
