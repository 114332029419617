import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantSteps,
  selectHasUpsellOffer,
} from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionTrialPeriod,
  selectUserStatus,
} from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { TrialPeriod } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const useUserStatus = (): string => {
  const steps = useSelector(selectCurrentVariantSteps)
  const userStatus = useSelector(selectUserStatus)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const userTrialPeriod = useSelector(selectGeneralSubscriptionTrialPeriod)

  const { currentSubscriptionPageId } = useGetPageInfo()

  return useMemo(() => {
    if (userStatus?.account.hasAccount) {
      return PageId.DOWNLOAD
    }

    if (
      userStatus?.hasSubscription &&
      hasUpsellOffer &&
      userStatus.upsell.isUpsellAvailable &&
      !userStatus.upsell.isUpsellPurchased &&
      !userStatus?.config.isCancelOfferPurchased &&
      !userStatus?.config.isCancelOfferTried &&
      userTrialPeriod !== TrialPeriod.ONE_WEEK
    ) {
      return PageId.UPSELL_1
    }

    if (userStatus?.hasSubscription) {
      return PageId.ACCOUNT
    }

    if (userStatus?.email.hasEmail) {
      return currentSubscriptionPageId || steps[0].id
    }

    return ''
  }, [
    userStatus,
    hasUpsellOffer,
    userTrialPeriod,
    currentSubscriptionPageId,
    steps,
  ])
}
