export const PAGE_NAME = 'Overall condition question'
export const OPTION_VALUES = {
  OVERWEIGHT: 'overweight',
  BARELY_ACTIVE: 'barely_active',
  SMOKE: 'smoke',
  ALCOHOL: 'drink_alcohol_daily',
  DIET: 'eat_a_poor_diet',
  STRESS: 'stress_a_lot',
  NONE: 'none_of_the_above',
}
