import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'components/Option'

import { Language } from 'root-constants/common'

import { StyledStatementVariant2 as S } from './StatementVariant2.styles'
import { OPTIONS_NUMBER, STATEMENT_V2_DATA } from './constants'

export const StatementVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t(STATEMENT_V2_DATA[pageId].title, { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.TextContainer>
          <S.Title>{t(STATEMENT_V2_DATA[pageId].title)}</S.Title>
          <S.Subtitle>{t(STATEMENT_V2_DATA[pageId].subtitle)}</S.Subtitle>
        </S.TextContainer>
        <S.OptionsContainer>
          {Array.from({ length: OPTIONS_NUMBER })
            .map((_, i) => i + 1)
            .map((number) => (
              <S.Option
                {...optionProps}
                value={number.toString()}
                disabled={isAnswersDisabled}
                key={number}
              >
                <S.QuestionButton>{number}</S.QuestionButton>
              </S.Option>
            ))}
        </S.OptionsContainer>
        <S.Description>
          <span>{t(STATEMENT_V2_DATA[pageId].lowestScaleText)}</span>
          <span>{t(STATEMENT_V2_DATA[pageId].highestScaleText)}</span>
        </S.Description>
      </S.Column>
    </S.Wrapper>
  )
}
