import styled from 'styled-components'

import bgImage from 'assets/images/blood-pressure-graph-bg.jpg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledBloodPressureGraphVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 46px 0 40px;
    background-image: url(${bgImage});
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    align-items: center;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 24px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 22px;
    line-height: 30px;
  `,
  GraphContainer: styled.div`
    width: 100%;
    margin-bottom: 12px;
    padding: 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
    aspect-ratio: 327/316;
  `,
  GraphTitle: styled.h3`
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 17px;
    color: rgba(86, 89, 118, 0.8);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
}
