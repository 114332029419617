import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSummaryAppScreens = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100svh - ${HEADER_HEIGHT}px);
    padding: 48px 0;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 8px;
  `,
  Subtitle: styled.h3`
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  SwiperContainer: styled.div`
    flex-grow: 1;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    .swiper-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 38px;
    }

    .swiper-slide {
      max-width: 154px;
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background-color: #b2b8cd;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: ${Color.DANGER};
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
}
