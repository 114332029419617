import { IFormattedTime } from 'modules/subscriptions/types'

export const getDecoratedTimerValue = (
  valueInSeconds: number,
): IFormattedTime => {
  const minutes = Math.trunc(valueInSeconds / 60)
  const seconds = valueInSeconds - minutes * 60
  const get2DigitValue = (value: number): string =>
    value.toString().padStart(2, '0')

  return {
    minutes: get2DigitValue(minutes),
    seconds: get2DigitValue(seconds),
  }
}
