import React from 'react'
import { useTranslation } from 'react-i18next'

import guide from 'assets/images/upsell-guide.png'
import management from 'assets/images/upsell-management.png'
import playlist from 'assets/images/upsell-playlist.png'

import { StyledUpsellBenefitsDescription as S } from './UpsellBenefitsDescription.styles'

export const UpsellBenefitsDescription: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t`upsell.benefitsDescription.title`}</S.Title>
      <S.Subtitle>{t`upsell.benefitsDescription.subtitle`}</S.Subtitle>

      <S.Card>
        <S.Image src={playlist} alt="playlist" />
        <S.CardTitle>{t`upsell.benefitsDescription.playlists.title`}</S.CardTitle>
        <S.Description>{t`upsell.benefitsDescription.playlists.description`}</S.Description>
      </S.Card>

      <S.Card>
        <S.Image src={management} alt="management" />
        <S.CardTitle>{t`upsell.benefitsDescription.management.title`}</S.CardTitle>
        <S.Description>{t`upsell.benefitsDescription.management.description`}</S.Description>
      </S.Card>

      <S.Card>
        <S.Image src={guide} alt="guide" />
        <S.CardTitle>{t`upsell.benefitsDescription.guide.title`}</S.CardTitle>
        <S.Description>{t`upsell.benefitsDescription.guide.description`}</S.Description>
      </S.Card>
    </S.Wrapper>
  )
}
