import styled from 'styled-components'

import { StickyButton } from 'components/StickyButton'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledSocialProofIrregularities = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 48px 0 40px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
  `,
  Text: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 24px;

    strong {
      font-weight: 800;
      color: #5f96f7;
    }
  `,
  Img: styled.img`
    margin-bottom: 40px;
  `,
  StickyButton: styled(StickyButton)`
    @media (max-height: 620px) {
      position: static;
    }
  `,
}
