import { AdditionalGoal } from 'root-constants/common'

export const MAX_ADDITIONAL_GOALS_SIZE = 2
export const MAX_SYMPTOMS_SIZE = 3

export const ADDITIONAL_GOALS_TO_MARKUP = {
  [AdditionalGoal.BLOOD_PRESSURE]:
    'onboarding.additionalGoals.achieveBloodPressure',
  [AdditionalGoal.REDUCE_STRESS]: 'onboarding.additionalGoals.reduceStress',
  [AdditionalGoal.SLEEP_BETTER]: 'onboarding.additionalGoals.improveSleep',
  [AdditionalGoal.HEART_HEALTHY_DIET]:
    'onboarding.additionalGoals.eatHealthyDiet',
  [AdditionalGoal.PHYSICAL_ACTIVITY]:
    'onboarding.additionalGoals.stayPhysicallyActive',
}
