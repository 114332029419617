import { MAIN_GOAL_OPTION_VALUES } from 'root-constants/common'

export const PERSONAL_PLAN_SUBTITLE_MAP = {
  [MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH]:
    'onboarding.mainGoal.controlHeartHealth',
  [MAIN_GOAL_OPTION_VALUES.CONTROL_BLOOD_PRESSURE]:
    'onboarding.mainGoal.controlBloodPressure',
  [MAIN_GOAL_OPTION_VALUES.REDUCE_STRESS]: 'onboarding.mainGoal.reduceStress',
  [MAIN_GOAL_OPTION_VALUES.SLEEP_BETTER]: 'onboarding.mainGoal.sleepBetter',
  [MAIN_GOAL_OPTION_VALUES.REDUCE_MEDICATION_INTAKE]:
    'onboarding.mainGoal.reduceMedicationIntake',
}
export const BENEFITS_DATA_HEART_HEALTH = [
  { text: 'summary.heartHealth.improveHeartHealth' },
  { text: 'summary.heartHealth.controlHeartDisease' },
  { text: 'summary.heartHealth.developHabits' },
]

export const BENEFITS_DATA_REDUCE_STRESS = [
  { text: 'summary.heartHealth.reduceStress' },
  { text: 'summary.heartHealth.controlHeartDisease' },
  { text: 'summary.heartHealth.developHabits2' },
]
