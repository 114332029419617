import styled from 'styled-components'

import { BASE_COLUMN_WIDTH } from 'root-constants/common'

import { Button } from '../Button'

export const StyledHeaderWithButton = {
  HeaderWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 36, 95, 0.05);
    position: fixed;
    z-index: 9;
  `,
  Header: styled.header`
    width: ${BASE_COLUMN_WIDTH}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  `,
  Button: styled(Button)`
    width: 122px;
    height: 38px;
    line-height: 38px;
    margin: 0;
    padding: 0 16px;
    border-radius: 8px;
  `,
}
