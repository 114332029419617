import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { Language } from 'root-constants/common'

import { StyledAlcoholVariant1 as S } from './AlcoholVariant1.styles'

const PAGE_NAME = 'Alcohol'
const OPTION_VALUES = {
  DONT_DRINK: 'do_not_drink',
  LESS_THAN_THREE_DRINKS: 'less_than_3',
  MORE_THAN_THREE_DRINKS: 'more_than_3',
  MORE_THAN_SEVEN_DRINKS: 'more_than_7',
  MORE_THAN_TEN_DRINKS: 'more_than_10',
}

export const AlcoholVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.alcohol.title', { lng: Language.EN }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      if (
        answer === OPTION_VALUES.DONT_DRINK ||
        answer === OPTION_VALUES.LESS_THAN_THREE_DRINKS
      ) {
        goTo(alternativePagePath)
        return
      }
      goTo(nextPagePath)
    },
    [t, pageId, dispatch, nextPagePath, alternativePagePath],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.alcohol.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DONT_DRINK}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.alcohol.dontDrink`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.LESS_THAN_THREE_DRINKS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.alcohol.lessThanThreeDrinks`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.MORE_THAN_THREE_DRINKS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.alcohol.moreThanThreeDrinks`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.MORE_THAN_SEVEN_DRINKS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.alcohol.moreThanSevenDrinks`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.MORE_THAN_TEN_DRINKS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.alcohol.moreThanTenDrinks`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
