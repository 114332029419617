import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TImageProps = {
  hasAlternativeStyles?: boolean
}
export const StyledHeartAgeVariant1 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 20px 0 #dfe3f3;
    max-width: ${({ theme }) => theme.common.columnWidth};
    width: 100%;
    margin-bottom: 48px;
    border-radius: 16px;
  `,
  ImageContainer: styled.div<TImageProps>`
    max-width: ${({ theme }) => theme.common.columnWidth};
    width: 100%;
    aspect-ratio: ${({ hasAlternativeStyles }) =>
      hasAlternativeStyles ? 343 / 276 : 327 / 276};

    img {
      max-height: ${({ hasAlternativeStyles }) =>
        hasAlternativeStyles ? 'auto' : 276}px;
      border-radius: 16px 16px 0 0;
    }
  `,
  HeartAgeDataWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    border-radius: 0 0 16px 16px;
    border-top: 1px solid #e5e7eb;
    background-color: ${Color.WHITE};
    align-items: center;
    padding: 16px;
  `,
  OldHeartAge: styled.div`
    padding: 0 16px 16px 0;
    border-right: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
    max-width: 147px;
    width: 100%;
  `,
  NewHeartAge: styled.div`
    padding: 0 0 16px 16px;
    border-bottom: 1px solid #e5e7eb;
    max-width: 147px;
    width: 100%;
  `,
  OldHeartRisk: styled.div`
    padding: 16px 16px 0 0;
    border-right: 1px solid #e5e7eb;
    max-width: 147px;
    width: 100%;
  `,
  NewHeartRisk: styled.div`
    padding: 16px 0 0 16px;
    max-width: 147px;
    width: 100%;
  `,
  Title: styled.h3`
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.secondaryText};
  `,
  Text: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primaryText};
  `,
}
