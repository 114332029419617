import styled from 'styled-components'

import { Button } from 'components/Button'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TTextProps = {
  marginBottom?: number
}

export const StyledIntroVariant6 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 48px 0 40px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 32px;
  `,
  Text: styled.p<TTextProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 16}px`};
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    strong {
      font-weight: 900;
      color: ${Color.DANGER};
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    margin-bottom: 32px;
    aspect-ratio: 327/184;
  `,
  Button: styled(Button)`
    margin-bottom: 12px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  `,
  AgreementText: styled.p`
    margin-bottom: 4px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
  `,
  PoliciesLinksWrapper: styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-bottom: 16px;

    a {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
  Description: styled.div`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  `,
}
