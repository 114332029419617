import { DefaultTheme } from 'styled-components'

export const COMMON_THEME: DefaultTheme = {
  common: {
    columnWidth: '328px',
    planItemBorderRadius: '12px',
    planItemPeriodText: '10px',
    planItemFontSize: '16px',
    planItemLineHeight: '22px',
    planItemFontWeight: '700',
    swiperPaginationBulletSize: '6px',
    swiperPaginationBulletMargin: '0 3px',
    moneyBackImageMargin: '48px',
    moneyBackLinksFontWeight: '400',
    moneyBackTextSize: '13px',
    optionFontSize: '16px',
    optionLineHeight: '22px',
    textAlign: 'center',
    optionPadding: '17px 24px',
    subtitleBottomPadding: '40px',
    titleFontSize: '22px',
    titleLineHeight: '30px',
    subtitleFontSize: '13px',
    subtitleLineHeight: '22px',
    subtitleMarginBottom: '40px',
    wrapperPaddingTop: '48px',
    columnPaddingTop: '48px',
    columnAlignItems: 'center',
  },
  arrangements: {
    verticalAlignment: 'flex-start',
  },
  colors: {
    primaryBorder: '#fff',
    buttonBackground: 'transparent',
    buttonBackgroundImage: 'linear-gradient(180deg, #69bcff, #2573ff)',
    disabledButtonBackground: 'transparent',
    disabledButtonBackgroundImage: 'linear-gradient(180deg, #bddef9, #a4c3f9)',
    measurementSystemBackground: '#ebedf5',
    inputBorder: 'transparent',
    activeInputBorder: '#3588ff',
    optionBorder: '#fff',
    checkedOptionBorder: '#3588ff',
    checkedOptionWithCheckboxBorder: '#3588ff',
    checkIcon: '#199cf3',
    optionBackground: '#fff',
    checkedOptionBackground: '#fff',
    checkedOptionText: '#2c2f54',
    highlightedText: '#5f96f7',
    primaryText: '#2c2f54',
    secondaryText: '#5f96f7',
    validationText: '#fd417f',
    popoverBackground: '#fff',
    popoverText: '#565976',
    priceColor: '#81848c',
    pricePeriodColor: '#565976',
    oldPrice: '#fd417f',
    discountBadge: '#fd417f',
    placeholderTextColor: '#56597680',
    timerBackground: '#5f96f7',
    timerText: '#fff',
    timerDiscountText: 'rgba(255, 255, 255, 0.5)',
    planItemText: '#2c2f54',
    planItem: '#3588ff',
    swiperPaginationBullet: '#d8dbe8',
    swiperPaginationBulletActive: '#b1b8d7',
    moneyBackImage: '#5f96f7',
    stickyButtonBackgroundImage:
      'linear-gradient(180deg,rgba(255, 255, 255, 0) 0%,#f0f2fa 24%)',
    burgerLinksColor: '#153e83',
  },
  shadows: {
    optionShadow: '0 8px 12px rgba(152, 159, 187, 0.08)',
    primaryShadow: '0 8px 12px rgba(152, 159, 187, 0.08)',
    buttonShadow: '0 8px 16px rgba(54, 132, 255, 0.2)',
  },
}

export const DANGER_THEME: DefaultTheme = {
  common: {
    columnWidth: '328px',
    swiperPaginationBulletSize: '6px',
    swiperPaginationBulletMargin: '0 3px',
    planItemFontSize: '16px',
    planItemLineHeight: '22px',
    planItemFontWeight: '700',
    optionFontSize: '16px',
    optionLineHeight: '22px',
    textAlign: 'center',
    optionPadding: '17px 24px',
    subtitleBottomPadding: '40px',
    titleFontSize: '22px',
    titleLineHeight: '30px',
    subtitleFontSize: '13px',
    subtitleLineHeight: '22px',
    subtitleMarginBottom: '40px',
    wrapperPaddingTop: '48px',
    columnPaddingTop: '48px',
    columnAlignItems: 'center',
  },
  arrangements: {
    verticalAlignment: 'space-between',
  },
  colors: {
    primaryBorder: '#b2b8cd',
    buttonBackground: '#ff0000',
    buttonBackgroundImage: 'none',
    disabledButtonBackground: '#ffcccc',
    disabledButtonBackgroundImage: 'none',
    measurementSystemBackground: '#f0f2fa',
    inputBorder: '#b2b8cd',
    activeInputBorder: '#153e83',
    optionBorder: '#b2b8cd',
    checkedOptionBorder: '#ff0000',
    checkedOptionWithCheckboxBorder: '#b2b8cd',
    checkIcon: '#ff0000',
    optionBackground: '#fff',
    checkedOptionBackground: '#ff0000',
    checkedOptionText: '#fff',
    highlightedText: '#ff0000',
    primaryText: '#565976',
    secondaryText: '#2c2f54',
    validationText: '#fe5959',
    popoverBackground: '#2c2f54',
    popoverText: '#fff',
    priceColor: '#81848c',
    pricePeriodColor: '#565976',
    oldPrice: '#ff0000',
    discountBadge: '#3588ff',
    placeholderTextColor: '#56597680',
    planItemText: '#2c2f54',
    planItem: '#3588ff',
    swiperPaginationBullet: '#d8dbe8',
    swiperPaginationBulletActive: '#b1b8d7',
    stickyButtonBackgroundImage:
      'linear-gradient(180deg,rgba(255, 255, 255, 0) 0%,#fff 24%)',
    burgerLinksColor: '#153e83',
  },
  shadows: {
    optionShadow: 'none',
    primaryShadow: 'none',
    buttonShadow: 'none',
  },
}

export const SENIOR_THEME: DefaultTheme = {
  common: {
    columnWidth: '328px',
    planItemBorderRadius: '16px',
    planItemPeriodText: '12px',
    planItemFontSize: '16px',
    planItemLineHeight: '22px',
    planItemFontWeight: '700',
    swiperPaginationBulletSize: '10px',
    swiperPaginationBulletMargin: '0 6px',
    moneyBackImageMargin: '32px',
    moneyBackLinksFontWeight: '500',
    moneyBackTextSize: '14px',
    optionFontSize: '16px',
    optionLineHeight: '22px',
    textAlign: 'center',
    optionPadding: '17px 24px',
    subtitleBottomPadding: '40px',
    titleFontSize: '22px',
    titleLineHeight: '30px',
    subtitleFontSize: '16px',
    subtitleLineHeight: '22px',
    subtitleMarginBottom: '40px',
    wrapperPaddingTop: '48px',
    columnPaddingTop: '48px',
    columnAlignItems: 'center',
  },
  colors: {
    primaryBorder: '#fff',
    buttonBackground: '#ff0000',
    buttonBackgroundImage: 'none',
    disabledButtonBackground: '#ffcccc',
    disabledButtonBackgroundImage: 'none',
    inputBorder: 'transparent',
    activeInputBorder: '#153e83',
    optionBorder: '#fff',
    checkedOptionBorder: '#3588ff',
    checkedOptionWithCheckboxBorder: '#3588ff',
    checkIcon: '#199cf3',
    optionBackground: '#fff',
    checkedOptionBackground: '#fff',
    checkedOptionText: '#2c2f54',
    highlightedText: '#ff0000',
    primaryText: '#565976',
    secondaryText: '#2c2f54',
    validationText: '#fe5959',
    popoverBackground: '#2c2f54',
    popoverText: '#fff',
    timerBackground: 'linear-gradient(0deg, #fde047 0%, #fde047 100%), #3588ff',
    timerText: '#2c2f54',
    timerDiscountText: '#2c2f54',
    priceColor: '#81848c',
    pricePeriodColor: '#565976',
    planItemText: '#2c2f54',
    planItem: '#ff0000',
    swiperPaginationBullet: '#b2b8cd',
    swiperPaginationBulletActive: '#ff0000',
    moneyBackImage: '#565976',
    placeholderTextColor: '#565976',
    stickyButtonBackgroundImage:
      'linear-gradient(180deg,rgba(255, 255, 255, 0) 0%,#f0f2fa 24%)',
    burgerLinksColor: '#153e83',
  },
  shadows: {
    optionShadow: '0 8px 12px rgba(152, 159, 187, 0.08)',
    primaryShadow: 'none',
    buttonShadow: 'none',
  },
}
export const SENIOR_THEME_V2 = {
  ...SENIOR_THEME,
  common: {
    ...SENIOR_THEME.common,
    optionFontSize: '18px',
    optionLineHeight: '24px',
    textAlign: 'start',
    optionPadding: '19px 24px',
    subtitleBottomPadding: '24px',
    titleFontSize: '32px',
    titleLineHeight: '40px',
    subtitleFontSize: '18px',
    subtitleLineHeight: '24px',
    subtitleMarginBottom: '24px',
    wrapperPaddingTop: '24px',
    columnPaddingTop: '0',
    columnAlignItems: 'start',
  },
  colors: {
    ...SENIOR_THEME.colors,
    burgerLinksColor: '#565976',
  },
}

export const SENIOR_THEME_V3 = {
  ...SENIOR_THEME,
  common: {
    ...SENIOR_THEME.common,
    columnWidth: '343px',
    optionFontSize: '17px',
    optionLineHeight: '24px',
    planItemFontSize: '17px',
    planItemLineHeight: '24px',
    planItemFontWeight: '600',
    textAlign: 'start',
    optionPadding: '0 16px',
    subtitleBottomPadding: '24px',
    titleFontSize: '24px',
    titleLineHeight: '32px',
    subtitleFontSize: '17px',
    subtitleLineHeight: '24px',
    subtitleMarginBottom: '24px',
    wrapperPaddingTop: '24px',
    columnPaddingTop: '0',
    columnAlignItems: 'start',
    moneyBackTextSize: '15px',
    moneyBackLinksFontWeight: '400',
  },
  colors: {
    ...SENIOR_THEME.colors,
    burgerLinksColor: '#565976',
    stickyButtonBackgroundImage:
      'linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 24%)',
    planItem: '#E74C3C',
    swiperPaginationBulletActive: '#E74C3C',
    primaryText: '#626262',
    secondaryText: '#2C3131',
    planItemText: '#2C3131',
    priceColor: '#626262',
    pricePeriodColor: '#626262',
    inputBorder: '#cacaca',
    primaryBorder: '#cacaca',
    buttonBackground: '#E74C3C',
    highlightedText: '#E74C3C',
  },
}
