import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import {
  Color,
  MediaBreakpoint,
  SENIOR_FLOW_HEADER_HEIGHT,
} from 'root-constants/common'

export const StyledSocialProofVariant7 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
    min-height: 100dvh;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;
  `,
  Text: styled.p`
    color: ${Color.GRAY_100};
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    flex-grow: 1;

    strong {
      color: ${Color.RED};
    }
  `,
  Img: styled.img`
    aspect-ratio: 375/247;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
}
