import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { COMMON_OPTION_VALUES, Language } from 'root-constants/common'

import { StyledStress as S } from './Stress.styles'

const PAGE_NAME = 'Stress Symptoms'

export const StressVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.stress.title', { lng: Language.EN }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      goTo(
        answer !== COMMON_OPTION_VALUES.YES &&
          answer !== COMMON_OPTION_VALUES.OFTEN
          ? alternativePagePath
          : nextPagePath,
      )
    },
    [dispatch, pageId, alternativePagePath, nextPagePath, t],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.stress.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.YES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.yes`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.OFTEN}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.often`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.RARELY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.rarely`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.NO}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.no`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
