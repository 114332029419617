import styled, { keyframes } from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

const fadein = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const StyledPlanProcessingVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 0 48px;
  `,
  ProgressContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 41px;
  `,
  ProgressText: styled.p`
    padding-left: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    animation: ${fadein} 0.5s ease-in-out;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 62px;
    text-align: center;

    strong {
      color: ${({ theme }) => theme.colors.highlightedText};
      font-size: 24px;
      font-weight: 900;
      line-height: 32px;
    }
  `,
  ContentWrapper: styled.div`
    border: 1px solid #b2b8cd;
    border-radius: 20px;
    padding: 40px 16px 24px;
    position: relative;
  `,
  Text: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #0a324d;
  `,
  ReviewWrapper: styled.div`
    border: 1px solid #b2b8cd;
    border-radius: 16px;
    padding: 24px 24px 32px;
  `,
  AvatarContainer: styled.div`
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
  `,
  NameContainer: styled.div``,
  Avatar: styled.img`
    width: 40px;
    height: 40px;
  `,
  PersonDescription: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  `,
}
