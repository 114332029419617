import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CUSTOMER_RATING_DATA } from 'modules/subscriptions/components/common/CustomerRating/constants'

import goldenEarth from 'assets/images/golden-earth.png'
import leftLeaf from 'assets/images/left-golden-leaf.png'
import ratingStars from 'assets/images/rating-stars.png'
import rightLeaf from 'assets/images/right-golden-leaf.png'

import { StyledCustomerRatingVariant2 as S } from './CustomerRatingVariant2.styles'

export const CustomerRatingVariant2: React.FC = () => {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <S.RatingWrapper>
        {CUSTOMER_RATING_DATA.map(({ icon, text }) => (
          <S.RatingData key={text}>
            <img src={icon} alt="icon" />
            <S.RatingDataText>
              <Trans i18nKey={t(text)} />
            </S.RatingDataText>
          </S.RatingData>
        ))}
      </S.RatingWrapper>
      <S.HeartRateWrapper>
        <S.LeftLeaf src={leftLeaf} alt="icon" />
        <S.HeartRateDataWrapper>
          <img src={ratingStars} alt="stars" />
          <S.Title>{t`subscriptions.customerRating.heartRate`}</S.Title>
          <S.HeartRateText>
            <Trans i18nKey="subscriptions.customerRating.chosenBy" />
          </S.HeartRateText>
          <img src={goldenEarth} alt="earth" />
        </S.HeartRateDataWrapper>
        <S.RightLeaf src={rightLeaf} alt="icon" />
      </S.HeartRateWrapper>
    </S.Wrapper>
  )
}
