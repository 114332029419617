import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { useVatInfo } from 'hooks/useVatInfo'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { Timer } from 'modules/subscriptions/components/variant7/Timer'
import { PLAN_ITEMS } from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import { SubscriptionTag } from 'root-constants/common'

import { StyledSubscriptionsVariant7 as S } from './SubscriptionsVariant7.styles'

export const SubscriptionsVariant7: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )

  const hasIncludedVAT = useVatInfo()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`subscriptions.subscription7.title`}</S.Title>
        <S.Subtitle>{t`subscriptions.subscription7.subtitle`}</S.Subtitle>
        <Timer />
        <SelectPlanBlockVariant1
          onSelect={handleSelectSubscription}
          SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
        />
        <Button type="button" onClick={handleShowPayment}>
          {t`actions.continue`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
