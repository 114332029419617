import styled from 'styled-components'

export const StyledReduceHeartAge = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 48px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background-color: white;
  `,
  Title: styled.h4`
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    text-align: center;

    strong {
      color: #01cda1;
      font-weight: inherit;
    }
  `,
  Text: styled.span`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
  `,
  Img: styled.img`
    margin-bottom: 24px;
  `,
}
