import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledDiet = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 46px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 72px;
  `,
  TitleDietDescription: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 12px;
  `,
  Description: styled.p`
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    strong {
      font-weight: 800;
      color: ${Color.BLUE};
    }
  `,
  Image: styled.img`
    margin-bottom: 24px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_GRAY};
  `,
}
