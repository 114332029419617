import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

export const useConfirmationPasswordInputField = (
  password: IInputFieldState,
): TInputFieldHook => {
  const { t } = useTranslation()

  const [confirmationPassword, setConfirmationPassword] =
    useState<IInputFieldState>({
      value: '',
      isValid: true,
      validationText: '',
    })

  useEffect(() => {
    if (
      password.value !== confirmationPassword.value &&
      password.value !== '' &&
      confirmationPassword.value !== ''
    ) {
      setConfirmationPassword((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: t`login.wrongConfirmation`,
      }))
      return
    }

    setConfirmationPassword((prevState) => ({
      ...prevState,
      isValid: true,
      validationText: '',
    }))
  }, [confirmationPassword.value, password.value, t])

  const validateConfirmationPassword = useCallback(
    (confirmationPasswordValue: string) => {
      const formattedPassword = confirmationPasswordValue.trim()

      if (formattedPassword.length < 8 && formattedPassword !== '') {
        setConfirmationPassword((prevState) => ({
          ...prevState,
          value: formattedPassword,
          isValid: false,
          validationText: t`login.wrongConfirmation`,
        }))
        return
      }

      if (formattedPassword !== password.value) {
        setConfirmationPassword((prevState) => ({
          ...prevState,
          value: formattedPassword,
          isValid: false,
          validationText: t`login.wrongConfirmation`,
        }))
        return
      }

      setConfirmationPassword((prevState) => ({
        ...prevState,
        value: formattedPassword,
        isValid: true,
        validationText: '',
      }))
    },
    [password.value, t],
  )

  return [
    confirmationPassword,
    setConfirmationPassword,
    validateConfirmationPassword,
  ]
}
