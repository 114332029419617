import styled from 'styled-components'

import { Input } from 'components/Input'
import { SvgImage } from 'components/SvgImage'

import { BottomStickyBase, baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledEmailAccount = {
  Wrapper: styled.div`
    ${baseColumnStyles};
    align-items: center;
    padding: 16px 0 40px;
    position: relative;
  `,
  Title: styled.h1`
    margin: 24px 0 12px;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    text-align: center;

    strong {
      color: ${({ theme }) => theme.colors.highlightedText};
      font-weight: inherit;
    }
  `,
  SvgImage: styled(SvgImage)`
    stroke: ${({ theme }) => theme.colors.highlightedText};
    height: 12px;
  `,
  Text: styled.p`
    margin-bottom: 48px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
  `,
  BackButton: styled.button`
    position: absolute;
    left: -12px;
    top: 114px;
    width: 24px;
    height: 24px;
    display: block;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    box-sizing: content-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  Input: styled(Input)`
    border-radius: 16px;
  `,
  Description: styled.span`
    margin-bottom: 12px;
    display: block;
    padding-top: 20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #b2b8cd;

    a {
      color: inherit;
      text-decoration: underline;
      font-weight: 700;
    }
  `,
  Footer: styled.footer`
    ${BottomStickyBase};

    @media (max-height: 640px) {
      position: relative;
      bottom: 0;
    }
  `,
}
