import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useVatInfo } from 'hooks/useVatInfo'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledDisclaimer as S } from './Disclaimer.styles'
import { DISCLAIMER_CONTEXTS } from './constants'

export const Disclaimer: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const price = useSelector(selectSubscriptionFullPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)

  const hasIncludedVAT = useVatInfo()

  return (
    <S.Text>
      {t('payment.disclaimer.period', {
        price,
        currencySymbol: CURRENCY_SYMBOLS[currency],
        vatInfo: hasIncludedVAT ? t('commonComponents.inclVat') : '',
        context: DISCLAIMER_CONTEXTS[periodQuantity],
        minimumFractionDigits: 2,
      })}
    </S.Text>
  )
}
