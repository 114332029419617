import React from 'react'

import rateImage from 'assets/images/high-risk.jpg'

import { StyledHighRateGraph as S } from './HighRateGraph.styles'

export const HighRateGraph: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => (
  <S.Wrapper>
    <S.Title>{children}</S.Title>
    <img src={rateImage} height={147} alt="high rate" />
  </S.Wrapper>
)
