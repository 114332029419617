import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohortToUse,
  selectSubscriptionList,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'
import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { VatPlanItemVariant1 } from 'modules/subscriptions/components/common/PlanItemVariant1'
import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import {
  COMMON_BENEFITS,
  PLAN_ITEMS,
  REDUCE_STRESS_BENEFITS,
  TRIAL_PLAN_ITEMS,
} from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { HeaderWithButton } from 'components/HeaderWithButton'
import {
  ReviewsSlider,
  ReviewsSliderAlternative,
} from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import scoresImg from 'assets/images/scores.webp'
import heartRateImg from 'assets/images/subscriptions-heart-rate.jpg'

import {
  CURRENCY_SYMBOLS,
  Cohort,
  Goal,
  SubscriptionTag,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledSubscriptionsVariant4 as S } from './SubscriptionsVariant4.styles'

export const SubscriptionsVariant4: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const price = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currency = useSelector(selectCurrency)

  const hasIncludedVAT = useVatInfo()

  const { goal } = useUserData()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const isThirteenCohort = useMemo(
    () => cohortToUse === Cohort.CARDIMATE_13,
    [cohortToUse],
  )

  const isTrialPlan = useMemo(
    () => TRIAL_PLAN_ITEMS.includes(subscriptionBlockType),
    [subscriptionBlockType],
  )

  const discount = useMemo(() => {
    if (isThirteenCohort && !isTrialPlan) {
      return 75
    }
    if (isTrialPlan) {
      return 50
    }
    return 70
  }, [isTrialPlan, isThirteenCohort])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
      goal,
    })
  }, [subscriptions, goal])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <HeaderWithButton onClick={handleScroll} />

      <S.Content>
        <SubheaderWithTimer discount={discount} />
        {goal === Goal.REDUCE_STRESS ? (
          <>
            <S.Title isThirteenCohort={isThirteenCohort}>
              <Trans i18nKey="subscriptions.takeControl" />
            </S.Title>
            <S.Subtitle>
              <Trans i18nKey="subscriptions.stressFeedback" />
            </S.Subtitle>
            <S.List isThirteenCohort={isThirteenCohort}>
              <ul>
                {REDUCE_STRESS_BENEFITS.map((path) => (
                  <li key={path}>{t(path)}</li>
                ))}
              </ul>
              <img src={scoresImg} alt="scores on mobile phone" width={295} />
            </S.List>
          </>
        ) : (
          <>
            <S.Congratulations isThirteenCohort={isThirteenCohort}>
              {t`subscriptions.congrats`}
            </S.Congratulations>
            <S.Title isThirteenCohort={isThirteenCohort}>
              {t`subscriptions.rightTrack`}
            </S.Title>
            <S.Disclaimer isThirteenCohort={isThirteenCohort}>
              {t`subscriptions.trackingTools`}
            </S.Disclaimer>

            <S.List isThirteenCohort={isThirteenCohort}>
              <ul>
                {COMMON_BENEFITS.map((path) => (
                  <li key={path}>{t(path)}</li>
                ))}
              </ul>
              <img
                src={heartRateImg}
                alt="scores on mobile phone"
                width={295}
              />
            </S.List>
          </>
        )}

        <SelectPlanBlockVariant1
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={
            hasIncludedVAT
              ? VatPlanItemVariant1
              : PLAN_ITEMS[subscriptionBlockType]
          }
        />
        <S.Button onClick={handleShowPayment}>{t`actions.getCardi`}</S.Button>
        {isTrialPlan ? (
          <S.SubscriptionDescription>
            <Trans
              i18nKey="subscriptions.paidTrial.subscriptionDescription"
              values={{
                context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
                currencySymbol: CURRENCY_SYMBOLS[currency],
                currencyCode: currency.toUpperCase(),
                trialPrice,
                price,
                minimumFractionDigits: 2,
              }}
              components={{
                termsOfUse: <TermsOfUseLink />,
                supportLink: <SupportLink />,
              }}
            />
          </S.SubscriptionDescription>
        ) : (
          <S.CancelDisclaimer isThirteenCohort={isThirteenCohort}>
            {t`subscriptions.disclaimer`}
          </S.CancelDisclaimer>
        )}
        {goal === Goal.REDUCE_STRESS ? (
          <ReviewsSliderAlternative
            titlePath="subscriptions.customersLove"
            titleBottomMargin={48}
            marginBottom={40}
          />
        ) : (
          <ReviewsSlider
            titlePath="subscriptions.customersLove"
            titleBottomMargin={48}
            marginBottom={40}
          />
        )}
        <MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
