import styled from 'styled-components'

import checkIcon from 'assets/images/check.svg'
import soldOutPriceBgImg from 'assets/images/price-bg-grey.svg'
import priceBgImg from 'assets/images/price-bg.svg'

import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

export const StyledPlanItemVariant1 = {
  PlanItem: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    padding: 31px 16px 22px 56px;
    margin-bottom: 16px;
    border-radius: 12px;
    border: 1px solid #01cda1;
    background-color: ${Color.WHITE};

    &::after {
      content: attr(data-text);
      width: ${BASE_COLUMN_WIDTH}px;
      display: block;
      padding: 2px 0;
      position: absolute;
      top: -1px;
      left: -1px;
      font-weight: 900;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      color: ${Color.WHITE};
      background-color: #01cda1;
      border-radius: 12px 12px 0 0;

      [data-is-sold-out='true']& {
        content: attr(data-text);
        background-color: #b2b8cd;
      }
    }

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 41px;
      left: 16px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: center url(${checkIcon}) no-repeat #01cda1;
      background-size: 12px;

      [data-is-sold-out='true']& {
        background-image: none;
        border: 1px solid #dce0ee;
        background-color: #f0f2fa;
      }
    }

    &[data-is-sold-out='true'] {
      border: 1px solid ${Color.WHITE};
    }
  `,
  Container: styled.div`
    display: flex;
    align-items: center;
  `,
  PlanTitle: styled.div`
    font-weight: 700;
    text-transform: capitalize;
  `,
  CurrentPrice: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    width: 81px;
    height: 40px;
    font-weight: 900;
    color: ${Color.WHITE};
    background-image: url(${priceBgImg});
    background-repeat: no-repeat;
    background-size: contain;

    &[data-is-sold-out='true'] {
      color: #b2b8cd;
      background-image: url(${soldOutPriceBgImg});
    }

    &[data-currency-symbol-length='3'] {
      width: 90px;
      height: 45px;
      padding-right: 5px;
    }
  `,
  OldPrice: styled.span`
    font-weight: 700;
    color: rgba(253, 65, 127, 0.5);
    text-decoration: line-through;
    text-decoration-thickness: from-font;
    margin-right: 16px;

    &[data-currency-symbol-length='3'] {
      margin-right: 6px;
    }
  `,
}
