import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import img from 'assets/images/social-proof-irregularities.webp'

import { COMMON_OPTION_VALUES } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledSocialProofIrregularities as S } from './SocialProofIrregularities.styles'

export const SocialProofIrregularitiesVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const userAnswers = useSelector(selectAnswers)

  const hasUserIrregularitiesInHR = useMemo(
    () =>
      userAnswers?.[PageId.IRREGULARITIES_IN_HR_1] !==
      COMMON_OPTION_VALUES.NEVER,
    [userAnswers],
  )

  const handleContinue = useNextStep({
    pageId,
    pageName: hasUserIrregularitiesInHR
      ? 'Social Proof Pulse'
      : 'Social Proof Healthy People Die',
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        {hasUserIrregularitiesInHR ? (
          <>
            <S.Title>{t`onboarding.socialProofHeartIrregularities.title`}</S.Title>
            <S.Text>
              {t`onboarding.socialProofHeartIrregularities.description`}
            </S.Text>
          </>
        ) : (
          <>
            <S.Title>{t`onboarding.socialProofHeartIrregularities.title2`}</S.Title>
            <S.Text>
              <Trans i18nKey="onboarding.socialProofHeartIrregularities.description2" />
            </S.Text>
          </>
        )}
        <S.Img src={img} alt="pressure measurement" />
        <S.StickyButton onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </S.StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
