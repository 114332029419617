import food1 from 'assets/images/social-proof-8.jpg'
import food2 from 'assets/images/social-proof-9.jpg'
import doctors from 'assets/images/social-proof-doctors.jpg'

import { PageId } from 'root-constants/pages'

export const SOCIAL_PROOF_DATA = {
  [PageId.KIT_SOCIAL_PROOF_3]: {
    image: doctors,
    title: 'onboarding.socialProof.afterRecovery',
    text: 'onboarding.socialProof.somePeople',
  },
  [PageId.KIT_SOCIAL_PROOF_8]: {
    image: food1,
    title: 'onboarding.socialProof.limitingFoods',
    text: 'onboarding.socialProof.sugarsAndSodium',
  },
  [PageId.KIT_SOCIAL_PROOF_9]: {
    image: food2,
    title: 'onboarding.socialProof.deliciousMeals',
    text: 'onboarding.socialProof.wePromise',
  },
}
