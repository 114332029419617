import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import highPressureImage from 'assets/images/high-blood-pressure-graph.jpg'
import lowPressureImage from 'assets/images/low-blood-pressure-graph.jpg'

import { CustomPageId } from 'root-constants/pages'

import { StyledBloodPressureGraphVariant1 as S } from './BloodPressureGraphVariant1.styles'

const LOW_BLOOD_PRESSURE = '<120 / <80'

export const BloodPressureGraphVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const hasLowBloodPressure = useMemo(
    () => userAnswers?.[CustomPageId.BLOOD_PRESSURE] === LOW_BLOOD_PRESSURE,
    [userAnswers],
  )

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Content>
        <S.Column>
          <S.Title>{t`onboarding.bloodPressureGraph.title`}</S.Title>
          <S.GraphContainer>
            <S.GraphTitle>{t`onboarding.bloodPressureGraph.yourPressure`}</S.GraphTitle>
            <img
              src={hasLowBloodPressure ? lowPressureImage : highPressureImage}
              alt="graph"
            />
          </S.GraphContainer>
          <S.Disclaimer>
            <Trans i18nKey="onboarding.bloodPressureGraph.disclaimer" />
          </S.Disclaimer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
