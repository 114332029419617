import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div`
    font-size: ${({ theme }) => theme.common.moneyBackTextSize};
    line-height: 20px;
    text-align: center;
    color: #565976;
  `,
  LinksWrapper: styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-bottom: 16px;

    a {
      color: inherit;
      text-decoration: underline;
      font-weight: ${({ theme }) => theme.common.moneyBackLinksFontWeight};
    }
  `,
  Image: styled(SvgImage)`
    fill: ${({ theme }) => theme.colors.moneyBackImage};
    margin-bottom: ${({ theme }) => theme.common.moneyBackImageMargin};
  `,
  Logo: styled.img`
    margin: 0 auto 16px;
  `,
}
