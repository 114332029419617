import styled from 'styled-components'

import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledSubscriptionsVariant9 = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 80px 0 100px;
    align-items: center;
  `,
  Title: styled.h3`
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;

    strong {
      font-size: 32px;
      font-weight: 900;
      line-height: 40px;
      color: ${Color.DANGER};
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    color: ${Color.LIGHT_TEXT};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 12px;
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
  CancelInfo: styled.p`
    margin-bottom: 32px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
  SubscriptionDescription: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 48px;
    text-align: center;
    color: #565976;

    & a {
      color: #565976;
      text-decoration: underline;
    }
  `,
  MoneyBackGuarantee: styled(MoneyBackGuarantee)`
    text-align: start;
  `,
}
