import { css } from 'styled-components'

import label from 'assets/images/check-icon-wavy-borders.svg'
import checkIcon from 'assets/images/check.svg'

import { Color } from 'root-constants/common'

export const activePlanItemStyles = css`
  border-color: ${({ theme }) => theme.colors.planItem};

  ::before {
    background: center url(${checkIcon}) no-repeat #7bc27e;
    background-size: 12px;
  }
`

export const defaultPlanItemStyles = css`
  position: relative;

  &::after {
    content: attr(data-text);
    display: block;
    width: max-content;
    padding: 0 16px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    height: 26px;
    font-size: 10px;
    line-height: 26px;
    font-weight: 800;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: ${Color.WHITE};
    background-color: ${({ theme }) => theme.colors.planItem};
    border-radius: 100px;
  }
`
export const commonPlanItemStyles = css`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.common.planItemFontSize};
  line-height: ${({ theme }) => theme.common.planItemLineHeight};
  padding: 24px 24px 24px 64px;
  border-radius: ${({ theme }) => theme.common?.planItemBorderRadius};
  border: 1px solid transparent;
  background-color: ${Color.WHITE};
  transition: border-color 0.2s ease-out;
  color: ${({ theme }) => theme.colors.planItemText};

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 24px;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 1px solid #dce0ee;
    border-radius: 50%;
    background-color: #f0f2fa;
  }

  &[data-is-default='true'] {
    ${defaultPlanItemStyles};
  }

  &[data-is-selected='true'] {
    ${activePlanItemStyles};
  }

  &[data-is-selected='true']::before {
    border-color: transparent;
  }
`

export const commonTitleStyles = css`
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #5f96f7;
`

export const commonListStyles = css`
  margin-bottom: 61px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 24px 16px 16px;
  background-color: ${Color.WHITE};
  border-radius: 16px;
  color: #0a324d;

  ul {
    margin-bottom: 36px;
  }

  li {
    margin-bottom: 16px;
    padding-left: 32px;
    background-image: url(${label});
    background-repeat: no-repeat;
    background-position: 0 2px;
  }

  img {
    border-radius: 8px;
  }
`
