import styled from 'styled-components'

import { commonTitleStyles } from 'modules/subscriptions/styles'

import { Button } from 'components/Button'

import label from 'assets/images/check-icon-wavy-borders.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledCancelOffer = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 88px 0 55px;
  `,
  Title: styled.h3`
    ${commonTitleStyles};
    margin-bottom: 16px;
  `,
  CancelOfferText: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;

    strong {
      font-weight: 900;
    }
  `,
  PromoBlock: styled.div`
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    background-color: #fff2f6;
    border: 1px solid #f4d0dc;
    border-radius: 12px;
    padding: 15px 7px 18px;
    margin-bottom: 48px;

    b,
    strong,
    span {
      font-weight: 900;
    }

    strong {
      color: #fd417f;
    }

    span {
      font-size: 28px;
      line-height: 42px;
      text-align: center;
      color: #fd417f;
    }
  `,
  SubscriptionBlockTitle: styled.h3`
    ${commonTitleStyles};
    margin-bottom: 24px;
  `,
  Button: styled(Button)`
    background-image: linear-gradient(
      180deg,
      #eb5e96 0%,
      #f04651 100%,
      #eb454f 100%
    );
    box-shadow: 0 8px 16px rgba(54, 132, 255, 0.2);
    margin-bottom: 48px;
  `,
  BenefitsList: styled.ul`
    width: 100%;
    margin: 24px 0 48px;
    padding: 24px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    background-color: ${Color.WHITE};
    border-radius: 16px;

    li {
      background-image: url(${label});
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 32px;
      margin-bottom: 16px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    strong {
      font-weight: 900;
    }
  `,
}
