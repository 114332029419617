import styled, { css } from 'styled-components'

const baseContainerStyles = css`
  height: 55px;
  background-color: rgb(255, 196, 57);

  & iframe {
    border-radius: 16px;
    z-index: 1 !important;
  }
`

export const StyledPayPalContainer = {
  ButtonsContainer: styled.div`
    ${baseContainerStyles};
    width: 100%;
    margin-bottom: 16px;
    border-radius: 16px;
  `,
}
