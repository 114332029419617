import styled from 'styled-components'

import ellipse from 'assets/images/ellipse.png'
import humanBody from 'assets/images/human-body.png'

import { Color } from 'root-constants/common'

type TStatsItemProps = {
  justifyContent?: string
}

export const StyledHealthStats = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 16px;
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    position: relative;
    min-height: 232px;
    background-image: url(${humanBody});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 295px;
      height: 295px;
      transform: translateX(-50%);
      background-image: url(${ellipse});
      background-size: 100%;
      background-repeat: no-repeat;
    }
  `,
  StatsContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-height: 110px;
    gap: 44px;
    z-index: 1;
  `,
  LeftStatsItem: styled.div<TStatsItemProps>`
    display: grid;
    grid-template-columns: 32px 42px;
    grid-template-rows: auto;
    grid-template-areas: 'icon title' 'icon value';
    grid-column-gap: 8px;
    width: 96px;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  `,
  RightStatsItem: styled.div<TStatsItemProps>`
    display: grid;
    grid-template-columns: 42px 32px;
    grid-template-rows: auto;
    grid-template-areas: 'title icon' 'value icon';
    grid-column-gap: 8px;
    width: 96px;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    text-align: right;
  `,
  StatsIconContainer: styled.div`
    grid-area: icon;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: #60adf5;
    box-shadow: 0 9px 8px 0 rgba(96, 173, 245, 0.2);
    padding: 6px;
  `,
  StatsTitle: styled.p`
    grid-area: title;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
  `,
  StatsValue: styled.p`
    grid-area: value;
    color: ${Color.LIGHT_TEXT};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  `,
  HeartAttackContainer: styled.div`
    min-height: 144px;
    padding: 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
  `,
  HeartAttackText: styled.p`
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
}
