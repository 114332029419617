export const OPTION_VALUES = {
  LIGHT: 'light',
  MODERATE: 'moderate',
  HIGH: 'high',
}
export const OPTION_VALUES_ACTIVITY_5 = [
  {
    value: OPTION_VALUES.LIGHT,
    text: 'onboarding.physicalActivity.lightlyActive2',
  },
  {
    value: OPTION_VALUES.MODERATE,
    text: 'onboarding.physicalActivity.moderatelyActive2',
  },
  {
    value: OPTION_VALUES.HIGH,
    text: 'onboarding.physicalActivity.veryActive2',
  },
]
export const TITLE_PATH = 'onboarding.physicalActivity.title'
