import styled, { css } from 'styled-components'

import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

import { IProps as IButtonProps } from './Button'

export const StyledButtonBase = css`
  width: ${BASE_COLUMN_WIDTH}px;
  height: 58px;
  margin: 0 auto;
  display: block;
  padding: 0 20px;
  cursor: pointer;
  border: none;
  border-radius: 16px;
  outline: none;
  font-size: 16px;
  font-weight: 900;
  line-height: 58px;
  text-decoration: none;
  text-align: center;
  color: ${Color.WHITE};
  background-image: ${({ theme }) => theme.colors.buttonBackgroundImage};
  background-color: ${({ theme }) => theme.colors.buttonBackground};
  box-shadow: ${({ theme }) => theme.shadows.buttonShadow};
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:disabled {
    background-image: ${({ theme }) =>
      theme.colors.disabledButtonBackgroundImage};
    background-color: ${({ theme }) => theme.colors.disabledButtonBackground};
  }
`

export const StyledButton = styled.button<IButtonProps>`
  ${StyledButtonBase};
`
