import styled from 'styled-components'

import { QuestionButton } from 'components/QuestionButton'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledStatementVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 48px 0 44px;
    justify-content: space-between;
    height: calc(100svh - ${HEADER_HEIGHT}px);
  `,
  Title: styled.h1`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
  `,
  OptionsContainer: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    gap: 8px;
    z-index: 1;
  `,
  OptionsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      margin-bottom: 8px;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    padding: 13px 0;
    border: 1px solid #b2b8cd;
    background-color: #fff;
    width: 58px;
    height: 58px;

    img {
      height: 32px;
      margin: auto;
    }
  `,
  Description: styled.p`
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
  `,
}
