import {
  selectCookieConsentCountries,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectCookieConsent,
  selectEmail,
  selectUUID,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'
import { createProductId } from 'helpers/createProductId'

import { IAppState } from 'models/store.model'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'

import { CookieConsentOption } from 'root-constants/common'

export const getPurchaseCommonEventParams = (state: IAppState) => {
  const uuid = selectUUID(state)
  const email = selectEmail(state)
  const stripePriceId = selectSubscriptionLookupKey(state)
  const price = selectSubscriptionFullPrice(state)
  const trialPrice = selectSubscriptionTrialPeriodPrice(state)
  const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
  const trialPriceId = selectSubscriptionTrialLookupKey(state)
  const trialPeriodQuantity = selectSubscriptionTrialPeriodDays(state)
  const currency = selectCurrency(state)
  const screenName = selectScreenName(state)
  const stripeAccountName = selectStripeAccountName(state)
  const stripeAccountId = selectStripeAccountId(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const cookieConsent = selectCookieConsent(state)
  const cookieConsentCountries = selectCookieConsentCountries(state)
  const userCountryCode = selectUserCountryCode(state)

  const isEUUser = cookieConsentCountries.includes(
    userCountryCode.toUpperCase(),
  )

  const productId = trialPriceId
    ? createIntroOfferProductId({
        priceId: stripePriceId,
        trialPriceId,
        trialPeriodQuantity,
      })
    : createProductId({
        periodName,
        periodQuantity,
        price,
      })

  return {
    uuid,
    email,
    price,
    trialPrice,
    trialPriceId,
    trialPeriodDays,
    currency,
    screenName,
    productId,
    stripeAccountName,
    stripeAccountId,
    stripePriceId,
    isPersonalDataAllowed:
      !isEUUser ||
      (isEUUser &&
        (cookieConsent || []).includes(CookieConsentOption.FUNCTIONAL_COOKIES)),
  }
}
