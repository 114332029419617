import React from 'react'
import { useTranslation } from 'react-i18next'

import { eventLogger } from 'services/eventLogger.service'

import { AppLinks } from 'root-constants/common'

import { OuterLink } from '../OuterLink'

export const PrivacyPolicyLink: React.FC = () => {
  const { t } = useTranslation()

  return (
    <OuterLink
      href={AppLinks.PP_LINK}
      onClick={() => eventLogger.logPrivacyPolicyClicked()}
    >
      {t`commonComponents.privacyPolicy`}
    </OuterLink>
  )
}
