import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { BLOOD_PRESSURE_OPTION_VALUES, Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledBloodPressureVariant3 as S } from './BloodPressureVariant3.styles'
import { PAGE_NAME } from './constants'

export const BloodPressureVariant3: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.BLOOD_PRESSURE,
    pageName: PAGE_NAME,
    question: t('onboarding.bloodPressure.question3', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.BLOOD_PRESSURE,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.bloodPressure.question3`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_LESS_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_LESS_80}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={BLOOD_PRESSURE_OPTION_VALUES.DONT_KNOW}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.doNotKnow`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
