import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import graph3MonthsImage from 'assets/images/graph-3-months.svg'

import { StyledCongratulationsReduceStress as S } from './CongratulationsReduceStress.styles'

export const CongratulationsReduceStress: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t`summary.congratulations`}</S.Title>
      <S.Text>
        <Trans
          i18nKey="summary.congratulationsReduceStress.subtitle"
          components={{ em: <em /> }}
        />
      </S.Text>
      <S.MetricsWrapper>
        <S.Metrics>
          <S.MetricsValue>{t`summary.congratulationsReduceStress.stressLevel`}</S.MetricsValue>
          <S.MetricsDate>
            {t('summary.byDate', {
              date: dayjs().add(2, 'month').format('MMMM YYYY'),
            })}
          </S.MetricsDate>
        </S.Metrics>
        <S.Graph>
          <S.GraphCurrent>78%</S.GraphCurrent>
          <S.GraphGoal>25%</S.GraphGoal>
          <S.GraphImage height={120} src={graph3MonthsImage} alt="graph" />
          <S.GraphMonths>
            <span>{dayjs().format('MMM')}</span>
            <span>{dayjs().add(1, 'month').format('MMM')}</span>
            <span>{dayjs().add(2, 'month').format('MMM')}</span>
          </S.GraphMonths>
        </S.Graph>
      </S.MetricsWrapper>
    </S.Wrapper>
  )
}
