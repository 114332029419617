import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TProps = {
  marginBottom?: number
}
export const StyledDynamicLeftToRight = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div<TProps>`
    ${baseColumnStyles};
    padding-top: 46px;
  `,
  Title: styled.h1<TProps>`
    ${baseTitleStyles};
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 23px;
  `,
}
