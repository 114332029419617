import styled, { css } from 'styled-components'

const progressValueStyles = css`
  background-color: #35b3e5;
  transition: all 0.2s ease-out;
`

export const StyledProgressBarV4 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    place-content: center;
    flex: 1;
  `,
  Title: styled.p`
    color: #000;
    margin-bottom: 8px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  ProgressContainer: styled.div`
    display: flex;
    justify-content: center;
    column-gap: 8px;
    padding: 2px 0;
  `,
  Progress: styled.progress`
    width: 58px;
    height: 4px;
    appearance: none;
    border: none;
    border-radius: 4px;
    background-color: #e9e9e9;

    &::-webkit-progress-bar {
      background-color: #e9e9e9;
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
