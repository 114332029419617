import styled from 'styled-components'

import { BASE_COLUMN_WIDTH } from 'root-constants/common'

export const StyledHeader = {
  Wrapper: styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 36, 95, 0.05);
  `,
  Header: styled.header`
    width: ${BASE_COLUMN_WIDTH}px;
    padding: 10px 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
