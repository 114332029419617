import React from 'react'
import { useTranslation } from 'react-i18next'

import { TPageProps } from 'models/common.model'

import { KitRadioOptionPage } from 'pages/kit-options-page'

import { OPTIONS_DATA } from './constants'

export const DailyMealsVariant1: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()

  return (
    <KitRadioOptionPage
      {...props}
      options={OPTIONS_DATA}
      titlePath={t`onboarding.dailyMeals.title`}
    />
  )
}
