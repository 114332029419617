import React from 'react'

import { TPageProps } from 'models/common.model'

import { KitRadioOptionPage } from 'pages/kit-options-page'

import { OPTION_VALUES, SUBTITLE_PATH, TITLE_PATH } from './constants'

export const TestosteroneVariant2: React.FC<TPageProps> = (props) => (
  <KitRadioOptionPage
    {...props}
    options={OPTION_VALUES}
    titlePath={TITLE_PATH}
    subtitlePath={SUBTITLE_PATH}
    titleMarginBottom={8}
  />
)
