import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import logoImg from 'assets/images/app-name-logo.png'

import {
  COHORTS_WITH_BURGER_MENU,
  Cohort,
  PAGES_WITH_BURGER_MENU,
} from 'root-constants/common'

import { BurgerMenu } from '../BurgerMenu'
import { ProgressBar } from '../ProgressBar'
import { StyledHeader as S } from './Header.styles'

export const Header: React.FC = () => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { currentPageId, hasHeader } = useGetPageInfo()

  const hasBurgerMenu = useMemo(
    () =>
      COHORTS_WITH_BURGER_MENU.includes(cohortToUse) &&
      PAGES_WITH_BURGER_MENU.includes(currentPageId),
    [cohortToUse, currentPageId],
  )

  const hasAlternativeProgressBar = useMemo(
    () =>
      cohortToUse === Cohort.CARDIMATE_15 ||
      cohortToUse === Cohort.CARDIMATE_17 ||
      cohortToUse === Cohort.CARDIMATE_19,
    [cohortToUse],
  )

  return !hasHeader ? null : (
    <S.Wrapper>
      <S.Header>
        <img height="28" src={logoImg} alt="cardi mate logo" />
      </S.Header>
      {hasBurgerMenu && <BurgerMenu />}
      {!hasAlternativeProgressBar && <ProgressBar />}
    </S.Wrapper>
  )
}
