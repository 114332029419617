import styled, { createGlobalStyle } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

const TIMER_HEIGHT = 64

type TClockProps = {
  clockheight?: number
}

export const GlobalStyleForSubheaderWithTimer = createGlobalStyle`
   #root {
     margin-top: ${TIMER_HEIGHT}px;
   }
`
export const StyledSubheaderWithTimer = {
  TimerContainer: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: ${TIMER_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.timerBackground};
  `,
  TimerContent: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.timerDiscountText};

    strong {
      font-weight: 900;
      margin-left: 5px;
      color: ${({ theme }) => theme.colors.timerText};
    }
  `,
  ClockImg: styled(SvgImage)<TClockProps>`
    stroke: ${({ theme }) => theme.colors.timerText};
    height: ${({ clockheight }) => clockheight || 16}px;
    margin-right: 6px;
  `,
  Discount: styled.span`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.timerText};
    margin-right: 5px;
  `,
}
