import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'
import { useUserSymptoms } from 'hooks/useUserSymptoms'

import { MAX_SYMPTOMS_SIZE } from 'modules/subscriptions/pages/SubscriptionsVariant8/constants'

import graph from 'assets/images/before-after-graphic.png'
import goalIcon from 'assets/images/goal-icon.png'
import symptomsIcon from 'assets/images/symptoms-icon.png'

import { StyledSymptomsVariant1 as S } from './SymptomsVariant1.styles'

export const SymptomsVariant1: React.FC = () => {
  const { t } = useTranslation()
  const { symptoms } = useUserData()
  const userSymptoms = useUserSymptoms(MAX_SYMPTOMS_SIZE)

  const formattedUserSymptoms = useMemo(() => {
    if (!symptoms) return []
    return symptoms?.length <= MAX_SYMPTOMS_SIZE
      ? userSymptoms
      : [...userSymptoms, t`commonComponents.more`]
  }, [userSymptoms, symptoms, t])

  return (
    <S.GraphWrapper>
      <S.BenefitsData>
        <S.BenefitsIcon src={goalIcon} alt="icon" />
        <S.BenefitsTextWrapper>
          <S.BenefitsTitle>{t`subscriptions.subscription3.goals.yourGoal`}</S.BenefitsTitle>
          <S.BenefitsText>{t`subscriptions.subscription3.goals.bloodPressure`}</S.BenefitsText>
        </S.BenefitsTextWrapper>
      </S.BenefitsData>
      {!!userSymptoms.length && (
        <S.BenefitsData>
          <S.BenefitsIcon src={symptomsIcon} alt="icon" />
          <S.BenefitsTextWrapper>
            <S.BenefitsTitle>{t`subscriptions.subscription3.goals.reduceSymptoms`}</S.BenefitsTitle>
            <S.BenefitsText>
              <Trans
                i18nKey="subscriptions.subscription3.goals.symptoms"
                values={{
                  symptoms: formattedUserSymptoms,
                  style: 'long',
                  type: 'conjunction',
                }}
              />
            </S.BenefitsText>
          </S.BenefitsTextWrapper>
        </S.BenefitsData>
      )}
      <S.ImageContainer>
        <img src={graph} alt="graph" />
      </S.ImageContainer>
    </S.GraphWrapper>
  )
}
