import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFirstPageFinish } from 'hooks/useFirstPageFinish'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import introImg from 'assets/images/intro-doctors.webp'

import { StyledIntroVariant6 as S } from './IntroVariant6.styles'

const PAGE_NAME = 'First question'

export const IntroVariant6: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  const handleFirstPageFinish = useFirstPageFinish()

  const handleClick = useCallback(() => {
    handleFirstPageFinish()
    handleContinue('')
  }, [handleContinue, handleFirstPageFinish])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.intro.intro3.title`}</S.Title>
        <S.Text marginBottom={32}>
          <Trans i18nKey="onboarding.intro.intro3.subtitle" />
        </S.Text>

        <S.ImageContainer>
          <img src={introImg} alt="doctor" />
        </S.ImageContainer>
        <S.Text>{t`onboarding.intro.intro6.takeQuiz`}</S.Text>
        <S.Button type="button" onClick={handleClick}>
          {t`actions.continue`}
        </S.Button>
        <S.Disclaimer>
          <Trans i18nKey="onboarding.intro.intro6.disclaimer" />
        </S.Disclaimer>

        <S.AgreementText>{t`onboarding.intro.byContinuing`}</S.AgreementText>
        <S.PoliciesLinksWrapper>
          <TermsOfUseLink />
          <PrivacyPolicyLink />
        </S.PoliciesLinksWrapper>
        <S.Description>{t`commonComponents.description`}</S.Description>
      </S.Column>
    </S.Wrapper>
  )
}
