import styled from 'styled-components'

import { Button } from 'components/Button'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TGraphTitleProps = {
  isNineteenCohort: boolean
}
export const StyledBloodPressureGraphVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    background: linear-gradient(
        0deg,
        rgba(240, 242, 250, 0.4) 0%,
        #f0f2fa 30%,
        #d5e4ff 100%
      ),
      #f0f2fa;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 0 0;
    align-items: center;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 30px;
  `,
  GraphContainer: styled.div`
    width: 100%;
    margin-bottom: 12px;
    padding: 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
    aspect-ratio: 327/305;
  `,
  GraphTitle: styled.h3<TGraphTitleProps>`
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: ${({ isNineteenCohort }) => (isNineteenCohort ? 700 : 400)};
    line-height: 24px;
    color: ${({ isNineteenCohort }) =>
      isNineteenCohort ? Color.DANGER : Color.LIGHT_TEXT};

    strong {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: ${Color.DEFAULT_TEXT};
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    color: rgba(86, 89, 118, 0.8);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  Button: styled(Button)`
    position: fixed;
    bottom: 40px;
  `,
}
