import {
  BLOOD_PRESSURE_OPTION_VALUES,
  SLEEP_OPTION_VALUES,
} from 'root-constants/common'

export const ENERGY_LEVELS = {
  [SLEEP_OPTION_VALUES.LESS_THAN_6]: '35%',
  [SLEEP_OPTION_VALUES.AVERAGE]: '56%',
  [SLEEP_OPTION_VALUES.MORE_THAN_8]: '64%',
  [SLEEP_OPTION_VALUES.VARIES]: '49%',
}

export const BLOOD_PRESSURE_LEVELS = {
  [BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80]:
    'summary.summaryHealthProfile.bloodPressure.low',
  [BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_80]:
    'summary.summaryHealthProfile.bloodPressure.normal',
  [BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89]:
    'summary.summaryHealthProfile.bloodPressure.high',
  [BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90]:
    'summary.summaryHealthProfile.bloodPressure.high',
  [BLOOD_PRESSURE_OPTION_VALUES.DONT_KNOW]:
    'summary.summaryHealthProfile.bloodPressure.none',
}
