import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Facts } from 'components/Facts'

import { CongratulationsReduceStress } from '../CongratulationsReduceStress'
import { HealthyLevelGraph } from '../HealthyLevelGraph'
import { HeartAge } from '../HeartAge'
import { HeartLevel } from '../HeartLevel'
import { HighRateGraph } from '../HighRateGraph'
import { StyledReduceStress as S } from './ReduceStress.styles'

type TProps = {
  handleButtonClick: () => void
}

export const ReduceStress: React.FC<TProps> = ({ handleButtonClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <S.Title>
        <Trans i18nKey="summary.reduceStress.title" />
      </S.Title>
      <CongratulationsReduceStress />
      <Facts />
      <S.Title>{t`summary.heartHealth.freshStart`}</S.Title>
      <HeartLevel percent={67} iconVariant={1}>
        {t`summary.reduceStress.lowStress`}
      </HeartLevel>
      <HeartLevel percent={76} iconVariant={2}>
        {t`summary.reduceStress.relaxingMaterials`}
      </HeartLevel>
      <HealthyLevelGraph marginBottom={48}>
        <Trans i18nKey="summary.reduceStress.healthyLevels" />
      </HealthyLevelGraph>
      <S.Button onClick={handleButtonClick}>{t`actions.getMyPlan`}</S.Button>
      <S.Title>{t`summary.heartHealth.heartHealthSummary`}</S.Title>
      <HighRateGraph>
        <Trans i18nKey="summary.reduceStress.highRate" />
      </HighRateGraph>
      <HeartAge />
    </>
  )
}
