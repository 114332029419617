import fifthUser from 'assets/images/heart-companion-fifth.png'
import firstUser from 'assets/images/heart-companion-first.png'
import fourthUser from 'assets/images/heart-companion-fourth.png'
import secondUser from 'assets/images/heart-companion-second.png'
import sixUser from 'assets/images/heart-companion-six.png'
import thirdUser from 'assets/images/heart-companion-third.png'

export const USER_DATA = [
  {
    avatar: firstUser,
    name: 'onboarding.heartCompanion.firstAuthor',
    review: 'onboarding.heartCompanion.firstReview',
  },
  {
    avatar: secondUser,
    name: 'onboarding.heartCompanion.secondAuthor',
    review: 'onboarding.heartCompanion.secondReview',
  },
  {
    avatar: thirdUser,
    name: 'onboarding.heartCompanion.thirdAuthor',
    review: 'onboarding.heartCompanion.thirdReview',
  },
]

export const USER_DATA_FEMALE = [
  {
    avatar: firstUser,
    name: 'onboarding.heartCompanion.firstAuthor',
    review: 'onboarding.heartCompanion.firstReview',
  },
  {
    avatar: secondUser,
    name: 'onboarding.heartCompanion.secondAuthor',
    review: 'onboarding.heartCompanion.secondReview',
  },
  {
    avatar: fourthUser,
    name: 'onboarding.heartCompanion.seventhAuthor',
    review: 'onboarding.heartCompanion.thirdReview',
  },
]
export const USER_DATA_MALE = [
  {
    avatar: fifthUser,
    name: 'onboarding.heartCompanion.fourthAuthor',
    review: 'onboarding.heartCompanion.firstReview',
  },
  {
    avatar: sixUser,
    name: 'onboarding.heartCompanion.fifthAuthor',
    review: 'onboarding.heartCompanion.secondReview',
  },
  {
    avatar: thirdUser,
    name: 'onboarding.heartCompanion.sixAuthor',
    review: 'onboarding.heartCompanion.thirdReview',
  },
]
