import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { sendUserConfigAction, setProductName } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
  selectHasCancelOffer,
  selectScreenName,
} from 'root-redux/selects/common'

import { useProductId } from 'hooks/useProductId'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  Description,
  TrialDescription,
} from 'modules/payment/components/Description'
import { Disclaimer } from 'modules/payment/components/Disclaimer'
import { PrimerPaymentForm } from 'modules/payment/components/PrimerPaymentForm'
import { TotalAmount } from 'modules/payment/components/TotalAmount'
import { TotalAmountVAT } from 'modules/payment/components/TotalAmountVAT'
import {
  selectProductId,
  selectSubscriptionTrialLookupKey,
} from 'modules/payment/redux/selects'

import paymentServicesImage from 'assets/images/payment-services.png'

import { goBack, replaceHistory } from 'browser-history'
import { Cohort, ProductKey } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { PaymentRequestButton } from '../PaymentRequestButton'
import { StripePaymentForm } from '../StripePaymentForm'
import { TrialAmount } from '../TrialAmount'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const MixedPaymentFlows: React.FC = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const screenName = useSelector(selectScreenName)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const hasCancelOffer = useSelector(selectHasCancelOffer)
  const productName = useSelector(selectProductId)

  const productId = useProductId()
  const hasIncludedVAT = useVatInfo()
  const { goal } = useUserData()

  const isFifteenCohort = useMemo(
    () => cohortToUse === Cohort.CARDIMATE_15,
    [cohortToUse],
  )

  const hasDisclaimer = useMemo(
    () => cohortToUse === Cohort.CARDIMATE_12,
    [cohortToUse],
  )

  const paymentAmount = useMemo(() => {
    if (trialPriceId) {
      return <TrialAmount />
    }
    if (!trialPriceId && hasIncludedVAT) {
      return <TotalAmountVAT />
    }
    return <TotalAmount />
  }, [trialPriceId, hasIncludedVAT])

  const description = useMemo(() => {
    if (trialPriceId) {
      return <TrialDescription />
    }
    if (hasDisclaimer && !trialPriceId) {
      return <Disclaimer />
    }
    return <Description />
  }, [hasDisclaimer, trialPriceId])

  const handleBack = useCallback(() => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      goal,
      screenName,
    })

    if (
      hasCancelOffer &&
      screenName !== ScreenName.CANCEL_OFFER &&
      productName !== ProductKey.ONE_WEEK
    ) {
      dispatch(setSubscriptionListAction([]))
      dispatch(setProductName(productName))
      dispatch(
        sendUserConfigAction({
          product_name: productName,
        }),
      )
      replaceHistory({ pathname: PageId.CANCEL_OFFER_DISCOUNT_1, search })
      return
    }
    goBack()
  }, [
    cohort,
    dispatch,
    goal,
    pathname,
    productId,
    screenName,
    hasCancelOffer,
    search,
    productName,
  ])

  return (
    <>
      {!trialPriceId && <S.Title>{t`payment.paymentFlow.getPlan`}</S.Title>}
      {paymentAmount}
      <S.Form marginBottom={isFifteenCohort ? 24 : 0}>
        <StripePaymentForm />
      </S.Form>
      {!isFifteenCohort && (
        <>
          <S.Divider>
            <S.DividerText>{t`payment.paymentFlow.or`}</S.DividerText>
          </S.Divider>
          <PrimerPaymentForm />
        </>
      )}
      <PaymentRequestButton />
      <S.PaymentServiceImage
        width="256px"
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`payment.paymentFlow.moneyBack`}</S.Guarantee>
      <S.BackButton onClick={handleBack}>
        {t`payment.paymentFlow.backToPlans`}
      </S.BackButton>
      {description}
    </>
  )
}
