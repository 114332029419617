import styled from 'styled-components'

import warningIcon from 'assets/images/warning-gray.svg'

import { Color } from 'root-constants/common'

export const StyledDescription = {
  DescriptionItem: styled.li`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    padding: 0 0 0 24px;
    color: ${Color.DARK_TEXT};
    background-image: url(${warningIcon});
    background-size: 14px 14px;
    background-position: top left;
    background-repeat: no-repeat;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  `,
}
