import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Language, MAIN_GOAL_OPTION_VALUES } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledMainGoal as S } from './MainGoal.styles'
import { PAGE_NAME } from './constants'

export const MainGoalVariant3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId: CustomPageId.MAIN_GOAL,
    pageName: PAGE_NAME,
    question: t('onboarding.mainGoal.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.MAIN_GOAL,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers],
  )
  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.mainGoal.title`}</S.Title>
        <S.Text marginBottom={24}>
          {t`commonComponents.selectMoreThanOne`}
        </S.Text>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH}
            checked={answers.includes(
              MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH,
            )}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.controlHeartHealth`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.CONTROL_BLOOD_PRESSURE}
            checked={answers.includes(
              MAIN_GOAL_OPTION_VALUES.CONTROL_BLOOD_PRESSURE,
            )}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.controlBloodPressure`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.REDUCE_STRESS}
            checked={answers.includes(MAIN_GOAL_OPTION_VALUES.REDUCE_STRESS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.reduceStress`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.SLEEP_BETTER}
            checked={answers.includes(MAIN_GOAL_OPTION_VALUES.SLEEP_BETTER)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.sleepBetter`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.REDUCE_MEDICATION_INTAKE}
            checked={answers.includes(
              MAIN_GOAL_OPTION_VALUES.REDUCE_MEDICATION_INTAKE,
            )}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.reduceMedicationIntake`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={MAIN_GOAL_OPTION_VALUES.OTHER}
            checked={answers.includes(MAIN_GOAL_OPTION_VALUES.OTHER)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.mainGoal.other`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
