import styled, { keyframes } from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const itemVisibility = keyframes`
  0% {
   opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledSummaryHeartScoreGraph = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    min-height: calc(var(--full-height, 100vh) - ${HEADER_HEIGHT}px);
    padding: 48px 0;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 16px;
  `,
  Subtitle: styled.h3`
    margin-bottom: 12px;
    text-align: center;
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    color: ${Color.DANGER};
  `,
  GraphContainer: styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 24px;
  `,
  GoalDate: styled.p`
    position: absolute;
    top: 80px;
    right: 54px;
    padding: 4px;
    border-radius: 6px;
    background-color: ${Color.WHITE};
    box-shadow: 0 4px 10px 0 rgba(5, 4, 51, 0.1);
    font-size: 9px;
    font-weight: 600;
    line-height: 11px;
    animation-name: ${itemVisibility};
    animation-fill-mode: backwards;
    animation-duration: 780ms;
    animation-delay: 1900ms;

    strong {
      font-weight: 900;
      color: #153e83;
    }
  `,
  DatesContainer: styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 278px;
    margin-left: 35px;
  `,
  Month: styled.span`
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: rgba(21, 62, 131, 0.6);
    animation-name: ${itemVisibility};
    animation-duration: 600ms;
    animation-fill-mode: backwards;

    [data-order='1']& {
      animation-delay: 50ms;
    }

    [data-order='2']& {
      animation-delay: 300ms;
    }

    [data-order='3']& {
      animation-delay: 700ms;
    }

    [data-order='4']& {
      animation-delay: 1020ms;
    }

    [data-order='5']& {
      animation-delay: 1110ms;
    }
  `,
  Disclaimer: styled.p`
    flex-grow: 1;
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
}
