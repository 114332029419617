import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useFirstPageFinish } from 'hooks/useFirstPageFinish'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { ReviewsSlider } from 'components/ReviewSlider'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { Gender, Language } from 'root-constants/common'

import { StyledIntro as S } from './Intro.styles'

const PAGE_NAME = 'Gender question'

export const IntroVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { handleChange, isAnswersDisabled, setIsAnswersDisabled } =
    useDelayedContinue()
  const handleFirstPageFinish = useFirstPageFinish()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.intro.selectGender', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleFirstPageFinish()
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      handleFirstPageFinish,
      handleChange,
      handleContinue,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.intro.intro2.title" />
        </S.Title>
        <S.Text>{t`onboarding.intro.selectGender`}:</S.Text>

        <StyledOption.OptionsContainer>
          <S.Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={Gender.MALE}
            marginBottom={16}
          >
            <S.QuestionButton>{t`actions.male`}</S.QuestionButton>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={Gender.FEMALE}
            marginBottom={56}
          >
            <S.QuestionButton>{t`actions.female`}</S.QuestionButton>
          </S.Option>
        </StyledOption.OptionsContainer>

        <ReviewsSlider marginBottom={28} />
        <S.AgreementText>{t`onboarding.intro.byContinuing`}</S.AgreementText>
        <S.PoliciesLinksWrapper>
          <TermsOfUseLink />
          <PrivacyPolicyLink />
        </S.PoliciesLinksWrapper>
        <S.Description>{t`commonComponents.description`}</S.Description>
      </S.Column>
    </S.Wrapper>
  )
}
