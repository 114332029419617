import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'

export const StyledMonitorBloodPressureVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${({ theme }) => theme.common.wrapperPaddingTop};
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 28px;
    padding-top: ${({ theme }) => theme.common.columnPaddingTop};
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 24px;
  `,
}
