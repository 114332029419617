import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'
import { Goals } from 'modules/subscriptions/components/common/Goals'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { PLAN_ITEMS, TRIAL_PLAN_ITEMS } from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { HeaderWithButton } from 'components/HeaderWithButton'
import { ReviewsSlider } from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  CURRENCY_SYMBOLS,
  SubscriptionTag,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledSubscriptionsVariant3 as S } from './SubscriptionsVariant3.styles'
import { BENEFITS } from './constants'

export const SubscriptionsVariant3: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const price = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currency = useSelector(selectCurrency)

  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const isTrialPlan = useMemo(
    () => TRIAL_PLAN_ITEMS.includes(subscriptionBlockType),
    [subscriptionBlockType],
  )

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <HeaderWithButton onClick={handleScroll} />

      <S.Content>
        <SubheaderWithTimer discount={isTrialPlan ? 50 : 75} />
        <S.Title>
          <Trans i18nKey="subscriptions.subscription3.title" />
        </S.Title>
        <S.Subtitle>{t`subscriptions.subscription3.subtitle`}</S.Subtitle>

        <Goals />
        <S.List>
          {BENEFITS.map((path) => (
            <S.ListItem key={path}>{t(path)}</S.ListItem>
          ))}
        </S.List>

        <S.SelectPlanBlock
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
        />
        <S.Button onClick={handleShowPayment}>{t`actions.getCardi`}</S.Button>
        {isTrialPlan ? (
          <S.SubscriptionDescription>
            <Trans
              i18nKey="subscriptions.paidTrial.subscriptionDescription"
              values={{
                context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
                currencySymbol: CURRENCY_SYMBOLS[currency],
                currencyCode: currency.toUpperCase(),
                trialPrice,
                price,
                minimumFractionDigits: 2,
              }}
              components={{
                termsOfUse: <TermsOfUseLink />,
                supportLink: <SupportLink />,
              }}
            />
          </S.SubscriptionDescription>
        ) : (
          <S.Disclaimer>{t`subscriptions.disclaimer`}</S.Disclaimer>
        )}
        <S.ReviewsTitle>{t`subscriptions.customersLove`}</S.ReviewsTitle>
        <ReviewsSlider titleBottomMargin={24} marginBottom={32} />
        <S.MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
