import styled from 'styled-components'

import { Option } from 'components/Option'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

export const StyledAgeRangeVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    align-items: center;
    padding: 16px 0 24px;
  `,
  Title: styled.h2`
    margin-bottom: 12px;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    color: ${Color.BLUE_DARK};
  `,
  Subtitle: styled.h3`
    margin-bottom: 12px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    color: ${Color.BLUE_DARK};
  `,
  Text: styled.p`
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase;
    color: ${Color.BLUE_DARK};
  `,
  OptionsContainer: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 20px;
    margin-bottom: 4px;

    input:checked + div {
      border: 2px solid ${Color.RED};
    }
  `,
  Option: styled(Option)`
    margin-bottom: 20px;
  `,
  ActionContainer: styled.div`
    padding: 2px;
    background-color: #f2f3f5;
    border-radius: 12px;
    border: 2px solid #f2f3f5;
  `,
  Action: styled.div`
    position: relative;
    display: flex;
    aspect-ratio: 161/168;
  `,
  RangeImage: styled.img`
    width: 100%;
    height: 124px;
    position: absolute;
    top: -11px;
    z-index: 1;
  `,
  Answer: styled.div`
    background-color: #f2f3f5;
    border-radius: 12px;
    position: relative;
    flex-basis: 100%;
    transition: background-color 0.1s ease-out;
  `,
  Range: styled.span`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 0 0 12px 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.WHITE};
    background-color: ${Color.RED};
    z-index: 1;

    svg {
      margin-left: 8px;
    }
  `,
  AgreementText: styled.p`
    margin-bottom: 24px;
    color: ${Color.GRAY_80};
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  Description: styled.p`
    color: ${Color.GRAY_80};
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
  `,
}
