import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
} from 'modules/payment/redux/selects'

import guide from 'assets/images/upsell-benefit-guide.png'
import management from 'assets/images/upsell-benefit-management.png'
import playlist from 'assets/images/upsell-benefit-playlist.png'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledUpsellBenefits as S } from './UpsellBenefits.styles'

export const UpsellBenefits: React.FC = () => {
  const { t } = useTranslation()
  const price = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)

  return (
    <S.Wrapper>
      <S.BenefitContainer>
        <S.Image src={playlist} alt="playlist" />
        <S.Text>{t`upsell.benefits.playlists`}</S.Text>
      </S.BenefitContainer>

      <S.BenefitContainer>
        <S.Image src={management} alt="management" />
        <S.Text>{t`upsell.benefits.management`}</S.Text>
      </S.BenefitContainer>

      <S.BenefitContainer>
        <S.Image src={guide} alt="guide" />
        <S.Text>{t`upsell.benefits.guide`}</S.Text>
      </S.BenefitContainer>

      <S.Price>
        {t('upsell.benefits.price', {
          price,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          minimumFractionDigits: 2,
        })}
      </S.Price>
    </S.Wrapper>
  )
}
