import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import goalIcon from 'assets/images/goal-icon.png'
import heartRateImg from 'assets/images/subscriptions-heart-rate.jpg'
import symptomsIcon from 'assets/images/symptoms-icon.png'

import { SYMPTOMS_TO_MARKUP } from 'root-constants/common'

import { StyledGoals as S } from './Goals.styles'
import { GOALS, MAX_SYMPTOMS_SIZE } from './constants'

export const Goals: React.FC = () => {
  const { t } = useTranslation()

  const { goal, symptoms } = useUserData()

  const userSymptoms = useMemo(() => {
    if (!symptoms) return []

    const formattedSymptoms =
      symptoms
        ?.slice(0, MAX_SYMPTOMS_SIZE)
        .map((symptom) => t(SYMPTOMS_TO_MARKUP[symptom]).toLowerCase()) || []

    return symptoms?.length <= MAX_SYMPTOMS_SIZE
      ? formattedSymptoms
      : [...formattedSymptoms, t`commonComponents.more`]
  }, [symptoms, t])

  return (
    <S.Wrapper>
      <S.TextContainer>
        <S.Icon src={goalIcon} alt="goal" />
        <S.Title>{t`subscriptions.subscription3.goals.yourGoal`}</S.Title>
        <S.Text>{t(GOALS[goal])}</S.Text>
      </S.TextContainer>

      {!!userSymptoms.length && (
        <S.TextContainer>
          <S.Icon src={symptomsIcon} alt="symptoms" />
          <S.Title>{t`subscriptions.subscription3.goals.reduceSymptoms`}</S.Title>
          <S.Text>
            <Trans
              i18nKey="subscriptions.subscription3.goals.symptoms"
              values={{
                symptoms: userSymptoms,
                style: 'long',
                type: 'conjunction',
              }}
            />
          </S.Text>
        </S.TextContainer>
      )}
      <S.Image src={heartRateImg} alt="scores on mobile phone" />
    </S.Wrapper>
  )
}
