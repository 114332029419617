export const OPTION_VALUES = {
  SALTY_FOODS: 'salty_foods',
  PROCESSED_FOODS: 'processed_foods',
  BEVERAGES: 'sugary_beverages',
  DONT_EAT: 'dont_eat',
  NONE_ABOVE: 'none_above',
}
export const OPTION_VALUES_CONSUMPTION_2 = [
  {
    value: OPTION_VALUES.SALTY_FOODS,
    text: 'onboarding.consumption.saltyFood',
  },
  {
    value: OPTION_VALUES.PROCESSED_FOODS,
    text: 'onboarding.consumption.processedFood',
  },
  { value: OPTION_VALUES.BEVERAGES, text: 'onboarding.consumption.beverages' },
  { value: OPTION_VALUES.DONT_EAT, text: 'onboarding.consumption.dontEat' },
  { value: OPTION_VALUES.NONE_ABOVE, text: 'onboarding.consumption.none' },
]
