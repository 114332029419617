import styled from 'styled-components'

export const StyledTotalAmountVAT = {
  Container: styled.div`
    width: 100%;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #7b8898;
  `,
  PeriodName: styled.span`
    text-transform: capitalize;
  `,
}
