import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserHeartAge } from 'root-redux/selects/common'

import arrowIcon from 'assets/images/arrow-repeate-red.svg'
import heartRateIcon from 'assets/images/heart-rate-red.svg'
import presentationIcon from 'assets/images/presentation-red.svg'

import { StyledHeartStats as S } from './HeartStats.styles'

export const HeartStats: React.FC = () => {
  const userHeartAge = useSelector(selectUserHeartAge)

  return (
    <S.Wrapper>
      <S.Content>
        <S.IconWrapper>
          <S.Icon src={heartRateIcon} alt="icon" />
        </S.IconWrapper>
        <S.Text>
          <Trans i18nKey="summary.summaryHeartPlan.heartRate" />
        </S.Text>
      </S.Content>

      <S.Content>
        <S.IconWrapper>
          <S.Icon src={arrowIcon} alt="icon" />
        </S.IconWrapper>
        <S.Text>
          <Trans
            i18nKey="summary.summaryHeartPlan.heartAge"
            values={{ age: userHeartAge }}
          />
        </S.Text>
      </S.Content>

      <S.Content>
        <S.IconWrapper>
          <S.Icon src={presentationIcon} alt="icon" />
        </S.IconWrapper>
        <S.Text>
          <Trans i18nKey="summary.summaryHeartPlan.cardicDisease" />
        </S.Text>
      </S.Content>
    </S.Wrapper>
  )
}
