import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AnswerWithCheckbox,
  Button,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import {
  OPTION_VALUES,
  OPTION_VALUES_CONSUMPTION_2,
} from 'pages/consumption/constants'

import { OptionType } from 'components/Option'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-icon-white.svg'

import { Language } from 'root-constants/common'

import { StyledConsumptionVariant2 as S } from './ConsumptionVariant2.styles'

export const ConsumptionVariant2: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.consumption.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked && value === OPTION_VALUES.NONE_ABOVE) {
          setAnswers([OPTION_VALUES.NONE_ABOVE])
          return
        }

        if (isChecked) {
          const newValues = [...answers, value].filter(
            (item) => item !== OPTION_VALUES.NONE_ABOVE,
          )
          setAnswers(newValues)
          return
        }

        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      },
    }),
    [answers, pageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.consumption.title`}</S.Title>
        <S.Text>{t`onboarding.additionalGoals.chooseAll`}</S.Text>
        <S.OptionsContainer>
          {OPTION_VALUES_CONSUMPTION_2.map(({ value, text }) => (
            <AnswerWithCheckbox
              {...optionProps}
              theme={answerWithCheckboxTheme.CARDIMATE}
              value={value}
              key={value}
              checked={answers.includes(value)}
              iconSrc={checkIcon}
              margin="0 0 12px"
            >
              {t(text)}
            </AnswerWithCheckbox>
          ))}
        </S.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
