import styled from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-circle-light-green.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, SENIOR_FLOW_HEADER_HEIGHT } from 'root-constants/common'

export const StyledBloodPressureGraphVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    background-color: #f2f3f5;
    padding-top: ${SENIOR_FLOW_HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;
    padding-top: 24px;
  `,
  Content: styled.div`
    flex-grow: 1;
  `,
  Title: styled.h1`
    margin-bottom: 24px;
    color: ${Color.GRAY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  GraphWrapper: styled.div`
    display: flex;
    margin-bottom: 20px;
    padding: 16px 12px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 12px 0 rgba(152, 159, 187, 0.08);
  `,
  BenefitsList: styled.ul`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GRAY_100};

    li {
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-position: left top;
      padding-left: 30px;
      margin-bottom: 16px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    li:first-child {
      margin-bottom: 8px;
    }

    strong {
      font-weight: 700;
      font-size: 17px;
      line-height: 24px;
      color: ${Color.GRAY_100};
    }
  `,
  ImageContainer: styled.div`
    max-width: 273px;
    width: 100%;
    aspect-ratio: 273/177;
    margin-bottom: 16px;

    img {
      max-width: 273px;
      margin-left: 30px;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    background-image: none;
  `,
}
