import styled from 'styled-components'

export const StyledHeaderV2 = {
  Wrapper: styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 36, 95, 0.05);
  `,
  Header: styled.header`
    position: relative;
    width: 100%;
    padding: 10px 12px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Logo: styled.img`
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    height: 28px;
  `,
  AppNameLogo: styled.img`
    height: 28px;
  `,
}
