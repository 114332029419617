import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledGoals = {
  Wrapper: styled.div`
    margin-bottom: 32px;
    padding: 24px 16px 16px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #dfe3f3;
  `,
  TextContainer: styled.div`
    display: grid;
    grid-template-columns: 36px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'icon title'
      'icon text';
    grid-column-gap: 12px;
    margin-bottom: 20px;
  `,
  Icon: styled.img`
    grid-area: icon;
    max-width: 36px;
  `,
  Title: styled.h3`
    grid-area: title;
    color: #898e97;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  Text: styled.p`
    grid-area: text;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

    &::first-letter {
      text-transform: capitalize;
    }
  `,
  Image: styled.img`
    border-radius: 8px;
  `,
}
