import styled from 'styled-components'

import { Option } from 'components/Option'

type TProps = {
  scrollMargin?: number
}
export const StyledSelectPlanVariant1 = {
  Wrapper: styled.div<TProps>`
    margin-bottom: 28px;
    width: 100%;
    scroll-margin-top: ${({ scrollMargin }) => scrollMargin || 0}px;
  `,
  Option: styled(Option)`
    width: 100%;
  `,
}
