import styled, { keyframes } from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

const fadein = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const StyledPlanProcessingVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 0 48px;
  `,
  ProgressContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 66px;
  `,
  ProgressText: styled.p`
    padding-left: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    animation: ${fadein} 0.5s ease-in-out;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 12px;
    text-align: center;

    strong {
      color: ${({ theme }) => theme.colors.highlightedText};
      font-size: 24px;
      font-weight: 900;
      line-height: 32px;
    }
  `,
  Subtitle: styled.h3`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 92px;
    text-align: center;

    strong {
      color: ${({ theme }) => theme.colors.highlightedText};
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }
  `,
  ContentWrapper: styled.div`
    border: 1px solid #b2b8cd;
    border-radius: 20px;
    padding: 40px 16px 24px;
    position: relative;
  `,
  Icon: styled.img`
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    top: -25px;
    width: 48px;
    height: 48px;
  `,
  Question: styled.p`
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    margin-bottom: 12px;
    text-align: center;
  `,
  Text: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;

    strong {
      font-size: 13px;
      line-height: 18px;
      font-weight: 900;
    }
  `,
}
