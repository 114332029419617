import styled from 'styled-components'

import checkIcon from 'assets/images/check.svg'

type TWrapperProps = {
  marginTop?: number
}

export const StyledGlobalStatusBar = {
  Wrapper: styled.div<TWrapperProps>`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: ${({ marginTop }) => `${marginTop || 0}px`};
  `,
  Item: styled.div`
    position: relative;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6px;

    :not(:last-child)::before {
      content: '';
      position: absolute;
      top: 11px;
      right: -75px;
      display: block;
      height: 2px;
      width: 110px;
      background-color: #b2b8cd;
      z-index: -1;
    }

    &[data-is-complete='true']:not(:last-child)::before {
      background-color: #01cda1;
    }
  `,
  Label: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #b2b8cd;
    background-color: white;
    font-size: 12px;
    font-weight: 800;
    line-height: 20px;
    color: #b2b8cd;

    [data-is-complete='true'] + * & {
      border-color: #01cda1;
      color: #01cda1;
    }

    [data-is-complete='true'] & {
      background-color: #01cda1;
      border-color: #01cda1;
      color: #01cda1;

      ::before {
        content: url(${checkIcon});
        position: absolute;
      }
    }
  `,
  Title: styled.span`
    min-height: 32px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #bcc2d6;
    text-align: center;
  `,
}
