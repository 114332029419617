import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useFirstPageFinish } from 'hooks/useFirstPageFinish'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import introImg from 'assets/images/intro-hearts.webp'

import { COMMON_OPTION_VALUES } from 'root-constants/common'

import { StyledIntroVariant5 as S } from './IntroVariant5.styles'

const PAGE_NAME = 'Heart age question'
const QUESTION = 'Do you know your heart age?'

export const IntroVariant5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { handleChange, isAnswersDisabled, setIsAnswersDisabled } =
    useDelayedContinue()
  const handleFirstPageFinish = useFirstPageFinish()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleFirstPageFinish()
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [
      handleFirstPageFinish,
      handleChange,
      handleContinue,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.intro.intro5.title" />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.intro.intro5.subtitle" />
        </S.Subtitle>
        <S.ImageContainer>
          <img src={introImg} alt="hearts" />
        </S.ImageContainer>
        <StyledOption.OptionsContainer>
          <S.Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={COMMON_OPTION_VALUES.YES}
            marginBottom={16}
          >
            <S.QuestionButton>{t`actions.yes`}</S.QuestionButton>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={COMMON_OPTION_VALUES.NO}
          >
            <S.QuestionButton>{t`actions.no`}</S.QuestionButton>
          </S.Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
