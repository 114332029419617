import agree from 'assets/images/agree.png'
import disagree from 'assets/images/disagree.png'
import neitherAgree from 'assets/images/neither-agree.png'
import stronglyAgree from 'assets/images/strongly-agree.png'
import stronglyDisagree from 'assets/images/strongly-disagree.png'

import { PageId } from 'root-constants/pages'

export const STATEMENT_PAGES_DATA = [
  {
    image: stronglyDisagree,
    description: 'onboarding.statement.options.stronglyDisagree',
    value: 'strongly_disagree',
  },
  {
    image: disagree,
    description: 'onboarding.statement.options.disagree',
    value: 'disagree',
  },
  {
    image: neitherAgree,
    description: 'onboarding.statement.options.neitherAgree',
    value: 'neither_agree',
  },
  {
    image: agree,
    description: 'onboarding.statement.options.agree',
    value: 'agree',
  },
  {
    image: stronglyAgree,
    description: 'onboarding.statement.options.stronglyAgree',
    value: 'strongly_agree',
  },
]

export const STATEMENT_PAGES_NAMES = {
  healthy_lifestyle: 'Lead a healthy lifestyle',
  eat_properly: 'Eat properly and balanced',
  manage_stress: 'Able to manage stress',
  feel_well_rested: 'Feel well-rested upon waking up',
  depression_anxiety: 'Depression or anxiety experience',
}

export const STATEMENT_PAGES_TITLES = {
  healthy_lifestyle: 'onboarding.statement.titles.healthyLifestyle',
  eat_properly: 'onboarding.statement.titles.eatProperly',
  manage_stress: 'onboarding.statement.titles.manageStress',
  feel_well_rested: 'onboarding.statement.titles.feelWellRested',
  depression_anxiety: 'onboarding.statement.titles.depressionAnxiety',
}

export const STATEMENT_V2_DATA = {
  [PageId.SOCIAL_ACTIVITIES]: {
    title: 'onboarding.statement2.titles.socialActivities',
    subtitle: 'onboarding.statement2.subtitles.socialActivities',
    lowestScaleText: 'onboarding.statement2.rarely',
    highestScaleText: 'onboarding.statement2.often',
  },
  [PageId.FAMILY_SUPPORT]: {
    title: 'onboarding.statement2.titles.familySupport',
    subtitle: '',
    lowestScaleText: 'onboarding.statement2.notReally',
    highestScaleText: 'onboarding.statement2.totally',
  },
  [PageId.HOBBIES]: {
    title: 'onboarding.statement2.titles.hobbies',
    subtitle: 'onboarding.statement2.subtitles.hobbies',
    lowestScaleText: 'onboarding.statement2.never',
    highestScaleText: 'onboarding.statement2.daily',
  },
}
export const STATEMENT_V3_DATA = {
  [PageId.SOCIALIZE_FREQUENCY]: {
    title: 'onboarding.statement3.titles.socialActivities',
    subtitle: 'onboarding.statement3.subtitles.socialActivities',
    lowestScaleText: 'onboarding.statement2.rarely',
    highestScaleText: 'onboarding.statement2.often',
  },
  [PageId.NEEDED_RELY]: {
    title: 'onboarding.statement3.titles.familySupport',
    subtitle: '',
    lowestScaleText: 'onboarding.statement2.rarely',
    highestScaleText: 'onboarding.statement2.often',
  },
  [PageId.ENJOY_FREQUENCY]: {
    title: 'onboarding.statement3.titles.hobbies',
    subtitle: 'onboarding.statement2.subtitles.hobbies',
    lowestScaleText: 'onboarding.statement2.rarely',
    highestScaleText: 'onboarding.statement2.often',
  },
}

export const OPTIONS_NUMBER = 5
