import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  PaymentFlow,
  PaymentMethodInfo,
  PaymentMethodType,
  Primer,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { updateUserConfigAction } from 'root-redux/actions/user'
import { selectError } from 'root-redux/selects/common'

import { useUserData } from 'hooks/useUserData'

import { Events } from 'services/eventLogger.service'

import {
  INITIAL_PRIMER_CONFIG,
  PRIMER_PAYMENT_ERRORS,
  PRIMER_PAYMENT_METHODS_MAP,
  PRIMER_PAY_PAL_ID,
} from 'modules/payment/constants'
import { useInitPrimerPayPal } from 'modules/payment/hooks/useInitPrimerPayPal'
import { usePrimerAnalytics } from 'modules/payment/hooks/usePrimerAnalytics'
import {
  PURCHASE_PRIMER,
  SET_PRIMER_PAYMENT_FORM_IS_LOADING,
  getPrimerClientSessionTokenAction,
  primerPurchaseAction,
  primerResumePurchaseAction,
  setIsFirstPaymentRetryPassedAction,
  setIsPrimerRetryProcessing,
  setPrimerClientSessionTokenAction,
} from 'modules/payment/redux/actions/primer'
import {
  selectIsFirstPaymentRetryPassed,
  selectPrimerClientSessionToken,
  selectSubscriptionLookupKey,
} from 'modules/payment/redux/selects'

import { PaymentMethod } from 'root-constants/common'

import { StyledPrimerPaymentForm as S } from './PrimerPaymentForm.styles'

export const PrimerPaymentForm: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const primerRef = useRef<PrimerHeadlessCheckout | null>(null)
  const primerFormRef = useRef<HTMLFormElement>(null)

  const isFirstPaymentRetryPassed = useSelector(selectIsFirstPaymentRetryPassed)
  const priceLookupKey = useSelector(selectSubscriptionLookupKey)
  const primerClientSessionToken = useSelector(selectPrimerClientSessionToken)
  const error = useSelector(selectError)

  const { goal } = useUserData()
  const { logPaypalPaymentStarted } = usePrimerAnalytics()

  const { initPayPalButton } = useInitPrimerPayPal({
    primerRef,
  })

  useLayoutEffect(() => {
    if (!primerRef.current) {
      dispatch(startFetching(SET_PRIMER_PAYMENT_FORM_IS_LOADING))
    }
  }, [dispatch])

  useEffect(() => {
    if (priceLookupKey) {
      dispatch(getPrimerClientSessionTokenAction())
    }

    return () => {
      dispatch(setPrimerClientSessionTokenAction(''))
      dispatch(updateUserConfigAction({ paymentId: '' }))
      dispatch(setIsFirstPaymentRetryPassedAction(false))
    }
  }, [dispatch, priceLookupKey])

  const retryPaymentCallback = useCallback(() => {
    if (!isFirstPaymentRetryPassed) {
      dispatch(setIsFirstPaymentRetryPassedAction(true))

      const submit = new Event('submit', {
        cancelable: true,
        bubbles: true,
      })

      primerFormRef.current?.dispatchEvent(submit)
      return
    }

    dispatch(setErrorAction(error || t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
    dispatch(setIsPrimerRetryProcessing(false))
    dispatch(stopFetching(PURCHASE_PRIMER))
  }, [dispatch, error, isFirstPaymentRetryPassed, t])

  const initPrimer = useCallback(async () => {
    const headless = await Primer.createHeadless(primerClientSessionToken, {
      ...INITIAL_PRIMER_CONFIG,
      paymentHandling: 'MANUAL',
      paypal: {
        paymentFlow: PaymentFlow.PREFER_VAULT,
      },

      onAvailablePaymentMethodsLoad(paymentMethods: PaymentMethodInfo[]) {
        paymentMethods.forEach(({ type }) => {
          if (type === PaymentMethodType.PAYPAL) {
            initPayPalButton()
          }
        })
      },
      onTokenizeSuccess(token, handler) {
        const currentMethod =
          PRIMER_PAYMENT_METHODS_MAP[token.paymentInstrumentType]

        currentMethod === PaymentMethod.PAYPAL &&
          logPaypalPaymentStarted(Events.PURCHASE_STARTED_PAYPAL)

        dispatch(
          primerPurchaseAction({
            token: token.token,
            handler,
            retryPaymentCallback,
            goal,
          }),
        )
      },
      onTokenizeStart() {
        dispatch(startFetching(PURCHASE_PRIMER))
      },
      onTokenizeError(clientError) {
        dispatch(stopFetching(PURCHASE_PRIMER))
        dispatch(
          setErrorAction(clientError || t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)),
        )
      },
      onResumeSuccess({ paymentId, resumeToken }, handler) {
        dispatch(
          primerResumePurchaseAction({
            currentPaymentId: paymentId,
            resumeToken,
            handler,
            retryPaymentCallback,
            goal,
          }),
        )
      },
    })

    primerRef.current = headless
    await headless.start()
  }, [
    dispatch,
    goal,
    initPayPalButton,
    logPaypalPaymentStarted,
    primerClientSessionToken,
    retryPaymentCallback,
    t,
  ])

  useEffect(() => {
    if (!primerClientSessionToken || primerRef.current) {
      return
    }

    initPrimer()
  }, [initPrimer, primerClientSessionToken])

  return <S.PayPalButton id={PRIMER_PAY_PAL_ID} />
}
