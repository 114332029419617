import styled, { css } from 'styled-components'

const progressValueStyles = css`
  background-color: #01cda1;
  transition: all 0.2s ease-out;
`

export const StyledProgressBarV2 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    place-content: center;
    flex: 1;
  `,
  Title: styled.p`
    margin-bottom: 6px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  `,
  ProgressContainer: styled.div`
    display: flex;
    justify-content: center;
    column-gap: 4px;
  `,
  Progress: styled.progress`
    width: 43px;
    height: 2px;
    appearance: none;
    border: none;
    background-color: #b2b8cd;

    &::-webkit-progress-bar {
      background-color: #b2b8cd;
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
