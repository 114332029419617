import React from 'react'

import { PlanItemLong } from 'modules/subscriptions/components/common/PlanItemLong'
import {
  PlanItemVariant1,
  VatPlanItemVariant1,
} from 'modules/subscriptions/components/common/PlanItemVariant1'
import { PlanItemVariant2 } from 'modules/subscriptions/components/common/PlanItemVariant2'
import { PaidTrialItemVariant1 } from 'modules/subscriptions/components/trial/PaidTrialItemVariant1'
import { PaidTrialItemVariant2 } from 'modules/subscriptions/components/trial/PaitTrialItemVariant2'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'

export const COMMON_BENEFITS = [
  'subscriptions.commonBenefits.trackingSystem',
  'subscriptions.commonBenefits.playlists',
  'subscriptions.commonBenefits.reports',
]

export const REDUCE_STRESS_BENEFITS = [
  'subscriptions.reduceStressBenefits.heartRate',
  'subscriptions.reduceStressBenefits.insights',
  'subscriptions.reduceStressBenefits.trackProgress',
  'subscriptions.reduceStressBenefits.playlists',
]

export const INITIAL_FORMATTED_TIME = {
  minutes: '',
  seconds: '',
}

export const PLAN_ITEMS: Record<string, React.FC<ISelectPlanItemProps>> = {
  plan_item_variant_1: PlanItemVariant1,
  plan_item_variant_2: PlanItemVariant2,
  plan_item_long_variant_1: PlanItemLong,
  vat_plan_item_variant_1: VatPlanItemVariant1,
  trial_plan_item_variant_1: PaidTrialItemVariant1,
  trial_plan_item_variant_2: PaidTrialItemVariant2,
}

export const TRIAL_PLAN_ITEMS = [
  'trial_plan_item_variant_1',
  'trial_plan_item_variant_2',
]
