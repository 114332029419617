import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useProgressBarList } from 'hooks/useProgressBarList'

import { TPageProps } from 'models/common.model'

import { Color } from 'root-constants/common'

import { StyledAdaptingProfileVariant2 as S } from './AdaptingProfileVariant2.styles'

export const AdaptingProfileVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const {
    firstCounterPercent,
    secondCounterPercent,
    thirdCounterPercent,
    isFirstProcessingFinished,
    isSecondProcessingFinished,
    isThirdProcessingFinished,
  } = useProgressBarList(true)

  const barsList = useMemo(
    () => [
      {
        listTitle: 'onboarding.adaptingProfile.healthProfile',
        counter: firstCounterPercent,
        textColor: `${Color.GRAY_100}`,
      },
      {
        listTitle: 'onboarding.adaptingProfile.habits',
        counter: secondCounterPercent,
        textColor: isFirstProcessingFinished
          ? `${Color.GRAY_100}`
          : `${Color.GRAY_80}`,
      },
      {
        listTitle: 'onboarding.adaptingProfile.dailyRoutine',
        counter: thirdCounterPercent,
        textColor: isSecondProcessingFinished
          ? `${Color.GRAY_100}`
          : `${Color.GRAY_80}`,
      },
    ],
    [
      firstCounterPercent,
      secondCounterPercent,
      thirdCounterPercent,
      isFirstProcessingFinished,
      isSecondProcessingFinished,
    ],
  )

  useEffect(() => {
    if (!isThirdProcessingFinished) return
    handleContinue('')
  }, [handleContinue, isThirdProcessingFinished])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.adaptingProfile.title`}</S.Title>
        <S.ProgressBarsList>
          {barsList.map(({ counter, listTitle, textColor }) => (
            <S.ProgressContainer key={listTitle}>
              <S.ProgressHeader>
                <S.ProgressText textColor={textColor}>
                  <Trans i18nKey={listTitle} />
                </S.ProgressText>

                <S.ProgressText textColor={textColor}>
                  {counter}%
                </S.ProgressText>
              </S.ProgressHeader>
              <S.ProgressBar max={100} value={counter} />
            </S.ProgressContainer>
          ))}
        </S.ProgressBarsList>
      </S.Column>
    </S.Wrapper>
  )
}
