import React, { useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsHealthConditionSkippablePage } from 'hooks/useIsHealthConditionSkippablePage'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { replaceHistory } from 'browser-history'
import { HEALTH_CONDITION_OPTION_VALUES } from 'root-constants/common'

import { StyledHeartRate as S } from './HeartRate.styles'

const PAGE_NAME = 'Heart rate question'
const QUESTION = `What's your average heart rate (mg/dL)?`
const OPTION_VALUES = {
  DONT_KNOW: `dont_know`,
  FROM_40_TO_50: '40 - 50',
  FROM_51_TO_80: '51 - 80',
  MORE_80: '>80',
}

export const HeartRateVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const isPageSkippable = useIsHealthConditionSkippablePage(
    HEALTH_CONDITION_OPTION_VALUES.HIGH_HEART_RATE,
  )

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  useLayoutEffect(() => {
    if (isPageSkippable) {
      replaceHistory(nextPagePath)
    }
  }, [isPageSkippable, nextPagePath])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.heartRate.title" />
        </S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DONT_KNOW}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.doNotKnow`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.FROM_40_TO_50}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.FROM_40_TO_50}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.FROM_51_TO_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.FROM_51_TO_80}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.MORE_80}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.MORE_80}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
