import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledSocialProofRestingHRVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 48px 0 47px;
    justify-content: space-between;
    height: calc(100svh - ${HEADER_HEIGHT}px);
  `,
  Text: styled.p`
    margin-bottom: 86px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 327/183;
  `,
}
