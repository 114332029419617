import { AnyAction } from 'redux'

import { ISubscription } from 'models/subscriptions.model'

import {
  SET_IS_FIRST_PAYMENT_RETRY_PASSED,
  SET_IS_PRIMER_RETRY_PROCESSING,
  SET_PRIMER_CLIENT_SESSION_TOKEN,
} from 'modules/payment/redux/actions/primer'

import { PaymentMethod, PlanAddition } from 'root-constants/common'

import {
  RESET_3D_SECURE_IFRAME_URL,
  SET_3D_SECURE_IFRAME_URL,
  SET_IS_PAYMENT_FLOWS_SHOWN,
  SET_PAYMENT_CLIENT_SECRET,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_REQUEST_BUTTON_TYPE,
  SET_PLAN_ADDITIONS,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_ID,
  SET_TRIAL_PERIOD_DAYS,
} from './actions/common'

export interface IPaymentState {
  selectedSubscription: ISubscription | null
  threeDSecureIframeURL: string
  paymentClientSecret: string
  trialPeriodDays: number
  subscriptionId: string
  isPaymentFlowsShown: boolean
  paymentMethod: PaymentMethod
  planAdditions: PlanAddition[]
  paymentRequestButtonType: PaymentMethod | null
  primerClientSessionToken: string
  isFirstPaymentRetryPassed: boolean
  isPrimerRetryProcessing: boolean
}

const initState: IPaymentState = {
  selectedSubscription: null,
  threeDSecureIframeURL: '',
  paymentClientSecret: '',
  trialPeriodDays: 0,
  subscriptionId: '',
  isPaymentFlowsShown: false,
  paymentMethod: PaymentMethod.CREDIT_CARD,
  planAdditions: [],
  paymentRequestButtonType: null,
  primerClientSessionToken: '',
  isFirstPaymentRetryPassed: false,
  isPrimerRetryProcessing: false,
}

export function paymentReducer(
  state: IPaymentState = initState,
  { type, payload = null }: AnyAction,
): IPaymentState {
  switch (type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        selectedSubscription: payload,
      }
    }
    case SET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: payload,
      }
    }
    case RESET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: '',
      }
    }
    case SET_PAYMENT_CLIENT_SECRET: {
      return {
        ...state,
        paymentClientSecret: payload,
      }
    }
    case SET_TRIAL_PERIOD_DAYS: {
      return {
        ...state,
        trialPeriodDays: payload,
      }
    }
    case SET_SUBSCRIPTION_ID: {
      return {
        ...state,
        subscriptionId: payload,
      }
    }
    case SET_IS_PAYMENT_FLOWS_SHOWN: {
      return {
        ...state,
        isPaymentFlowsShown: payload,
      }
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      }
    }
    case SET_PLAN_ADDITIONS: {
      return {
        ...state,
        planAdditions: payload,
      }
    }
    case SET_PAYMENT_REQUEST_BUTTON_TYPE: {
      return {
        ...state,
        paymentRequestButtonType: payload,
      }
    }
    case SET_PRIMER_CLIENT_SESSION_TOKEN: {
      return {
        ...state,
        primerClientSessionToken: payload,
      }
    }
    case SET_IS_FIRST_PAYMENT_RETRY_PASSED: {
      return {
        ...state,
        isFirstPaymentRetryPassed: payload,
      }
    }
    case SET_IS_PRIMER_RETRY_PROCESSING: {
      return {
        ...state,
        isPrimerRetryProcessing: payload,
      }
    }
    default:
      return state
  }
}
