import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { AdditionalGoal, Language } from 'root-constants/common'

import { StyledAdditionalGoalsVariant2 as S } from './AdditionalGoalsVariant2.styles'

export const AdditionalGoalsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.additionalGoals.title2', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, pageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.additionalGoals.title2`}</S.Title>
        <S.Subtitle>{t`onboarding.additionalGoals.chooseAll`}</S.Subtitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={AdditionalGoal.BLOOD_PRESSURE}
            checked={answers.includes(AdditionalGoal.BLOOD_PRESSURE)}
          >
            <S.QuestionButton data-has-check-icon>
              {t`onboarding.additionalGoals.achieveBloodPressure`}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={AdditionalGoal.REDUCE_STRESS}
            checked={answers.includes(AdditionalGoal.REDUCE_STRESS)}
          >
            <S.QuestionButton data-has-check-icon>
              {t`onboarding.additionalGoals.reduceStress`}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={AdditionalGoal.SLEEP_BETTER}
            checked={answers.includes(AdditionalGoal.SLEEP_BETTER)}
          >
            <S.QuestionButton data-has-check-icon>
              {t`onboarding.additionalGoals.improveSleep`}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={AdditionalGoal.HEART_HEALTHY_DIET}
            checked={answers.includes(AdditionalGoal.HEART_HEALTHY_DIET)}
          >
            <S.QuestionButton data-has-check-icon>
              {t`onboarding.additionalGoals.eatHealthyDiet`}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={AdditionalGoal.PHYSICAL_ACTIVITY}
            checked={answers.includes(AdditionalGoal.PHYSICAL_ACTIVITY)}
          >
            <S.QuestionButton data-has-check-icon>
              <Trans i18nKey="onboarding.additionalGoals.stayPhysicallyActive" />
            </S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
