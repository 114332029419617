import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ICancelOfferPlanItemProps } from 'modules/subscriptions/types'

import { CURRENCY_SYMBOLS, PERIOD_NAMES } from 'root-constants/common'

import { StyledPlanItemVariant1 as S } from './PlanItemVariant1.styles'

export const PlanItemVariant1: React.FC<ICancelOfferPlanItemProps> = ({
  currentPrice,
  periodQuantity,
  periodName,
  oldPrice,
  currency,
  isSoldOut = false,
}) => {
  const { t } = useTranslation()

  return (
    <S.PlanItem
      data-text={
        isSoldOut ? t`subscriptions.soldOut` : t`subscriptions.mostPopular`
      }
      data-is-sold-out={isSoldOut}
    >
      <S.PlanTitle>
        <Trans
          i18nKey="subscriptions.planPeriod"
          values={{ periodName: t(PERIOD_NAMES[periodName]), periodQuantity }}
        />
      </S.PlanTitle>

      <S.Container>
        <S.OldPrice
          data-currency-symbol-length={CURRENCY_SYMBOLS[currency]?.length}
        >
          {CURRENCY_SYMBOLS[currency]}
          {oldPrice}
        </S.OldPrice>
        <S.CurrentPrice
          data-is-sold-out={isSoldOut}
          data-currency-symbol-length={CURRENCY_SYMBOLS[currency]?.length}
        >
          {CURRENCY_SYMBOLS[currency]}
          {currentPrice}
        </S.CurrentPrice>
      </S.Container>
    </S.PlanItem>
  )
}
