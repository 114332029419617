import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'
import { ACCESS_DATA_V2 } from 'modules/subscriptions/components/common/Access/constants'
import { CustomerRatingVariant2 } from 'modules/subscriptions/components/common/CustomerRating'
import { PLAN_ITEMS, TRIAL_PLAN_ITEMS } from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { Spinner } from 'components/Spinner'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import appNameLogo from 'assets/images/app-name-logo-2.png'
import clockImage from 'assets/images/sprite/clock-big.svg'
import guaranteeImg from 'assets/images/sprite/guarantee-stamp-gray.svg'

import {
  CURRENCY_SYMBOLS,
  SubscriptionTag,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledSubscriptionsVariant10 as S } from './SubscriptionsVariant10.styles'

export const SubscriptionsVariant10: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const price = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currency = useSelector(selectCurrency)
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const planItem = useMemo(() => {
    return PLAN_ITEMS[subscriptionBlockType]
  }, [subscriptionBlockType])

  const isTrialPlan = useMemo(
    () => TRIAL_PLAN_ITEMS.includes(subscriptionBlockType),
    [subscriptionBlockType],
  )

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.HeaderWithButton
        onClick={handleScroll}
        logo={appNameLogo}
        height={36}
      />
      <S.Content>
        <S.SubheaderWithTimer
          discount={isTrialPlan ? 50 : 75}
          clockImg={clockImage}
          clockheight={24}
        />
        <S.Title>
          <Trans i18nKey="subscriptions.achieveHealthyHeart" />
        </S.Title>
        <S.Disclaimer>{t`subscriptions.heartHealthProgram`}</S.Disclaimer>
        <S.HeartAge />
        <S.SelectPlanBlock
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={planItem}
        />
        <Button onClick={handleShowPayment} theme={buttonTheme.CARDIMATE}>
          {t`actions.getCardi`}
        </Button>
        {isTrialPlan ? (
          <S.SubscriptionDescription>
            <Trans
              i18nKey="subscriptions.paidTrial.subscriptionDescription"
              values={{
                context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
                currencySymbol: CURRENCY_SYMBOLS[currency],
                currencyCode: currency.toUpperCase(),
                trialPrice,
                price,
                minimumFractionDigits: 2,
              }}
              components={{
                termsOfUse: <TermsOfUseLink />,
                supportLink: <SupportLink />,
              }}
            />
          </S.SubscriptionDescription>
        ) : (
          <S.CancelInfo>{t`subscriptions.disclaimer`}</S.CancelInfo>
        )}
        <S.AccessVariant accessData={ACCESS_DATA_V2} />
        <CustomerRatingVariant2 />
        <S.ReviewsSlider
          titlePath="subscriptions.customersLove"
          titleBottomMargin={24}
          marginBottom={32}
          textAlign="start"
        />
        <S.MoneyBackGuarantee
          logo={appNameLogo}
          width={124}
          height={36}
          guaranteeImg={guaranteeImg}
        />
      </S.Content>
    </S.Wrapper>
  )
}
