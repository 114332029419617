import styled from 'styled-components'

export const StyledCongratulationsReduceStress = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
  `,
  Title: styled.h3`
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
  `,
  Text: styled.p`
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;

    em {
      font-style: normal;
      color: #01cda1;
    }

    strong {
      color: #fd417f;
    }
  `,
  MetricsWrapper: styled.div`
    position: relative;
    padding-top: 52px;
  `,
  Metrics: styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    background: white;
    box-shadow: 0 8px 12px rgba(152, 159, 187, 0.1);
    border-radius: 16px;
    z-index: 1;
  `,
  MetricsValue: styled.span`
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
  `,
  MetricsDate: styled.span`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  `,
  Graph: styled.div`
    position: relative;
  `,
  GraphCurrent: styled.div`
    position: absolute;
    right: 280px;
    bottom: 104px;
    font-size: 13px;
    font-weight: 800;
  `,
  GraphGoal: styled.div`
    position: absolute;
    right: 280px;
    bottom: 45px;
    font-size: 13px;
    font-weight: 800;
  `,
  GraphImage: styled.img`
    margin-bottom: 4px;
  `,
  GraphMonths: styled.div`
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 16px;
    color: #81848c;
  `,
}
