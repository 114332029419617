import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledUpsellBenefits = {
  Wrapper: styled.div`
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #dfe3f3;
  `,

  BenefitContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  `,
  Image: styled.img`
    max-width: 48px;
    width: 100%;
    aspect-ratio: 48/48;
    margin-right: 12px;
  `,
  Text: styled.p`
    color: ${Color.LIGHT_TEXT};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  Price: styled.p`
    color: ${Color.LIGHT_GRAY};
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  `,
}
