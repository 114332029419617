import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectScreenName,
} from 'root-redux/selects/common'

import { useProductId } from 'hooks/useProductId'
import { useUserData } from 'hooks/useUserData'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { TrialDescription } from 'modules/payment/components/Description'
import { PaymentMethodSelector } from 'modules/payment/components/PaymentMethodSelector'
import { PriceInfo } from 'modules/payment/components/PriceInfo'
import { selectPaymentRequestButtonType } from 'modules/payment/redux/selects'

import paymentServicesImage from 'assets/images/payment-services.png'

import { goBack } from 'browser-history'
import { PaymentMethod } from 'root-constants/common'

import { StyledNoPaypalPaymentFlows as S } from './NoPaypalPaymentFlows.styles'

export const NoPaypalPaymentFlows: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const screenName = useSelector(selectScreenName)
  const paymentRequestButtonType = useSelector(selectPaymentRequestButtonType)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.CREDIT_CARD,
  )

  const productId = useProductId()
  const { goal } = useUserData()

  useLayoutEffect(() => {
    if (!paymentRequestButtonType) return
    setPaymentMethod(paymentRequestButtonType)
  }, [paymentRequestButtonType])

  const handleBack = useCallback(() => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      goal,
      screenName,
    })
    goBack()
  }, [cohort, dispatch, goal, pathname, productId, screenName])

  return (
    <S.Wrapper>
      <S.Title>
        {!paymentRequestButtonType
          ? t`payment.checkout`
          : t`payment.selectPaymentMethod`}
      </S.Title>
      <PaymentMethodSelector
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
      <PriceInfo />

      <S.StripePaymentForm
        isShown={paymentMethod === PaymentMethod.CREDIT_CARD}
      />
      <S.PaymentRequestButton
        isShown={paymentMethod !== PaymentMethod.CREDIT_CARD}
      />

      <S.AdditionalInfoContainer>
        <S.PaymentServiceImage
          src={paymentServicesImage}
          decoding="async"
          alt="payments services"
        />
        <S.Guarantee>{t`payment.paymentFlow.moneyBack`}</S.Guarantee>
        <S.BackButton onClick={handleBack}>
          {t`payment.paymentFlow.backToPlans`}
        </S.BackButton>
        <TrialDescription />
      </S.AdditionalInfoContainer>
    </S.Wrapper>
  )
}
