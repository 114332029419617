import styled from 'styled-components'

import { commonPlanItemStyles } from 'modules/subscriptions/styles'

import soldOutPriceBgImg from 'assets/images/price-bg-grey.svg'

import { Color } from 'root-constants/common'

type TProps = {
  priceBackground: string
}
export const StyledPlanItem = {
  PlanItem: styled.div`
    ${commonPlanItemStyles};

    &[data-is-default='true'] {
      &::after {
        left: 35%;
      }
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  PricesWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-weight: ${({ theme }) => theme.common.planItemFontWeight};
  `,
  CustomPrice: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 21px;
    width: 81px;
    height: 40px;
    color: #b2b8cd;
    background-image: url(${soldOutPriceBgImg});
    background-repeat: no-repeat;
    background-size: contain;

    &[data-is-selected='true'] {
      background-image: ${({ priceBackground }) => `url(${priceBackground})`};
    }

    &[data-currency-symbol-length='3'] {
      width: 90px;
      height: 45px;
      padding-left: 15px;
    }
  `,
  Price: styled.strong`
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.planItemText};

    &[data-is-selected='true'] {
      color: ${Color.WHITE};
    }
  `,
  Period: styled.span`
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    color: ${({ theme }) => theme.colors.pricePeriodColor};

    &[data-is-selected='true'] {
      color: ${Color.WHITE};
    }
  `,
  OldPrice: styled.span`
    font-size: 14px;
    line-height: 13px;
    font-weight: 500;
    text-decoration-line: line-through;
    text-decoration-color: #fd417f;
    color: ${({ theme }) => theme.colors.priceColor};
  `,
  NewPrice: styled.span`
    font-size: 14px;
    line-height: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.priceColor};
  `,
}
