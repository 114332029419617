import firstImage from 'assets/images/age-range-1.png'
import secondImage from 'assets/images/age-range-2.png'
import thirdImage from 'assets/images/age-range-3.png'
import fourthImage from 'assets/images/age-range-4.png'
import fifthImage from 'assets/images/age-range-5.png'
import sixthImage from 'assets/images/age-range-6.png'
import seventhImage from 'assets/images/age-range-7.png'
import eighthImage from 'assets/images/age-range-8.png'

export const QUESTION = 'Heart health program according to your age'
export const RANGES = [
  {
    text: '40-49',
    value: '40',
    img: firstImage,
  },
  {
    text: '50-59',
    value: '50',
    img: secondImage,
  },
  {
    text: '60-69',
    value: '60',
    img: thirdImage,
  },
  {
    text: '70+',
    value: '70',
    img: fourthImage,
  },
]

export const RANGES_V2 = [
  {
    text: '40-49',
    value: '40',
    img: fifthImage,
  },
  {
    text: '50-59',
    value: '50',
    img: sixthImage,
  },
  {
    text: '60-69',
    value: '60',
    img: seventhImage,
  },
  {
    text: '70+',
    value: '70',
    img: eighthImage,
  },
]
