import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants/common'

import { StyledPreparingPlan as S } from './PreparingPlan.styles'

export const PreparingPlanNameVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { name } = useUserData()
  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const hasAlternativeStyles = useMemo(
    () =>
      cohortToUse === Cohort.CARDIMATE_20 ||
      cohortToUse === Cohort.CARDIMATE_21,
    [cohortToUse],
  )

  useEffect(() => {
    const timerId = setTimeout(() => {
      handleContinue('')
    }, 2000)

    return () => {
      clearTimeout(timerId)
    }
  }, [handleContinue])

  return (
    <S.Wrapper hasAlternativeStyles={hasAlternativeStyles}>
      <S.Text hasAlternativeStyles={hasAlternativeStyles}>
        {t('onboarding.preparingPlanName.title', { name })}
      </S.Text>
    </S.Wrapper>
  )
}
