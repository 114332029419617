import React from 'react'

import arrowIcon from 'assets/images/sprite/popover-arrow.svg'

import { StyledPopover as S } from './Popover.styles'

type TProps = {
  isShown: boolean
  position: 'center' | 'left' | 'right'
  text: React.ReactNode
  children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export const Popover: React.FC<TProps> = ({
  isShown,
  position,
  text,
  children,
  ...props
}) => (
  <S.Wrapper {...props}>
    {isShown && (
      <S.Popover data-position={position}>
        {text}
        <S.SvgImage svg={arrowIcon} />
      </S.Popover>
    )}
    {children}
  </S.Wrapper>
)
