import styled from 'styled-components'

export const StyledPrimerPaymentForm = {
  PayPalButton: styled.div`
    position: relative;
    width: 100%;
    height: 55px;
    margin-bottom: 16px;
    border-radius: 16px;
    overflow: hidden;
    z-index: 1;

    &:active {
      opacity: 0.7;
    }
  `,
}
