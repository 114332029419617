import styled from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledCancelOfferDiscount = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    align-items: center;
    padding: 48px 0 0;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    text-align: center;
  `,
  AnimationContainer: styled.div`
    margin: 16px 0 20px;
    max-width: 240px;
    width: 100%;
    aspect-ratio: 240/240;
  `,
  DiscountContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 12px;

    img {
      width: 32px;
      height: 32px;
    }
  `,
  DiscountText: styled.p`
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
  `,
  SpecialOfferContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 24px;

    img {
      width: 32px;
      height: 32px;
    }
  `,
  SpecialOfferText: styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.LIGHT_TEXT};
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
    background: rgba(53, 136, 255, 0.2);
    color: #153e83;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    margin-bottom: 24px;

    strong {
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      color: ${Color.DANGER};
      margin-right: 5px;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 24px 0 12px;
  `,
}
