import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const commonTextStyles = css`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: ${Color.LIGHT_TEXT};
  text-align: center;
`
export const StyledCancelOfferVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 0;
    align-items: center;
  `,
  Firework: styled.img`
    width: 32px;
    height: 32px;
    margin-bottom: 12px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    text-align: center;
    margin-bottom: 24px;

    strong {
      font-size: 24px;
      font-weight: 900;
      line-height: 32px;
      color: ${Color.DANGER};
    }
  `,
  DiscountContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    border-radius: 16px;
    background: ${Color.WHITE};
    max-width: 327px;
    width: 100%;
    margin-bottom: 24px;
  `,
  DiscountTitle: styled.h3`
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    margin-bottom: 12px;
    text-align: center;
  `,
  PressureText: styled.p`
    ${commonTextStyles};
    font-size: 14px;
    margin-bottom: 16px;
  `,
  PercentContainer: styled.div`
    padding: 8px 12px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    background: #3588ff;
    margin-bottom: 16px;
    max-width: 295px;
    width: 100%;

    img {
      width: 24px;
      height: 24px;
    }
  `,
  PercentText: styled.p`
    ${commonTextStyles};
    font-weight: 900;
    color: ${Color.WHITE};
    max-width: 196px;
    width: 100%;
    text-align: start;
  `,
  PercentWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  CurrentDiscount: styled.p`
    ${commonTextStyles};
    font-weight: 800;
    color: ${Color.WHITE};
  `,
  OldDiscount: styled.p`
    ${commonTextStyles};
    font-weight: 600;
    color: #dcdfee;
    position: relative;

    ::before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      right: 0;
      border-top: 1px solid ${Color.DANGER};
      transform: rotate(15.524deg);
    }
  `,
  CostContainer: styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 295px;
    width: 100%;
    margin-bottom: 8px;
  `,
  CostText: styled.p`
    ${commonTextStyles};
  `,
  PriceWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  CurrentPrice: styled.p`
    ${commonTextStyles};
    font-weight: 800;
  `,
  OldPrice: styled.p`
    ${commonTextStyles};
    position: relative;

    ::before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      right: 0;
      border-top: 1px solid ${Color.DANGER};
      transform: rotate(15.524deg);
    }
  `,
  Difference: styled.p`
    ${commonTextStyles};
    font-weight: 800;
    color: #01cda1;
    margin-bottom: 16px;
    text-align: start;
  `,
  Line: styled.div`
    max-width: 295px;
    width: 100%;
    height: 1px;
    background: #d0dde4;
    margin-bottom: 16px;
  `,
  TotalWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  TotalText: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  TotalPrice: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
  SubscriptionDescription: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    & a {
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
}
