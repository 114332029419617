import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Language } from 'root-constants/common'

import { StyledSymptoms as S } from './Symptoms.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const SymptomsVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.symptoms.testosterone', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked && value === OPTION_VALUES.NONE) {
          setAnswers([OPTION_VALUES.NONE])
          return
        }

        if (isChecked) {
          const newValues = [...answers, value].filter(
            (item) => item !== OPTION_VALUES.NONE,
          )
          setAnswers(newValues)
          return
        }

        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      },
    }),
    [answers, pageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.symptoms.testosterone`}</S.Title>
        <S.Text>{t`onboarding.symptoms.testosteroneRisks`}</S.Text>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.FATIGUE}
            checked={answers.includes(OPTION_VALUES.FATIGUE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.symptoms.fatigue`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.REDUCE_MUSCLE_MASS}
            checked={answers.includes(OPTION_VALUES.REDUCE_MUSCLE_MASS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.symptoms.muscleMass`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.LIBIDO}
            checked={answers.includes(OPTION_VALUES.LIBIDO)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.symptoms.libido`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NONE}
            checked={answers.includes(OPTION_VALUES.NONE)}
          >
            <QuestionButton data-has-none-button-check-icon>
              {t`actions.noneOfThis`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
