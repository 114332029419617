import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { CURRENCY_SYMBOLS, Cohort, PERIOD_NAMES } from 'root-constants/common'

import { StyledPlanItemVariant2 as S } from './PlanItemVariant2.styles'

export const PlanItemVariant2: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    mainPrices: { periodName, periodQuantity, weekly },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isThirteenCohort = useMemo(
    () => cohortToUse === Cohort.CARDIMATE_13,
    [cohortToUse],
  )

  return (
    <S.PlanItem
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={
        isThirteenCohort
          ? t`subscriptions.specialOffer`
          : t`subscriptions.mostPopular`
      }
    >
      <S.Container>
        <S.PlanTitle>
          {t('subscriptions.planPeriodV2', {
            periodName: t(PERIOD_NAMES[periodName]),
            periodQuantity,
          })}
        </S.PlanTitle>
        <S.PaymentInformation>
          {t('subscriptions.billingCycle', {
            periodName: t(PERIOD_NAMES[periodName]),
            count: periodQuantity,
          })}
        </S.PaymentInformation>
      </S.Container>
      {!!weekly && (
        <S.Container>
          <S.PlanPrice>
            {CURRENCY_SYMBOLS[currency]}
            {weekly.toFixed(2)}
          </S.PlanPrice>
          <S.PricePeriod>{t`subscriptions.perWeek`}</S.PricePeriod>
        </S.Container>
      )}
    </S.PlanItem>
  )
}
