import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import img from 'assets/images/social-proof-family.webp'

import { StyledSocialProofFamily as S } from './SocialProofFamily.styles'

const PAGE_NAME = 'Social Proof Family'

export const SocialProofFamilyVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.socialProofFamily.title`}</S.Title>
        <S.Text>{t`onboarding.socialProofFamily.description`}</S.Text>
        <S.Img src={img} alt="pressure measurement" />
        <S.StickyButton onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </S.StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
