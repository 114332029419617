import React from 'react'

import { StyledQuestionButton } from './QuestionButton.styles'

type TProps = {
  children: React.ReactNode
}

export const QuestionButton: React.FC<TProps> = ({ children, ...props }) => (
  <StyledQuestionButton.Wrapper {...props}>
    {children}
  </StyledQuestionButton.Wrapper>
)
