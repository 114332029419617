export function createProductId({
  periodName,
  periodQuantity,
  price,
}: {
  periodName: string
  periodQuantity: number | null
  price: number
}): string {
  return `cardimate_${periodQuantity}_${periodName}_${price}`
}
