import { OPTION_VALUES as ETHNICITY } from 'pages/ethnicity/EthnicityVariant1'

export const ETHNICITY_TEXTS = {
  [ETHNICITY.WHITE]: 'summary.heartHealth.ethnicity.white',
  [ETHNICITY.BLACK_OR_AFRICAN_AMERICANS]:
    'summary.heartHealth.ethnicity.blackOrAfricanAmericans',
  [ETHNICITY.HISPANICS]: 'summary.heartHealth.ethnicity.hispanics',
  [ETHNICITY.ASIAN_AMERICANS]: 'summary.heartHealth.ethnicity.asianAmericans',
  [ETHNICITY.AMERICAN_INDIANS_OR_ALASKA_NATIVES]:
    'summary.heartHealth.ethnicity.americanIndianOrAlaskaNatives',
  [ETHNICITY.HAWAIIANS_OR_OTHER_ISLANDERS]:
    'summary.heartHealth.ethnicity.hawaiiansOrOtherIslanders',
  [ETHNICITY.MULTIRACIALS]: 'summary.heartHealth.ethnicity.multiracials',
  [ETHNICITY.PREFER_NOT_TO_SAY]: '',
}
